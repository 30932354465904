@import "src/helpers/mixins";

$headerHeight: 132px;
$headerHeightSmall: 112px;
$footerHeight: 60px;

.wrapper {
  overflow: hidden;
  position: absolute;
  top: 137px;
  right: 0;
  width: 282px;
  height: calc(100vh - 60px - 134px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--font);
  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  transition: width var(--transition-time) ease-in-out;

  @media (max-height: 900px) {
    top: $headerHeightSmall;
    height: calc(100vh - #{$footerHeight} - #{$headerHeightSmall});
  }

  &.collapsed {
    width: 0;
    overflow: hidden;
    border: none;
  }
}
