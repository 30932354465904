.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.3em;
  color: var(--font);
}

.title {
  color: var(--grey60);
}
