.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff;
  text-align: center;
  padding: 20px;
}

.errorMessage {
  display: flex;
  flex-direction: column;
}

.oopsMessage {
  text-align: left;
}

.image {
  width: 400px;
  max-width: 90%;
  margin-bottom: 20px;
}

.title {
  font-size: 57px;
  font-weight: 600;
  color: #333;
}

.subtitle {
  font-size: 35px;
  margin-top: 10px;
  font-weight: 400;
}

.text {
  font-size: 24px;
  color: #666;
  margin-top: 10px;
  font-weight: 400;
}

.link {
  color: #4a66f0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
