@import "src/helpers/mixins";

.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chartWrapper {
  flex-grow: 1;
  border-bottom: var(--basic-border);

  &:last-child {
    border: none;
  }
}
