.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
}

.listsContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.625rem;
}

.sectionHeader {
  width: 100%;
  height: auto;
  padding: 0.625rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.5rem;
  align-items: center;
  border: var(--basic-border);
  border-left: none;
  border-right: none;
  background-color: var(--extra-background);

  span {
    color: var(--font);
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.3em;
  }
}

.formContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.625rem;
}

.basicBtn {
  width: 95px;
  height: 100%;
  color: var(--white);
  border-radius: 2px;
}

.allowBtn {
  @extend .basicBtn;
  background-color: var(--green);
  &:hover {
    background-color: var(--green-lighter-30);
  }
}

.blockBtn {
  @extend .basicBtn;
  background-color: var(--pink);
  &:hover {
    background-color: var(--pink-lighter-30);
  }
}
