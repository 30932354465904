.notificationBellWrapper {
  position: absolute;
  right: 16px;
}

.notificationBell {
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.notificationBellCount {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #6B55F0;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 9999px;
}

.emptyTooltip {
  position: absolute;
  top: -30px;
  left: 0;
  transform: translateX(-50%);
  background-color: var(--bottom-nav-background);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 100;
  opacity: 0.9;
}
