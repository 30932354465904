@import "src/helpers/mixins.scss";

.container {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 18px;
  font-weight: 600;
}

.addBtn {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--accent-color);
  background: var(--accent-color);
  height: 20px !important;
  margin: auto;
}

.empty {
  height: 100%;
}

.noBorder {
  position: relative;
  &:not(.empty) {
    > * {
      height: max-content;
      grid-template-rows: unset;
    }
  }

  div[class*="extraTitle_"] {
    width: 15%;
  }
}

.location {
  display: flex;
  align-items: center;
  gap: 5px;
}
