.wrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas: "headerWrapper headerWrapper" "tableWrapper tableWrapper";

  &.notFullHeight {
    grid-template-rows: 56px 14rem;
  }
}

.header {
  grid-area: headerWrapper;
}
