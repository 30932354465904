.filtersWrapper {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 0;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: var(--accent-color);
  margin-right: 0.5rem;
}
