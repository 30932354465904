body {
  --font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    helvetica, arial, "Lucida Grande", sans-serif;

  // ZAYO COLORS
  --zayo-light-blue: #eef5f7;
  --zayo-dark-blue: #0085b1;
  --zayo-font: #14395a;

  // NEW COLORS
  --tab-active: #ff5f01;
  --accent-color: #6b55f0;
  --accent-color-opacity: #6b55f026;
  --accent-color-lighter-5: rgba(107, 85, 240, 0.05);
  --accent-color-lighter-10: rgba(107, 85, 240, 0.1);
  --accent-color-lighter-20: rgba(107, 85, 240, 0.2);
  --accent-color-lighter-50: rgba(107, 85, 240, 0.5);
  --accent-color-lighter-60: rgba(107, 85, 240, 0.6);
  --accent-color-lighter-70: rgba(107, 85, 240, 0.7);
  --accent-color-lighter-80: rgba(107, 85, 240, 0.8);
  --font: #211d2e;
  --grey70: rgba(89, 83, 107, 0.7);
  --grey60: rgba(89, 83, 107, 0.6);
  --border-color: #d8daeb;
  --border-color-secondary: #e2e2e8;
  --item-background: #ffffff;
  --item-background-secondary: #fcfcff;
  --item-background-tertiary: #f1eefe;
  --out-color: #844aff;
  --in-color: #00e492;
  --out-area-color: rgba(107, 85, 240, 0.1);
  --in-area-color: rgba(0, 228, 146, 0.1);
  --grey-font: #59536b;
  --grey50: rgba(89, 83, 107, 0.5);
  --label-background: rgba(107, 85, 240, 0.1);
  --main-background: #f9f8fb;
  --navigation-background: rgba(216, 218, 235, 0.3);
  --accent-orange-color: #ff6c0e;
  --card-hover: linear-gradient(
      0deg,
      rgba(107, 85, 239, 0.05),
      rgba(107, 85, 239, 0.05)
    ),
    #ffffff;
  --purple-lighter-50: #f7f4fd;
  --light-blue: #6b55f0;
  // bottom nav
  --bottom-nav-background: #211d2e;
  --bottom-nav-active-color: #ffffff;
  --bottom-nav-inactive-color: rgba(216, 218, 235, 0.8);
  --bottom-nav-active-background: rgba(255, 255, 255, 0.2);

  // todo add to figma
  // table
  --table-header: #f9f8fb;
  --critical: #ff406e;
  --box-shadow: 10px 10px 20px rgba(120, 118, 148, 0.1);
  --box-shadow-inner: inset 10px 10px 20px rgba(120, 118, 148, 0.1);
  --box-shadow-secondary: 1px 10px 20px rgba(120, 118, 148, 0.15);
  --box-shadow-smaller: 0 4px 27px rgba(158, 166, 174, 0.1);
  --box-shadow-smaller-inner: inset 0 4px 27px rgba(158, 166, 174, 0.1);
  --border-radius: 10px;
  --input-background: linear-gradient(
      0deg,
      rgba(89, 83, 107, 0.02),
      rgba(89, 83, 107, 0.02)
    ),
    rgba(107, 85, 240, 0.03);
  --button-font: #ffffff;

  --disabled-basic: rgba(76, 76, 102, 0.16);
  --disabled-checked: rgba(110, 57, 223, 0.5);

  --extra-background: #f9fafc;
  --extra-dusty-yellow: #ef9c0b;
  --extra-dusty-magenta: #ef426e;

  // graphics colors
  --white: #ffffff;
  --lilac: #6a82ff;
  --pink: #FF406E;
  --aqua: #06e091;
  --green: #00ba77;
  --blue: #5d96ec;
  --lavender: #c06ce8;
  --orange: #ff5f01;
  --yellow: #ffa400;

  --lilac-lighter-10: #798eff;
  --lilac-lighter-30: #96a7ff;
  --pink-lighter-10: #ff537c;
  --pink-lighter-30: #ff7999;
  --green-lighter-10: #19c184;
  --green-lighter-30: #4ccfa0;
  --green-lighter-50: #ffefe6;

  --green-lighter-40: #4ccfa0;

  --light-green: #bfeedd;
  --light-blue: #cedcf2;
  --light-lavender: #efdaf9;
  --light-pink: #ffcfe5;

  --lilac-5: rgb(107, 132, 255, 0.05);
  --lilac-10: rgb(107, 132, 255, 0.1);
  --lilac-15: rgb(107, 132, 255, 0.15);
  --lilac-50: rgb(107, 132, 255, 0.5);

  --green-5: rgba(0, 186, 119, 0.05);
  --green-10: rgba(0, 186, 119, 0.1);
  --green-15: rgba(0, 186, 119, 0.15);
  --green-20: rgba(0, 186, 119, 0.2);
  --green-50: rgba(0, 186, 119, 0.5);

  --orange-5: rgba(255, 95, 1, 0.05);
  --orange-10: rgba(255, 95, 1, 0.15);
  --orange-15: rgba(255, 95, 1, 0.15);
  --orange-50: rgba(255, 95, 1, 0.5);

  --pink-15: rgba(255, 64, 110, 0.05);
  --pink-10: rgba(255, 64, 110, 0.1);
  --pink-15: rgba(255, 64, 110, 0.15);
  --pink-50: rgba(255, 64, 110, 0.5);

  // OLD COLORS todo remove
  --orange-opacity: rgba(255, 95, 1, 0.12);
  --lilac-opacity: rgba(106, 130, 255, 0.15);
  --green-opacity: rgba(0, 186, 119, 0.1);
  // grafana colors
  --grafana-grey: #8d8d8d;
  --grafana-orange: #ff780a;

  // warning message
  --warning-message-background: rgba(255, 95, 1, 0.03);
  --warning-message-border: 1px solid rgba(255, 95, 1, 0.2);
  --warning-message-color: #db5100;

  // packages colors
  --essential-package-color: rgba(89, 83, 107, 1);
  --premium-package-color: rgba(242, 160, 0, 1);
  --pro-package-color: rgba(233, 101, 17, 1);
  --custom-package-color: rgba(219, 214, 251, 1);

  --essential-package-color-opacity: rgba(89, 83, 107, 0.1);
  --premium-package-color-opacity: rgba(242, 160, 0, 0.5);
  --pro-package-color-opacity: rgba(233, 101, 17, 0.2);
  --custom-package-color-opacity: rgba(107, 85, 240, 0.2);

  --essential-package-bg-primary: rgba(89, 83, 107, 0.05);
  --premium-package-bg-primary: rgba(255, 164, 0, 0.05);
  --pro-package-bg-primary: rgba(233, 101, 17, 0.05);
  --custom-package-bg-primary: rgba(107, 85, 240, 0.05);

  --essential-package-bg-secondary: rgba(89, 83, 107, 0.2);
  --premium-package-bg-secondary: rgba(255, 164, 0, 0.2);
  --pro-package-bg-secondary: rgba(233, 101, 17, 0.2);
  --custom-package-bg-secondary: rgba(107, 85, 240, 0.2);

  --orange: #ff5f01;
  --button-primary: #6e39df;
  --button-primary-hover: #361087;

  --toggle-active-knob: rgba(110, 57, 223, 0.3);
  --toggle-inactive-knob: #9b94ae;
  --toggle-inactive-track: rgba(155, 148, 174, 0.2);

  --orange-gradiend-first: #ff5c00;
  --orange-gradiend-second: #ff8a00;

  --orange-lime-gradient-first: #ff6544;
  --orange-lime-gradient-second: #fc9e6a;
  --orange-lime-gradient-third: #cee34e;

  //--border-color: #e2e2e8;
  --background-color: #ffffff;
  --background-second-color: #fdfcfe;

  //need to deal with the colors when the palette is ready

  --grey-30: rgba(76, 76, 102, 0.3);
  --grey-20: rgba(76, 76, 102, 0.2);
  --grey-disabled: rgba(89, 83, 107, 0.5);
  --border-color-8: rgba(89, 83, 107, 0.08);

  --box-shadow-medium: 50px 38px 50px rgba(120, 118, 148, 0.05);
  --box-shadow-smaller: 0 4px 27px rgba(158, 166, 174, 0.1);

  --dialog-background: rgba(155, 149, 173, 0.6);

  --stop-color: #ff007a;

  --pie-chart-inner-color-1: #33a02c;
  --pie-chart-inner-color-2: #045a8d;
  --pie-chart-inner-color-3: #1f78b4;
  --pie-chart-inner-color-4: #b2df8a;

  --pie-chart-outer-color-1: #6a3d9a;
  --pie-chart-outer-color-2: #ff7f00;
  --pie-chart-outer-color-3: #fb9a99;

  --table-background-primary: #fdfcfe; // main color
  --table-background-secondary: rgba(239, 233, 251, 0.2); // odd
  --table-background-accent: rgba(239, 233, 251, 0.4); // header and hover
  --table-background-accent-secondary: rgba(
    239,
    233,
    251,
    0.8
  ); // header and hover
  --table-background-accent-tertiary: #ece2ff; // parent row hover
  --table-background-highlight: rgba(245, 142, 189, 0.15); // pink highlight
  --table-background-highlight-extra: rgba(107, 85, 240, 0.1);

  --disabled-basic: rgba(76, 76, 102, 0.16);
  --disabled-checked: rgba(110, 57, 223, 0.5);

  --semi-gray: #c4c4c4;

  --transition-time: 0.2s;

  --basic-border: 1px solid var(--border-color);

  --input-height: 28px;

  --transparent-gray: #9b98a6;

  --active-tab-gradient: linear-gradient(0deg, rgba(255, 95, 1, 0.7) 0%, rgba(255, 95, 1, 0.7) 100%), #FFF;

  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: var(--font);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: unset;
}

input {
  border: none;
  background: none;
  outline: none;
}

// allow table header controls overlap table content
.mantine-rkx6uh {
  z-index: 9;
}

::-webkit-scrollbar {
  height: 8px;
  width: 2px;
  margin-left: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--font);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (max-width: 1440px) {
  body {
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    height: 4px;
    width: 2px;
    margin-left: 5px;
  }
}
