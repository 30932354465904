.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 162px 1fr;
  column-gap: 1rem;
}

.list {
  margin: 0;
  padding: 0;
  margin-top: 1.5rem;
  list-style: none;
  display: grid;
  row-gap: 0.675rem;

  li {
    color: var(--grey60);
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.3em;
  }
  .link {
    color: var(--button-primary);

    &:hover {
      color: var(--button-primary-hover);
    }
  }
}
