@import "src/helpers/mixins";

.formWrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 1.25rem;

  margin-bottom: 1rem;
}
$colWidth: calc(50% - 1.25rem / 2);
.fields {
  display: grid;
  grid-template-columns: $colWidth $colWidth;
  grid-auto-rows: auto;
  gap: 1.25rem 1.25rem;
}

.flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vlanWrapper {
  position: relative;
  width: 100%;
  .vlanList {
    width: 100%;
    transform: translateY(50px) translateX(-8px) !important;
    border-radius: 0;
    height: 65px;
  }

  .usedVlan {
    @include yScroll;

    width: calc(100% - 5px);
    position: absolute;
    height: 110px;
    box-sizing: border-box;
    top: 120px;
    border: 1px solid var(--border-color);
    box-shadow: var(--box-shadow);
    background: var(--item-background);
    z-index: 20;
    padding: 1.25rem 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      color: #ff406e;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      opacity: 0.8;
    }

    > div {
      font-size: 0.875rem;
      line-height: 1.2em;
      padding-left: 1.875rem;
    }
  }

  &.empty {
    .usedVlan {
      top: 50px;
      width: 100%;
    }
  }
}
