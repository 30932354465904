.wrapper {
  box-sizing: border-box;
  padding: 24px 30px;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  column-gap: 24px;
  color: var(--grey-font);
  margin-bottom: 12px;

  .headerSource,
  .headerDestination {
    display: flex;
    column-gap: 8px;
    font-weight: 600;

    span {
      opacity: 0.6;
    }

    b {
      opacity: 1;
    }
  }
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  width: 100%;
  box-shadow: 0 0 30px rgba(27, 24, 63, 0.1);
  padding: 10px 32px;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-columns: 1fr auto auto;
  z-index: 2;
  background-color: var(--background-color);

  .btn {
    width: 120px;
  }

  .msg {
    align-self: flex-start;
    display: flex;
    align-items: center;
    height: 2rem;
  }
}

.mainContent {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 40px;
}
