.field {
    font-size: 14px;

    .name,
    .value {
        display: inline-block;
    }

    .name {
        font-weight: 400;
        width: 125px;
    }

    .value {
        color: var(--grey-font);
        font-weight: 600;
    }
}