.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;

  font-size: 0.9rem;
  line-height: 1.25em;
  letter-spacing: 0.05em;
  color: var(--font);
  border-top: 1px solid var(--border-color);
  background-color: white;
}

.btnContainer {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  margin-left: 2.5rem;
  margin-right: 1rem;

}

.btn {
  width: 25px;
  height: 25px;

  &:hover {
    opacity: 0.6;
  }
}

.prevBtn {
  transform: rotate(180deg);
}
