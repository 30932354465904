@import "src/helpers/mixins.scss";

$basicPadding: 1.875rem;

.wrapper {
  position: absolute;
  min-width: 200px;
  // max-width: 240px;
  z-index: 20;
  overflow: hidden;

  transform: translateY(-20px);

  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  padding: 1.25rem 0;
  box-sizing: border-box;
  margin-top: 1.5rem;

  &.smaller {
    top: 22px;
    margin: 0;
  }

  &.medium {
    top: 34px;
  }

  &.hiddenList {
    display: none;
  }

  &.toRightCorner {
    left: unset;
    right: 0;
  }

  .aside {
    bottom: 0;
  }
}

.dropDownList {
  overflow: hidden;

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  box-sizing: border-box;

  &.notFormElement {
    top: 40px;
  }

  button {
    text-align: left;
    white-space: nowrap;
  }
}

.noValue {
  padding-left: $basicPadding;
  color: var(--grey-font);
}

.createBtn {
  width: calc(100% - #{$basicPadding} - #{$basicPadding});
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--grey-20);

  color: var(--font);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;

  margin: 0 $basicPadding;
  margin-top: 1.25rem;

  span {
    margin-right: 0.5rem;
  }
}

.label {
  color: #59536b;
  opacity: 0.6;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 1rem 0.5rem 1.875rem;
}

.labelInput {
  flex-direction: row;
  gap: 42px;

  &:first-of-type {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
}
