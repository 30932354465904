@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartsWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 240px 700px;
  gap: 1rem 1rem;
  grid-template-areas: "srcIps dstIps" "sankey sankey";
}
.srcIps {
  @include grafanaChartWrapper;
  grid-area: srcIps;
}

.dstIps {
  @include grafanaChartWrapper;
  grid-area: dstIps;
}

.sankey {
  @include grafanaChartWrapper;
  grid-area: sankey;
  padding: 1rem;
}
