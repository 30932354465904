@import "../../../../helpers/mixins.scss";

.headerSection {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2em;
  color: (--font);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.colorLine {
  width: 100%;
  height: 6px;
  border-radius: 4px 4px 0 0;

  &.essentials {
    background-color: var(--essential-package-color);
  }

  &.premium {
    background-color: var(--premium-package-color);
  }

  &.pro {
    background-color: var(--pro-package-color);
  }

  &.custom {
    background-color: var(--custom-package-color);
  }
}

.headerSectionContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1rem;
  box-sizing: border-box;
  border: var(--basic-border);
  row-gap: 1rem;
  border-radius: 2px 2px 0 0;

  .btns {
    display: flex;
    justify-content: center;
    column-gap: 0.75rem;
  }

  .btn {
    min-width: 4rem;
    border-radius: 10px;
    border: none;
    border: var(--basic-border);
  }

  .iconBtn {
    width: 2rem;
  }

  // ESSENTIALS
  &.essentials {
    border-color: var(--essential-package-color-opacity);
    background-color: var(--essential-package-bg-primary);

    &.active {
      background-color: var(--essential-package-bg-secondary);
    }

    .btn {
      color: var(--essential-package-color);
      border-color: var(--essential-package-color-opacity);
    }

    .btn.activeBtn {
      background-color: var(--essential-package-color);
      color: var(--button-font);
    }
  }

  // PREMIUM
  &.premium {
    border-color: var(--premium-package-color-opacity);
    background-color: var(--premium-package-bg-primary);

    &.active {
      background-color: var(--premium-package-bg-secondary);
    }

    .btn {
      color: var(--premium-package-color);
      border-color: var(--premium-package-color-opacity);
    }

    .btn.activeBtn {
      background-color: var(--premium-package-color);
      color: var(--button-font);
    }
  }

  // PRO
  &.pro {
    border-color: var(--pro-package-color-opacity);
    background-color: var(--pro-package-bg-primary);

    &.active {
      background-color: var(--pro-package-bg-secondary);
    }

    .btn {
      color: var(--pro-package-color);
      border-color: var(--pro-package-color-opacity);
    }

    .btn.activeBtn {
      background-color: var(--pro-package-color);
      color: var(--button-font);
    }
  }

  // CUSTOM
  &.custom {
    border-color: var(--custom-package-color-opacity);
    background-color: var(--custom-package-bg-primary);

    &.active {
      background-color: var(--custom-package-bg-secondary);
    }

    .btn {
      color: var(--button-primary);
      border-color: var(--button-primary);
    }

    .btn.activeBtn {
      background-color: var(--button-primary);
      color: var(--button-font);
    }
  }
}
