.wrapper {
  display: grid;
  margin-right: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;

  .label {
    width: 100%;
    color: var(--font) !important;
    font-size: 0.875rem;
    font-weight: 600;
    opacity: 0.6;
    align-self: center;
  }
}
