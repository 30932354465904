@import "../../helpers/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;

  color: var(--font);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2em;
  height: max-content;
  width: max-content;
  margin: 0 auto;

  svg {
    margin-right: 0.75rem;
    width: 36px;
    height: 36px;
  }
}

.smallerSize {
  font-size: 1.125rem;
  line-height: 1em;

  svg {
    margin-right: 0.75rem;
    width: 22px;
    height: 22px;
  }
}

.title {
  max-width: 10rem;
  width: max-content;
  text-align: center;
  margin-right: 0.75rem;
}
