.previewTitle {
    &>div {
        // todo: fix this
        color: var(--grey-font);
        font-weight: 400;
        font-size: 18px;
    }

    svg {
        height: 18px;
    }
}

.dialog {
    position: relative;
}

.previewFrame {
    position: relative;
    background: var(--background-second-color);
    border: 1px solid #E2E2E8;
    border-radius: 10px;

    height: inherit;
    white-space: pre-line;
    max-height: 100%;
    overflow-y: auto;
    font-family: 'Courier';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 12px;
    align-self: normal;
    text-align: left;
}

.closeBtn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

.copyBtn {
    position: absolute;
    right: 8px;
    top: 8px;

    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    width: 100%;
    flex: 1;
}