@import "../../../../../helpers/mixins.scss";

.wrapper {
  background: var(--background-second-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
  margin-bottom: 3px;
}

.header {
  display: flex;
  width: 100%;
  position: relative;

  &.clickable {
    cursor: pointer;
  }
}

.groupTitle {
  margin-right: 3.75rem;
  width: 130px;
  display: flex;
  flex-direction: column;
}

.groupName {
  margin-right: 2rem;
  display: flex;
  gap: 8px;
  color: var(--font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
}

.groupSubTitle {
  margin-left: 2rem;
  padding-top: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  color: var(--grey-font);
  opacity: 0.8;
}

.groupNoLabels {
  margin-left: 2rem;
  padding-top: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3em;
  color: var(--grey-font);
  opacity: 0.8;
}

.groupFirstSpan {
  color: var(--grey-font);
  opacity: 0.8;
}
.groupSecondSpan {
  color: var(--grey-font);
  opacity: 0.5;
}

.showBtn {
  margin-top: 12px;
  height: fit-content;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }
}

.editBtn {
  height: fit-content;
  margin: 0 25px 0 16px;
}

.deleteBtn {
  height: fit-content;
  margin: 0 25px 0 0px;
}

.inputDiv {
  width: 100%;
  flex-shrink: 0;
  fill: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding-left: 14px;
  color: var(--grey-font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
}

.addDiv {
  width: 85%;
  flex-shrink: 0;
  fill: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding-left: 14px;
  color: var(--grey-font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
}

.groupColumn {
  width: 90px;
  height: 100%;
  flex: none;
  margin-left: 14px;
  margin-right: 10px;
  margin-top: 12px;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
  align-items: center;
}

.labelClassName {
  margin-right: 5px;
  margin-top: 8px;
}

.labelSubnetClassName {
  background-color: rgba(255, 95, 1, 0.1);
  margin-right: 5px;
}

.fireWallBtn {
  margin: 0;
}

.groupRow {
  display: flex;
  align-items: baseline;
}

.members {
  color: var(--grey-font);
  opacity: 0.8;
  flex-shrink: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  display: flex;
  margin-left: 40px;
}

.edit {
  color: var(--grey-font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  margin: 28px 0px 19px 0;
  display: grid;
  grid-template-columns: 150px 1fr 80px;
  gap: 25px;
  width: 76.5%;
}

.editLabel {
  margin-right: 60px;
  width: 140px;
}

.addDropdown {
  margin-left: 15px;
  width: fit-content;
}

.userBox {
  width: 67%;
  fill: var(--item-background);
  border: 1px solid var(--border-color);
  padding: 13px;
  color: var(--grey-font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  display: grid;
  grid-template-columns: 202px auto 25px;
}

.userThumbnail {
  width: 250px;
}

.userNameBox {
  justify-self: baseline;
}

.hidden {
  display: none;
}

.groupInput {
  margin-right: 2rem;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  color: var(--font);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
}

.groupInputName {
  width: 120px !important;
}

.inlineBox {
  width: 90%;
  display: grid;
  grid-template-columns: 166px 1fr;
}

.inlineBoxAddGroup {
  width: 90%;
}

.flexRow {
  display: grid;
  grid-template-columns: 80% 80px 80px;
  gap: 16px;
}

.collapsedGroup {
  display: grid;
  grid-template-columns: 130px 1fr;
  width: 90%;
}

.addGroupBtn {
  font-size: 16px;
  background-color: var(--button-primary);
  margin-right: 0;
  color: var(--button-font);
}
