.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.125rem;
}

.version {
  color: var(--grey-font);
  font-weight: 600;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 8px;

  svg circle {
    fill: var(--green) !important;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.btn {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
