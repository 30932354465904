.details {
  background: var(--background-color);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}

.details div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.details div + div {
  margin: 8px 0 0;
}

.details div:first-child {
  font-weight: 500;
}

.details p {
  margin: 0;
}

.details h6 {
  font-size: inherit;
  margin: 0;
  padding: 2px 8px;
  border-radius: 4px;
}

.details svg {
  display: block;
  margin: 8px auto 0;
}
