.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &.medium {
    margin-bottom: 0.5rem;
  }

  button {
    color: var(--6-b-55-f-0, #6b55f0);
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.optional {
  font-style: italic;
  color: var(--grey-font);
  margin-left: 0.5rem;
  opacity: 0.6;
}

.delete {
  text-align: right;
}

.textWrapper {
  display: flex;
}
