.sectionWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 6.25rem 1fr;
  column-gap: 2.5rem;
}

.title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
  width: 7rem;
}

.subtitle {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.contentWrapper {
  width: 100%;
  height: auto;
  display: grid;
  align-items: flex-start;
  column-gap: 2.5rem;
  row-gap: 1rem;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 40%) !important;
    overflow-x: hidden;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 80% !important;
    overflow-x: hidden;
  }

  &.oneColumn {
    grid-template-columns: 1fr !important;
    @media (max-width: 1300px) {
      grid-template-columns: 1fr !important;
      overflow-x: hidden;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr !important;
      overflow-x: hidden;
    }
  }

  &.btmAlign {
    align-items: flex-end;
  }
}
