.wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 1.25rem 1.25rem;

  box-sizing: border-box;
  border: var(--basic-border);
  border-radius: 4px;
  padding: 0.75rem 3.5rem 1.5rem 1rem;

  position: relative;
}

.deleteBtn {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-radius: 4px;
  border: var(--basic-border);
  box-sizing: border-box;
}
