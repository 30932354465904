@import "../../helpers/mixins.scss";

.contentWrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
  grid-template-rows: 3rem 1fr;
  overflow: scroll;
}
