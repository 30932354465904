.wrapper {
  width: 240px;
  height: 12.5rem;
  display: grid;
  grid-template-rows: 1.5rem 1rem 1fr;
  row-gap: 1rem;
  align-items: flex-start;

  font-size: 0.875rem;
  line-height: 1.2em;
}

.title {
  color: var(--grey-font);
  margin-bottom: 0.5rem;
}

.btn {
  width: 3.25rem;
  justify-content: flex-start;
  color: var(--button-primary);

  &:hover {
    color: var(--button-primary-hover);
  }
}

.hints {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.fileWrapper {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  font-size: 0.875rem;
  line-height: 1.4em;
  color: var(--font);
}

.progressBar {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;

  .bgBar {
    width: 100%;
    height: 3px;
    background: var(--accent-color-lighter-20);
    border-radius: 25px;
  }

  .dynamicBar {
    width: 100%;
    height: 100%;
    background: var(--accent-color);
    border-radius: 25px;
    animation-duration: 0.6s;
    animation-name: slidein;
  }
}

@keyframes slidein {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
