.tableHeader {
  background: var(--item-background);
  align-items: center;
  font-size: 18px;
  display: flex;
  padding: 1rem 0.75rem 1rem 1rem;
  color: var(--grey-font);
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: var(--basic-border);
}
