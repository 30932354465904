.statSection {
  position: relative;
}

.statContent {
  position: relative;
  padding: 18px 24px;
  display: flex;
  column-gap: 40px;
  border-bottom: 1px solid var(--border-color-secondary);
}

.statGraph {
  height: 100px;
}

.statInfo {
  display: flex;
  flex-direction: column;
  width: 200px;
  overflow-x: auto;

  .statInfoTitle {
    font-weight: 600;
    font-size: 14px;
    color: var(--grey-font);

    display: flex;
    align-items: center;
    gap: 8px;

    margin-bottom: 24px;
    word-break: break-all;

    .remote {
      font-style: italic;
    }
  }
}

.graphWrapper {
  position: relative;
}

.graphInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.statValueWrapper {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding-top: 6px;
}

.labelValue {
  color: var(--grey-font);
  opacity: 0.6;
}

.statValue {
  color: var(--grey-font);
  opacity: 1;
}

.grafana {
  height: 225px;
  width: 100%;
}
