@import "src/helpers/vars.scss";

.container {
  height: $navBarHeight;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  background: var(--bottom-nav-background);
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;

  &.zayoContainer {
    background: var(--zayo-font);
  }
}

$iconSize: 28px;

.tabButton {
  display: flex;
  align-items: center;
  color: var(--bottom-nav-active-color);
  text-decoration: none;
  height: 42px;
  padding: 7px 9px;
  box-sizing: border-box;
  opacity: 0.6;

  &.active {
    opacity: 1;
    background: var(--bottom-nav-active-background);
  }

  &.disabled {
    opacity: 1 !important;
    color: var(--bottom-nav-active-background);
    pointer-events: none;
    cursor: not-allowed;
  }

  svg {
    width: $iconSize;
    height: $iconSize;
    margin-right: 0.625rem;
  }
}

.separator {
  height: 20px;
  width: 2px;
  background-color: var(--bottom-nav-inactive-color);
  opacity: 0.5;
}
