@import "../../../helpers/mixins.scss";

.container {
  width: 100%;
  height: calc(100% - 3rem);

  background: var(--background-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 6px;

  padding: 1.5rem 0;
  box-sizing: border-box;
}

.tableWrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  position: relative;

  padding: 0 1.5rem;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
}
