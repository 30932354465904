.systemTabsWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 35px;

  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.675rem;
  margin-bottom: 4px;
  z-index: 100;
}

.timerangeWrapper {
  height: calc(100vh - 214px) !important;
  top: 158px !important;
}
