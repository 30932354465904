@import "src/helpers/mixins";

.tabs {
  width: 50%;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

$animationTime: 200ms;

.tab {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.2em;

  height: 100%;
  transition: color ease-in-out $animationTime;

  position: relative;
  overflow: hidden;

  .btn {
    color: var(--grey-disabled) !important;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:not(.disabled)::after {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--tab-active);
    transition: transform ease-in-out $animationTime,
      opacity ease-in-out 0ms $animationTime;
    transform: translateX(100%);
    opacity: 0;
  }
}

.tab:not(.disabled):hover {
  .btn {
    color: var(--tab-active) !important;
  }

  & ~ .tab:not(.active)::after {
    transform: translateX(-100%);
  }
}

.tab:not(.active)::after {
  transform: translateY(100%);
}

.tabs:hover .tab:hover::after {
  transition-delay: 0ms, 0ms;
}

.tab:hover::after {
  transform: translateX(0%);
  opacity: 1;
}

.tab.active {
  .btn {
    color: var(--tab-active) !important;
  }

  &::after {
    transform: translateX(0%);
    opacity: 1;
  }
}

.tab.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  .btn {
    cursor: not-allowed !important;
  }
}

.tab.semiDisabled {
  opacity: 1;
}

.separator {
  width: 1px;
  height: 60%;
  background-color: var(--border-color);
}

.tooltip {
  @include tooltip;
  width: 160px;
  font-weight: 600;
}
