.header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  column-gap: 14px;
  color: var(--grey-font);
  font-weight: 600;
  font-size: 14px;
  background: rgba(239, 233, 251, 0.7);
  border-left: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #6b55f0;
  }
}
