@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--item-background);
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: "title controls" "chart chart";
  gap: 0.75rem 0.75rem;

  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
}

.title {
  grid-area: title;
  display: flex;
  align-items: center;

  color: var(--grey-font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;

  .statusLabel {
    margin-left: 0.75rem;
    width: auto;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--accent-color-lighter-10);
    padding: 0 2px;
    box-sizing: border-box;
  }
}

.controls {
  grid-area: controls;
  display: flex;
  align-items: center;
  @include rowGap(0.5rem);
}

.timeRange {
  height: calc(100vh - 331px);
  top: 51px;
}

.charts {
  width: 100%;
  height: 100%;
  grid-area: chart;
  border-radius: 4px;
  border: var(--basic-border);
  background: #f9f8fb;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 0.75rem;
}
