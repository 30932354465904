.form {
  display: flex;
  gap: 36px;
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.toggle {
  display: flex;
  gap: 10px;

  button {
    flex: 1;
    padding: 5px 10px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
}

.active {
  background-color: var(--disabled-checked);
}

.label {
  width: 100%;
  color: var(--font);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.checkboxGroup {
  display: flex;
  gap: 10px;
}
