@import "../TableHeaders.module.scss";
@import "../../../../../helpers/mixins";

.labels {
  @include labelsLineMixin;
}

.id {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--grey50);
  cursor: pointer;
}

.tooltip {
  @include tooltip;
}

.fwSegmenBlock{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}

.actionWrapper {
  display: flex;
  gap: 0.2rem;
}
