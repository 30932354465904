.wrapper {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  border-radius: 4px;
  min-width: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;


  & svg {
    min-width: 16px;
  }

  &.medium {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1em;
  }

  &.green {
    border: 1px solid var(--light-green);
    color: var(--green);
  }

  &.greenBorder {
    border: 1px solid var(--light-green);
    color: var(--font);
  }

  &.blue {
    border: 1px solid var(--light-blue);
    color: var(--blue);
  }

  &.lightBlue {
    background: rgba(0, 102, 255, 0.10);
  }

  &.lavender {
    border: 1px solid var(--light-lavender);
    color: var(--lavender);
  }

  &.lavenderBorder {
    border: 1px solid var(--light-lavender);
    color: var(--font);
  }

  &.critical {
    border: 1px solid var(--critical);
    color: var(--critical);
  }

  &.warning {
    border: 1px solid var(--lilac);
    color: var(--lilac);
  }

  &.warningBorder {
    border: 1px solid var(--lilac);
    color: var(--font);
  }

  &.pink {
    border: 1px solid var(--light-pink);
    color: var(--pink);
  }

  &.font {
    border: 1px solid var(--border-color);
    color: var(--font);
  }

  &.hint {
    background-color: var(--navigation-background);
    color: var(--grey60);
  }

  &.interface {
    border: 1px solid var(--toggle-active-knob);
    color: var(--grey-disabled);
  }

  &.entity {
    color: var(--grey-font);
    border: 1px solid #D3CCFB;
  }

  &.accent {
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
  }
}