.wrapper {
  position: relative;
  width: 100%;
  height: 36px;
}

.child {
  position: absolute;
  top: 0;
}
