@import "src/helpers/mixins.scss";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.portWrapper {
  max-width: 12rem;
  height: 3.2rem;
}

.radioWrapper {
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  flex-wrap: nowrap;

  .radioContainer {
    gap: 2rem;
    font-size: 0.75rem;
  }
}
