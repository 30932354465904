@import "src/helpers/mixins";
@import "src/helpers/vars";

.content {
  background: radial-gradient(
        50% 134.37% at 50% 50%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, rgba(89, 83, 107, 0.15), rgba(89, 83, 107, 0.15)),
    linear-gradient(0deg, rgba(107, 85, 240, 0.15), rgba(107, 85, 240, 0.15)),
    #f9f8fb;
  position: relative;
  height: 100%;
  overflow: hidden;
}
