.tableControlsWrapper{
    display: flex;
    align-items: center;
    z-index: 10;
}
.csvWrapper{
    height: 34px;
    font-size: 14px;
    font-weight: bold;
    color: grey;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.csvBtnProp{
    border:1px solid #ced4da;
    border-radius: 4px;
    padding: 5px 7px;
}
.wrap{
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}