@import "../../../helpers/mixins.scss";

.wrapper {
  position: relative;
}

.selectedItems {
  display: grid;
  column-gap: 0.5rem;
  margin-top: 6px;
}

.dropDownLabel {
  @include fieldLabel;
}

.list {
  top: 0;
}
