.row {
  display: grid;
  align-items: flex-end;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
}

.btn {
  width: 100% !important;
  margin-top: 24px;
}