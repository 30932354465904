@import "src/helpers/mixins";

input[type="radio"] {
  @include visuallyHidden;
}

.container {
  @include checkBoxRadioContainer;

  &.labelDisabled {
    color: var(--grey60);
  }
}

.radio {
  @include checkboxRadioBasics;
  border-radius: 100%;
  min-width: 18px;
  margin-right: 0.5rem;

  &.radioDisabled {
    @include checkboxRadioDisabled;
  }

  &.notEditable {
    cursor: not-allowed !important;
  }
}

.tooltip {
  @include tooltip;
  width: 160px;
  font-weight: 600;
}