@import "src/helpers/mixins";

.innerLabel {
  display: block;
  color: var(--grey-font);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: auto;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tag {
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid rgba(89, 83, 107, 0.2);
  color: var(--grey60);
  background: transparent;
  margin-bottom: 0.5rem;

  &.active {
    border-color: rgba(107, 85, 240, 0);
    background: rgba(107, 85, 240, 0.2);
    color: var(--grey);
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
