.asnDropdown {
  position: relative;
  width: 100%;

  .customForm {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-top: 8px;
    border: 1px solid #d8daeb;

    .label {
      display: flex;
      margin-bottom: 8px;
      font-size: 14px;
      align-items: center;

      .input {
        width: 100%;
        padding: 8px;
        border: 1px solid #d8daeb;
        border-radius: 4px;
        margin-top: 4px;
      }

      .asnNameInput {
        width: 100%;
        padding: 8px;
        border: none;
        border-radius: 4px;
        margin-top: 4px;
      }
    }

    .actions {
      display: flex;
      margin-top: 16px;
      justify-self: end;
    }
  }
}
.list {
  position: relative;
  top: 0 !important;
  width: 100%;
}

.title {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #59536b;
  opacity: 0.6;
  margin-bottom: 0.5rem;
}

.cancelButton {
  margin-right: 8px;
}

.validationError {
  font-size: 12px;
  color: #ff5f01;
}

.grey {
  opacity: 0.6;
}

.setUpNewItem {
  border: 1px solid #D8DAEB;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 90%;
  height: 28px;
  border-radius: 4px;
  border-width: 1px;
  padding: 4px;
  color: #6B55F0;
  font-weight: bold;
}
