.activity {
  padding: 3px 4px;
  border-radius: 3px;
}

.create {
  background-color: var(--green-opacity);
  color: var(--green);}

.update {
  background-color: var(--lilac-opacity);
  color: var(--lilac);
}

.delete, .disable {
  background-color: var(--pink-10);
  color: var(--critical);
}
