.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.check {
  display: flex;
  column-gap: 18px;
  font-weight: 600;
}

.checkName {
  width: 40px;
  color: var(--grey-font);
  opacity: 0.6;
}

.checkValue {
  color: var(--grey-font);
}
