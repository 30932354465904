.tableControls {
  display: flex;
  align-items: center;
}

.portsModeSelector {
  cursor: pointer;
  margin: 0 5px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;
  border-bottom: 2px solid transparent;
}

.portsModeSelector.active {
  border-bottom: 2px solid var(--tab-active);
}

.separator {
  font-size: 0.875rem;
  line-height: 1.2em;
}
