@import "../../../../helpers/mixins.scss";

.controlsWrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: var(--basic-border);
  border-bottom: var(--basic-border);
}

.tooltip {
  position: fixed;
  top: 240px;
  left: 255px;
  width: 180px;
}

.showMoreIcon {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;
  width: 22px;
  height: 22px;
  border: 1px solid var(--light-lavender);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: var(--button-primary);
  }

  svg {
    transform: rotate(90deg);
    width: 16px;
  }
}
