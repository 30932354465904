@import "../../../helpers/mixins.scss";

$headerHeight: 132px;
$headerHeightSmall: 112px;
$footerHeight: 60px;

.wrapper {
  overflow: hidden;

  position: absolute;
  top: $headerHeight;
  right: 0;
  width: 282px;
  height: calc(100vh - #{$footerHeight} - #{$headerHeight});

  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--font);

  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  transition: width var(--transition-time) ease-in-out;

  @media (max-height: 900px) {
    top: $headerHeightSmall;
    height: calc(100vh - #{$footerHeight} - #{$headerHeightSmall});
  }

  &.collapsed {
    width: 0;
    overflow: hidden;
    border: none;
  }
}

.btn {
  height: 28px;
  width: 172px;
}

.label {
  width: 100%;
  color: var(--font) !important;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    color: var(--accent-color) !important;

    &:hover {
      color: var(--accent-color) !important;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: var(--font) !important;
  }
}
