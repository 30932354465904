@import "../../../../helpers/mixins.scss";

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.tooltipIcon {
  opacity: 0.6;
  cursor: help;

  &:hover {
    opacity: 1;
  }
}

.tooltipWrapper {
  @include tooltip;
  max-width: 160px;
  pointer-events: all !important;
  display: flex;
  flex-direction: column;
  background-color: var(--item-background) !important;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column;
  row-gap: 1.25rem;
  text-align: left !important;
  align-items: flex-start;
}
