@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.labelBlock {
  width: 50%;
  height: 100%;
  background: var(--accent-color-lighter-60);
  border-radius: 6px;
  text-align: center;

  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--button-font);

  &:last-child {
    width: calc(50% - 1rem);
    margin-left: 1rem;
  }

  &.cloudRouter {
    width: 100% !important;
    background: var(--blue);
  }
  &.elan {
    width: 100% !important;
    background: var(--accent-color-lighter-60);
  }
  &.azPorts {
    background: var(--accent-orange-color);
  }
}
