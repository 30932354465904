@import "src/helpers/mixins";

.container {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}
