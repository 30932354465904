@import "src/helpers/mixins";

.wrapper {
  display: grid;
  grid-template-rows: 4.5rem 1fr;
}

.header {
  width: 100%;
  height: 100%;
  border-bottom: var(--basic-border);

  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding: 0 1.5rem;

  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2em;
  color: var(--grey-font);
}

.content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  @include colGap(2rem);

  box-sizing: border-box;
  padding: 0 1.5rem;
}

.controls {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
}