.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  transition: all 0.3s ease-in-out;

  &.empty {
    align-items: center;
  }
}

.noBorder {
  border: none;
  position: relative;
  &.empty {
    height: unset;
  }

  > * {
    height: max-content;
    grid-template-rows: unset;
  }
}

.addBtn {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--accent-color);
  background: var(--accent-color);
  height: 20px !important;
  margin: auto;
}
