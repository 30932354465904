.tooltip {
  background-color: var(--item-background) !important;
  border: var(--basic-border) !important;
  opacity: 1 !important;
  box-shadow: var(--box-shadow);
  color: var(--grey-font);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1em;
}
