@import "../../../../../helpers/mixins.scss";
.labelName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.noBack {
  background: none;
}

.ips {
  background-color: rgba(0, 182, 240, 0.12);
}

.tooltipTitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--grey-font);
  margin-bottom: 1rem;
}

.tooltipValue {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1em;
  color: var(--font);
  margin-bottom: 0.75rem;
}

.tooltip {
  @include tooltip;
  opacity: 1 !important;
}

.underPopupElement {
  cursor: pointer;
}
