@import "src/helpers/mixins.scss";

.portLabel {
  border-radius: 6px;
  background: var(--active-tab-gradient);
  height: 22px;
  width: 110px;
  position: relative;
  top: 11px;

  .label {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 1px 13px;
  }
}

.zPort {
  @include yScroll;

  width: 100%;
  max-height: 90%;
  display: flex;
  box-shadow: unset;
  overflow-y: visible;
  height: max-content;

  div[class^="CloudItem_fields"] {
    display: flex;
  }
}

.disabled {
  opacity: 0.8;

  * {
    color: var(--grey-font) !important;
  }
}
