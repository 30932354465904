@import "../../../helpers/mixins";

.wrapper {
  width: 100%;
  height: 28px;
  display: grid;
  column-gap: 0.25rem;
  align-items: center;
  position: relative;
}

.withWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.labels {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tooltipTitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--grey-font);
  margin-bottom: 1rem;
}

.tooltipValue {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1em;
  color: var(--font);
  margin-bottom: 0.75rem;
}

.tooltip {
  @include tooltip;
}
