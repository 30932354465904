@import "../../../../helpers/mixins.scss";

.headerBasic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2em;
  color: (--font);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.subTitle {
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.tableHeader {
  display: grid;
  grid-template-columns: 240px repeat(4, 1fr);
  column-gap: 0.75rem;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--font);

  &.shortened {
    grid-template-columns: 240px repeat(3, 1fr);
  }
}
