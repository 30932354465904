.wrapper {
  width: 100%;
  max-width: 278px;
  height: 100%;
  max-height: 278px;
  position: relative;
  box-sizing: border-box;

  background-color: var(--item-background);
  border: var(--basic-border);

  &.withPaddings {
    padding: 1rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.loader {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.btn {
  min-width: 80px;
  min-height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--item-background);
  color: var(--font);
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
}

.placeholderImg {
  filter: blur(3px);
}

.clickable {
  cursor: pointer;
}
