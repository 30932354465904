.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.25rem;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.isDeny > * {
    color: var(--critical) !important;
  }
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--grey-font);
}

.tip {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey60);

  display: flex;
  align-items: center;
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 0.25rem;
}
