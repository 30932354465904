.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: var(--font);
  column-gap: 1rem;

  button {
    cursor: pointer;
  }

  &.lastChildExtraGap button:last-child {
    margin-left: 2rem;
  }
}
