.block {
  width: 32px;
}

.wrapper {
  display: flex;
  max-width: max-content;
  align-items: flex-start;
  gap: 1px;
  max-height: 30px;
  transform: rotate(90deg);
  flex-grow: 0;
}

.tab {
  height: 30px;
  min-width: 140px;
  width: max-content;
  flex-shrink: 0;
  border: 1px solid #D8DAEB;
  background: var(--white);
  display: flex;
  align-items: center;
  cursor: pointer;

  .label {
    width: 100%;
    color: var(--accent-color);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    text-wrap: nowrap;
    transform: rotate(180deg);

    &.showDialog {
      color: var(--white);
    }
  }

  &.active {
    background: #F1EEFE;
  }

  &.orange {
    background: var(--active-tab-gradient);
  }

  &.blue {
    background: rgba(21, 142, 255, 0.7);
  }
}
