@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframeWrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    "stat1 stat1"
    "stat2 stat3"
    "stat4 stat4";
}

.chartBlock {
  background-color: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
  border-radius: 10px;
  overflow: hidden;
}

.stat1 {
  grid-area: stat1;
  height: 760px;
}

.stat2 {
  grid-area: stat2;
}

.stat3 {
  grid-area: stat3;
}

.stat4 {
  grid-area: stat4;
}

.stat2,
.stat3,
.stat4 {
  height: 320px;
}
