.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 3.25rem);
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.viRow {
  display: grid;
  align-items: center;
  grid-template-columns: 35% calc(100% - 35% - 0.75rem);
  column-gap: 0.75rem;
}
