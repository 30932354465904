.appliances {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 16px;

  h4 {
    margin: 0 0 16px;
  }

  h5 {
    margin: 0 0 8px;
  }

  div + div {
    margin: 16px 0 0;
  }

  ul {
    margin: 0 -16px;
    padding: 0;
    list-style: none;

    li {
      list-style: none !important;
      margin: 0;
      padding: 4px 16px;
      cursor: pointer;
      border-left: 2px solid var(--background-color);
      transition: 250ms ease-in-out;
    }

    :global(.active) {
      background: var(--accent-color-opacity);
      border-left-color: var(--accent-color);
    }
  }
}

@media (min-width: 1025px) {
  .appliances {
    position: sticky;
    top: 24px;
  }
}
