.loader {
  color: var(--orange);
  line-height: 0;
  font-size: 0.875rem;

  svg {
    width: 15px;
    height: 15px;
  }
}
