.wrapper {
  position: relative;
}

.button {
  border-radius: 50% !important;
  padding: 0 !important;
  border-color: var(--grey-font) !important;
}

.content {
  position: absolute;
  top: 42px;
  right: -20px;
  z-index: 999;
  background: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;
  width: 18rem;
  max-height: 80vh;
  overflow-y: auto;
}
