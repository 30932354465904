@import "../../../../helpers/mixins.scss";

.contentWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
}

.upperBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.oneUpBox {
  background: var(--background-second-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
  margin-bottom: 3px;
  display: grid;
  grid-template-columns: 40px 1fr 60px;
}

.boxMainInfo {
  display: grid;
  align-items: start;
}

.boxTitle {
  font-weight: 600;
  font-size: 10px;
  line-height: normal;
  color: var(--accent-color);
  text-transform: uppercase;
}

.titleText {
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: var(--font);
}

.healthStatus {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.healthyColor {
  color: var(--green);
}

.unHealthyColor {
  color: var(--orange);
}

.notAvailable {
  color: var(--grey-font);
}

.networkPart {
  display: grid;
  margin-top: 17px;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  color: var(--accent-color);
  background-color: #e9e6fd;
  height: 40px;
  padding-left: 18px;
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px 6px 0 0;
}

.subtitle {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  color: var(--accent-color);
  background-color: #e9e6fd;
  height: 40px;
  padding-left: 18px;
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
}

.headerNetwork {
  display: grid;
  grid-template-columns: 400px 200px 1fr 40px 40px;
  background-color: #f7f6fe;
  height: 40px;
  padding-left: 18px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3em;
  color: var(--grey-font);
  border: var(--basic-border);
}

.nextPart {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.oneLine {
  display: grid;
  grid-template-columns: 400px 200px 1fr;
  background-color: var(--item-background);
  height: 40px;
  padding-left: 18px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3em;
  color: var(--grey-font);
  border: var(--basic-border);
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dataCell {
  display: flex;
  flex-direction: column;
  padding: 19px 0 8px 17px;
  gap: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3em;
  background-color: var(--item-background);
  height: 82px;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--grey-font);
}

.inputAll {
  height: 24px !important;
  width: 94px !important;
}

.configuration {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tableWrapper {
  border-radius: 0;
  border-top: none;
  height: unset;
}

.tableHeader {
  height: 50px;
}

.clickable {
  cursor: pointer;
}

.basicBtn {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--button-primary);
  border-radius: 8px;

  &:hover {
    opacity: 0.6;
  }
}

.switchBtn {
  @extend .basicBtn;
  border-color: var(--green);
  margin-top: 10px;

  svg {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas: "headerWrapper headerWrapper" "tableWrapper tableWrapper";

  &.twoCols {
    grid-template-columns: 1fr 35%;
    grid-template-areas: "headerWrapper headerWrapper" "tableWrapper logsWrapper";
  }
}

.header {
  grid-area: headerWrapper;
}

.table {
  grid-area: tableWrapper;
  border-radius: 0 0 4px 4px !important;
}

.logs {
  grid-area: logsWrapper;
}
