.wrapper {
    display: flex;
    gap: 8px;

    height: 100%;
}

.header {
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
}

.tenantSelector {
    width: 200px;
}