@import "src/helpers/mixins.scss";

@mixin basicPadding {
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.formContainer {
  @include yScroll;
  @include basicPadding;
  display: flex;
  flex-direction: column;

  grid-template-rows: auto;
  row-gap: 1.5rem;
  padding-bottom: 1rem;
}

.fieldsGroup {
  @include colGap(0.5rem);
}

.extraConfig {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "leftCol rightCol"
    "btm btm";

  .leftCol {
    grid-area: leftCol;
  }
  .rightCol {
    grid-area: rightCol;
  }
  .text {
    margin-top: 1rem;
    grid-area: btm;
    text-align: center;
    color: var(--grey-font);
  }
  a {
    color: var(--accent-color);
  }
}
