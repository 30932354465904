.region {
  display: flex;
  align-items: center;
  gap: 4px;
}

.green {
  color: var(--green);
}

.yellow {
  color: var(--orange);
}

.red {
  color: var(--pink);
}
