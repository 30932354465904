.wrapper {
  background: var(--background-second-color);
  border: var(--basic-border);
  border-right: none;
  border-radius: 10px 0 0 10px;

  width: 100%;
  height: 100%;

  padding: 2rem 1.75rem;
  box-sizing: border-box;

  position: relative;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--grey-font);
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, auto);
  row-gap: 0.875rem;
}
