.wrapper {
  position: absolute;
  top: 24px;
  left: 40px;
  z-index: 10;
  font-weight: 600;
}

.step {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey70);
}

.title {
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--font);
}
