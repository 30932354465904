.tip {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2em;
}

.tipTitle {
  color: var(--grey-font);
  margin-bottom: 0.75rem;
}

.tipSubtitle {
  color: var(--grey70);
}
