@import "../../helpers/mixins";
@import "../../helpers/vars.scss";

$tableHeaderHeight: 3.75rem;
$columnHeaderHeight: 3rem;
$tableFooterHeight: 3rem;

.container {
  display: grid;
  grid-template-rows: $tableHeaderHeight $columnHeaderHeight 1fr;
  grid-template-rows: $tableHeaderHeight $columnHeaderHeight 1fr $tableFooterHeight;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: relative;
  border-spacing: 0;
  color: var(--font);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: var(--item-background);

  svg {
    margin-right: 4px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: var(--accent-color);
  }

  li {
    color: var(--font);
    list-style: none;
    margin-bottom: 4px;
    padding: 0;
  }

  li span {
    color: var(--grey-font);
  }

  a {
    text-decoration: none;
    color: var(--accent-color);
  }
}

.titleBox {
  background-color: var(--item-background);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 20px 0.75rem 20px 20px;
  color: var(--grey-font);
  border-bottom: 1px solid var(--border-color);
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}

.title {
  color: var(--font);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 88.889% */
  margin-left: 5px;
}

.main {
  display: grid;
  grid-template-columns: 318px 582px 1fr;
  row-gap: 2rem;
  gap: 2.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  filter: drop-shadow(10px 10px 20px rgba(120, 118, 148, 0.1));
}

.qrColumn {
  margin-left: 2.5rem;
  margin-top: 2.5rem;
}

.showConfigBtn {
  width: 275px;
  margin-top: 1.5rem;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.instrColumn {
  padding-top: 2.5rem;
  display: flexbox;
}

.instructionLogo {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wireGuardLogo {
  width: 121px;
  margin: 8px;
}

.systemButton {
  &:hover {
    cursor: pointer;
  }
}

.userInfoColumn {
  display: flex;
  flex-direction: column;
  border-left: var(--basic-border);
  height: 541px;
  border-radius: 0 0 4px;
  background-color: var(--item-background);
}

.warnMessage {
  margin: 1.5rem !important;
  width: 87.5%;
}
