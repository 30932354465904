@import "src/helpers/mixins.scss";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.inputWrapper {
  width: 8rem;
}

.inputWrapperCenter {
  @extend .inputWrapper;
  align-self: center;
}

.portWrapper {
  width: 12rem;
}

.radio {
  flex-direction: row;
  gap: 25px;
}

.radioWrapper {
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  flex-wrap: nowrap;

  .radioContainer {
    gap: 2rem;
    font-size: 0.75rem;
  }
}
