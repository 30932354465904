@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 1rem;
}

.row {
  height: fit-content;
}

.tableWrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  height: inherit;
}

.tableContent {
  @include yScroll;
  position: relative;
  max-height: 100%;
}

.backBtn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
