.tabsWrapper {
  width: 100%;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 140px;
  column-gap: 0.675rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.675rem;
  margin-bottom: 4px;
}

.timerangeWrapper {
  height: calc(100vh - 212px);
  top: 40px;
  margin-left: 1rem;
}