@import "../../../../helpers/mixins.scss";

.config {
    width: 440px;
    overflow: hidden;
    max-height: inherit;
}

.header {
    @include remoteConfigHeader;
}

.frame {
    background-color: var(--background-second-color);
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-color-secondary);
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
}

.title {
    @include textHeader;
    margin-bottom: 16px;
}

.fields {
    margin-bottom: 16px;
}