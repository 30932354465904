@import "../../../helpers/mixins";

.datePicker {
  @include inputField;
  width: 100%;
  font-size: 14px;
  color: var(--grey-disabled);
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  padding: 6px 10px;

  &:focus-visible {
    outline: none;
    border: 2px solid var(--button-primary);
  }

  &.medium {
    padding: 0.375rem;
    height: 28px;
  }
}

:global {
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--button-primary) !important;
  }

  .react-datepicker {
    border-color: var(--border-color) !important;
  }

  .react-datepicker__time-container,
  .react-datepicker__time-list-item--selected {
    background-color: var(--button-primary) !important;
  }
}
