@import "../../../../helpers/mixins.scss";

.wrapper {
    width: 350px;
    @include lookingGlassPanel;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.panelHeader {
    font-size: 14px;
    @include textHeader;
}

.sectionHeader {
    font-size: 18px;
    @include textHeader;
    opacity: 1;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 100%;
}

.text {
    flex: 1;
    height: 100%;
    font-size: 14px;
    color: var(--grey-font);
    display: flex;
    flex-direction: column;

    p{
        margin-bottom: 8px;
    }

    b{
        display: block;
        color: var(--grey-font);
        margin-top: 4px;
    }
}

.mainText{
    flex: 1;
}

.tip{
    align-self: flex-end;
}