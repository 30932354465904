@import "src/helpers/mixins";

@mixin labelsLineMixin() {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 0.75rem;
  }
}

.toggle {
  @include labelsLineMixin();
}

.value {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--font);
}

.policyWrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.green {
  background-color: rgba(0, 186, 119, 0.08);
}

.gatewayWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  max-width: 250px;
  position: relative;
  flex-wrap: wrap;
}

.noTracked {
  background-color: var(--orange);
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  right: -5px;
  z-index: 1;
  margin-right: 0.25rem;
}

.gateway {
  display: flex;
}

.name {
  background: none !important;
}
