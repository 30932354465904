.portTypeFlag {
  height: 100%;
  width: 30px;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-overflow: ellipsis;

  color: var(--accent-color-lighter-60);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;

  padding: 0.25rem 0rem;
  box-sizing: border-box;
  background-color: var(--item-background-tertiary);
  border-left: var(--basic-border);
}
