.title {
  color: var(--grey60);
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.listContainer {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
}

.listItem {
  position: relative;
  color: var(--grey-font);
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  padding-left: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: var(--grey60);
    border-radius: 50%;
  }
}

.example {
  color: var(--accent-color);
}
