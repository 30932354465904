.td {
  margin: 0;
  padding: 0.375rem 0.25rem 0.375rem 1.25rem;

  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.alignedTop {
  align-items: start;
}

.clickableRow {
  cursor: pointer;
}

.disabledRow {
  cursor: auto;
  opacity: 0.6;
}

.parentRow {
  border-left: 2px solid var(--button-primary);
  background: var(--table-background-accent-secondary) !important;

  &:hover {
    color: var(--button-primary);
    background: var(--table-background-accent-tertiary) !important;
  }

  &.highlightedRow {
    border-left-color: var(--pink);
    background: var(--table-background-highlight) !important;
  }
}

.settingsCell {
  z-index: 1;
  overflow: visible;
  padding: 0;
  position: relative;
  height: 100%;

  & > div {
    width: auto;
    height: 100%;

    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);

    background: linear-gradient(
      to right,
      var(--table-background-accent) 0%,
      var(--table-background-accent-secondary) 67.89%,
      var(--table-background-accent) 100%
    );
  }
}

.expandCell {
  z-index: 4;
  overflow: visible;
  padding: 0;
  position: relative;
  height: 100%;

  & > * {
    position: absolute;
    z-index: 4;
    top: 50%;
    right: 3.75rem;
    transform: translateY(-50%);
  }
}
