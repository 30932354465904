@import "../../../../helpers/mixins.scss";

.wrapper {
    padding: 0px 40px;
    box-sizing: border-box;
    padding-top: 28px;

}

.header{
    border-bottom: 1px solid var(--border-color-secondary);
    padding-bottom: 10px;
    display: flex;
}

.headerLeft,.headerRight{
    display: flex;
    align-items: center;
    gap: 60px;
    flex:1;
}

.headerLeft{
    flex: 1;
}

.headerRight{
    justify-content: right;
}

.title{
    @include textHeader;
    font-size: 24px;
}

.region, .location{
    display: flex;
    align-items: center;
    gap: 12px;
    @include textHeader;
    opacity: 1;
    position: relative;
    top: 3px;
}