@import "src/helpers/mixins.scss";

.container {
  @include yScroll;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.wrapper {
  @include yScroll;
  @include xScroll;

  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto) 1fr;
  height: calc(100% - 72px);
  overflow-x: hidden;
}

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.contentWrapper {
  @include basicPaddings;
  border-bottom: var(--basic-border);

  &:last-child {
    border: none;
  }
}

.footer {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5rem;
  box-sizing: border-box;
  border-top: 1px solid var(--border-color);
}
