.wrapper {
  width: 100%;
  height: 3rem;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-transform: uppercase;
  color: var(--accent-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.content {
  display: flex;
}
