.row {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
}

.checkbox {
    align-items: center;
    margin-bottom: 3px;
}

.errorMsg {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey70);
}