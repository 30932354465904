.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 15rem 1fr 15rem;
  column-gap: 1.25rem;
}

.basicAppearance {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: var(--basic-border);
  background: var(--item-background);
}
