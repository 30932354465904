.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}

.contentWrapper {
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
}

.footerWrapper {
  border-top: var(--basic-border);
  @extend .contentWrapper;
}