@import "../../../helpers/mixins.scss";

.wrapper {
  @include dialogWrapper;
}

.overlay {
  @include overlayLayer;
}

$leftColumnWidth: 16.25rem;
$rightColumnWidth: 44.5rem;
$footerHeight: 5rem;
$generalPadding: 3.125rem;

.dialogContainer {
  @include dialogContainerShape;
  height: 40rem;
  width: 60.75rem;
  display: grid;
  grid-template-columns: 16.25rem auto;
  grid-template-rows: 1fr 1fr $footerHeight;
  gap: 0 0;
  grid-template-areas:
    "left-column content"
    "left-column content"
    "left-column footer";
}

.dialogContainerFull {
  grid-template-rows: 1fr 1fr;

  .contentWrapper {
    overflow: unset;
    max-height: 100%;
  }
}

.leftColumn {
  width: $leftColumnWidth;
  height: 100%;
  padding: $generalPadding 0;
  overflow: hidden;
  box-sizing: border-box;
  grid-area: left-column;
  border-right: var(--basic-border);
  box-shadow: var(--box-shadow-smaller-inner);
}

.content {
  grid-area: content;
  padding: $generalPadding;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

$headerHeight: 2rem;
$headerMargin: 1.75rem;

.title {
  width: 100%;
  height: $headerHeight;
  margin-bottom: $headerMargin;
  padding-bottom: 0.75rem;
  box-sizing: border-box;

  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--font);

  border-bottom: 1px solid var(--border-color);

  &.withoutLine {
    border: none;
  }
}

.contentWrapper {
  @include yScroll();
  width: 100%;
  max-height: calc(100% - #{$headerHeight} - #{$headerMargin});
  height: 100%;
}

.dialogFooter {
  padding: 0.5rem $generalPadding $generalPadding;
  padding-bottom: $generalPadding;
  padding-right: $generalPadding;
  box-sizing: border-box;
  grid-area: footer;
}
