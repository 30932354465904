.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  color: var(--font);
  box-sizing: border-box;
  border: 1px solid var(--border-color);
}

.scrollable {
  overflow: unset;
}
