@import "../../helpers/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 0 4px;
  width: 210px;
  box-sizing: border-box;

  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: width var(--transition-time) ease-out;

  svg {
    width: 16px;
    height: 16px;
  }

  &.collapsed {
    width: 32px;

    .input {
      width: 0;
      overflow: hidden;
      padding: 0;
      max-width: 0;
    }
  }
}

.input {
  flex: 1;
}
