@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  overflow-y: visible;
  width: 100%;
  height: max-content;

  background: var(--background-color);
  border: var(--basic-border);
  border-radius: 10px;

  padding: 1rem 1rem;
  box-sizing: border-box;

  @include colGap(1rem);
  display: flex;
  flex-direction: column;
}
