.header {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 242px;
  height: 28px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;

  // &.demoHeader {
  //   height: 40px;
  //   grid-template-columns: auto 242px;
  //   border: none;
  // }
}

// .tabs {
//   width: fit-content;
//   height: 40px !important;

//   button {
//     width: 227px;
//     font-size: 18px !important;
//     color: var(--grey-font);
//     display: flex;
//     column-gap: 28px;
//     justify-content: flex-start;
//     box-sizing: border-box;
//   }

//   .selectedTab {
//     color: var(--font);
//   }
// }

.timeRange {
  height: calc(100vh - 194px) !important;
  top: 137px !important;
}
