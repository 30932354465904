.wrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas: "headerWrapper headerWrapper" "tableWrapper tableWrapper";

  &.twoCols {
    grid-template-columns: 1fr 35%;
    grid-template-areas: "headerWrapper headerWrapper" "tableWrapper logsWrapper";
  }
  &.notFullHeight {
    grid-template-rows: 56px 14rem;
  }
}

.header {
  grid-area: headerWrapper;
}

.table {
  grid-area: tableWrapper;
  border-radius: 0 0 4px 4px !important;
}

.logs {
  grid-area: logsWrapper;
}