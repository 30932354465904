@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cloudName {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--font);

  .cloudIcon {
    margin-right: 0.5rem;
  }
}

.counter {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2;
  color: var(--grey60);
  width: 120px;
}

.loader {
  width: 0.875rem;
  height: 0.875rem;
}

.right {
  display: flex;
  gap: 1.3rem;
}

.tooltip {
  @include tooltip;

  position: absolute;
  font-weight: 600;
  border-radius: 0.25rem;
  color: #00ba77;
  width: auto;
  text-wrap: nowrap;
  margin: 0 auto;
  height: auto;
  z-index: 100;
  padding: 0.06rem;
  border: 1px solid #e2e2e8;
  background: #ffffff;
  box-shadow: 50px 38px 102.371px 0 rgba(120, 118, 148, 0.14);
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;

  .text {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .logic {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.tooltipExpired {
  @extend .tooltip;

  color: var(--orange);
}
