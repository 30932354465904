@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.placeholderWrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  position: relative;
}

.placeholderImg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

// .content {
//   width: 100%;
//   height: 100%;
//   border-radius: 6px;
//   overflow: hidden;
//   overflow: auto;
//   border: 1px solid var(--border-color);
//   box-sizing: border-box;

//   display: grid;
//   grid-template-areas: "headerItem headerItem" "tableItem chartsItem";
//   grid-template-columns: repeat(2, 50%);
//   grid-template-rows: auto 1fr;
// }

// .header {
//   grid-area: headerItem;
//   border-bottom: var(--basic-border);
// }

// .table {
//   grid-area: tableItem;
//   border-right: var(--basic-border);
//   box-sizing: border-box;
// }

// .charts {
//   grid-area: chartsItem;
// }
