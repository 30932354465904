@mixin filled($color, $bg) {
  background-color: $bg;
  color: $color;
}

@mixin outline($color) {
  background-color: transparent;
  border: 1px solid $color;
  color: var(--grey-font);
}

.wrapper {
  height: 24px;
  width: auto;
  padding: 0.25rem 0.25rem;
  box-sizing: border-box;

  border-radius: 3px;
  display: flex;
  align-items: center;

  //styles

  &.red {
    @include filled(var(--pink), var(--pink-10));
  }
  &.red.outline {
    @include outline(var(--pink-50));
  }

  &.green {
    @include filled(var(--green), var(--green-10));
  }
  &.green.outline {
    @include outline(var(--green-50));
  }

  &.orange {
    @include filled(var(--orange), var(--orange-10));
  }
  &.orange.outline {
    @include outline(var(--orange-50));
  }

  &.default {
    @include filled(var(--lilac), var(--lilac-10));
  }
  &.default.outline {
    @include outline(var(--lilac-50));
  }

  // elements

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3em;
  }
}
