.panelWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;

  &.empty {
    align-items: center;
  }

  [role="row"] {
    color: var(--grey-font);
  }
}

.tabsWrapper {
  max-width: 100%;
  overflow-x: auto;
  padding: 0 0 12px 8px;
  min-height: 40px;
}

.tabs {
  display: flex;
  gap: 10px;
  width: 500px;
  white-space: nowrap;
}

.tableGrouped {
  display: block;

  :global(.table-extraTitle) {
    height: auto;
    min-height: 2.25rem;
    padding: 8px;
  }

  :global(.table-extraTitleWrapper) {
    &:has(.bridged) {
      background-color: var(--orange-5) !important;
    }
  
    &:has(.routed) {
      background-color: rgba(21, 142, 255, 0.04) !important;
    }
  }
}

.groupHeader {
  display: grid;
  column-gap: 10px;
  color: var(--grey-font) !important;
  align-items: center;

  svg path {
    fill: var(--grey-font);
  }

  &.connectionsTab {
    grid-template-columns: 100px 120px auto;
  }

  &Item {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;

    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 2px;
      background: var(--border-color);
      right: 10px;
    }

    &:last-of-type {
      &::after {
        content: unset;
      }
    }
  }

  .connectionItem {
    padding: 2px 3px;
    border-radius: 3px;
  }

  .bridgedItem {
    border: 1px solid var(--orange-50);
  }

  .routedItem {
    border: 1px solid rgba(21, 142, 255, 1);
  }

  .portIcon {
    min-width: 16px;
  }
}

.tooltip {
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 13px;
  row-gap: 13px;

  &ItemLabel {
    color: var(--grey-font);
    font-style: 600;
  }
}

.allItem {
  padding: 2px 3px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  cursor: default;
}