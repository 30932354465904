.text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey-font);

  &.smallFont {
    font-size: 0.75rem;
  }
}
