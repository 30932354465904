.twoColumns {
  display: grid;
  gap: 24px;
  grid-template-columns: 320px minmax(0, 1fr);
  grid-template-rows: 100%;
  align-items: start;
  box-sizing: border-box;
  padding: 0 0 24px;
  color: var(--grey70);

  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    color: var(--font);
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }
}

.charts {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin: 24px 0 0;
}

@keyframes :global(spin) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chart {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 16px;
  min-height: 160px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.75);
    visibility: hidden;
    transition: 250ms;
  }

  &::after {
    content: "";
    position: absolute;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    border-radius: 50%;
    visibility: hidden;
    transition: 250ms;

    :global {
      animation: 1s spin infinite;
      animation-timing-function: linear;
    }
  }

  &[data-loading="true"]::before,
  &[data-loading="true"]::after {
    visibility: visible;
  }

  h4 {
    margin: 0 0 12px;
  }
}

.charts + .chart {
  margin-top: 24px;
}

@media (max-width: 1024px) {
  .twoColumns {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
  }
}
