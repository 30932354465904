.row {
  height: auto;
  min-height: 2.5rem;
  padding: 0;
  box-sizing: border-box;
  min-width: fit-content;

  display: grid;
  align-items: center;

  &.highlighted {
    background: var(--table-background-highlight-extra);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: var(--accent-color);
    }
  }

  cursor: auto;
  border-top: var(--basic-border);
  background: var(--item-background);

  &.noBorders {
    border-top: none;
  }

  &.clickableRow:hover {
    background: var(--label-background);
    cursor: pointer;
  }
}

.td {
  margin: 0;
  padding: 0.375rem 4px 0.375rem 18px;

  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editMode {
  height: 60px;
  padding: 0 4px 12px 18px;
}

.settingsCell {
  z-index: 1;
  overflow: visible;
  padding: 0;
  position: relative;
  height: 100%;

  & > div {
    width: auto;
    height: 100%;

    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }
}

.expandCell {
  z-index: 4;
  overflow: visible;
  padding: 0;
  position: relative;
  height: 100%;

  & > * {
    position: absolute;
    z-index: 4;
    top: 50%;
    right: 3.75rem;
    transform: translateY(-50%);
  }
}

.editControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: var(--font);
  column-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}
