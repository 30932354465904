.wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  column-gap: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
}

.inputField {
  max-width: 50rem;
}

.timerangeWrapper {
  height: calc(100vh - 207px);
  top: 151px;
  margin-left: 1rem;
}