@import "src/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  box-sizing: border-box;
  padding: 1rem 1.25rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.text {
  box-sizing: border-box;
  padding: 0 1.25rem;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4em;
  color: var(--grey-font);
}
