.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--background-color) !important;
  display: grid;
  grid-template-columns: 265px 1fr;
  align-items: center;
  justify-items: center;
}

.content {
  width: 100%;
  height: 100%;
}

.formLogin {
  max-width: 320px;
  margin-top: 40px;
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  padding-bottom: 47px;
  font-size: 26px;
}

.password {
  padding-top: 26px;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.loginButton {
  background: var(--accent-color) !important;
  width: 45%;
  height: 40px;
  color: var(--button-font) !important;
  border-radius: var(--border-radius);
  margin-top: 34px;

  &.zayoColors {
    background: var(--zayo-dark-blue) !important;
  }
}

.cancellButton {
  border: 1px solid var(--accent-color) !important;
  background: transparent !important;
  width: 45%;
  height: 40px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25em;
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--accent-color) !important;
  margin-top: 34px;

  &:hover {
    color: var(--button-primary-hover);
  }

  &.zayoColors {
    background: var(--zayo-dark-blue) !important;
    color: var(--button-font) !important;
    border: none !important;
  }
}

.errorText {
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  color: var(--critical);

  &.visibility {
    visibility: hidden;
  }
}

.inputInternalClass {
  border: 1px solid var(--critical);
}

.leftPart {
  background: linear-gradient(180deg, #0a0336 0%, #395c8d 100%);
  height: 100%;
  width: 265px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  > * {
    z-index: 1;
  }
}

.background {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  z-index: 0;
}

.leftContent {
  height: 1fr;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 103px;
  margin: 34px 30px 34px;
}
