@import "src/helpers/mixins.scss";

@mixin basicPadding {
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.msg {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4em;
  color: var(--grey-font);
  text-align: left;
  width: 250px;
  box-sizing: border-box;
}

.formContainer {
  @include yScroll;
  @include basicPadding;
  display: flex;
  flex-direction: column;
  grid-template-rows: auto;
  row-gap: 1.5rem;
  padding-bottom: 1rem;
}

.prefixForTunnel {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 4px;
}

.title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--font);
  height: 25px;
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  button {
    width: 46%;
  }
}
