.wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
}
.liItem {
  &:first-child {
    border-top: var(--basic-border);
  }

  cursor: pointer;

  border-bottom: var(--basic-border);
  padding: 0.75rem 1.25rem;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.liItem.active {
  color: var(--accent-color);
  background-color: var(--accent-color-lighter-5);

  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: var(--accent-color);
    top: 0;
    left: -2px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid var(--accent-color);
  }
}

.liItem.disabled {
  cursor: not-allowed !important;
}
