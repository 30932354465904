@import "../../../helpers/mixins";
@import "../../../helpers/vars.scss";

$tableHeaderHeight: 3.75rem;
$columnHeaderHeight: 3rem;
$tableFooterHeight: 3rem;

.table {
  display: grid;
  grid-template-rows: $tableHeaderHeight $columnHeaderHeight 1fr;
  grid-template-rows: $tableHeaderHeight $columnHeaderHeight 1fr $tableFooterHeight;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: relative;
  border-spacing: 0;

  color: var(--font);

  border: 1px solid var(--border-color);
  box-sizing: border-box;

  &.tableWithoutFooter {
    grid-template-rows: $tableHeaderHeight $columnHeaderHeight 1fr;
  }
}

.tableHeader {
  background: var(--item-background);
  align-items: center;
  font-size: 18px;
  display: flex;
  padding: 20px 0.75rem 20px 20px;
  color: var(--grey-font);
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  box-sizing: border-box;
}

.tableFooter {
 height: $tableFooterHeight;
 width: 100%;
 display: flex;
 justify-content: flex-end;
 align-items: center;

 background: var(--table-background-secondary);
 padding: 1rem;
 border-top: 1px solid var(--border-color);
 box-sizing: border-box;
}

.tableBody {
  @include yScroll();
  background: var(--item-background);
}

.row {
  height: auto;
  min-height: 2.5rem;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  display: grid;
  border-bottom: var(--basic-border);

  background: var(--item-background);

  &:not(.editableMode):hover {
    background: var(--label-background);
  }

  &.editableMode {
    border-left: 2px solid var(--orange);
  }
}
