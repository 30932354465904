.days {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 8px 188px;
}

.days h5 {
  margin: 0;
}

.healthwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 8px;
}

.healthwrap h5 {
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
}

.health {
  display: flex;
  flex-grow: 1;
  height: 12px;
  border: 1px solid var(--border-color);
  transition: 250ms;
}

.health > div {
  width: 3px;
  cursor: pointer;
  flex-grow: 1;
}

.health > div + div {
  border-left: 1px solid var(--background-color);
}

.health > div:hover {
  transform: scale(1.4);
  box-shadow: 0 0 0 1px var(--background-color);
}
