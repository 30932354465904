.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.2em;
    color: var(--grey60);
    margin-right: 0.25rem;
  }
}

.icon {
  height: 16px;
  transform: rotate(90deg);
}
