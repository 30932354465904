@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartsWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  gap: 1rem;
}

.basicChart {
  @include grafanaChartWrapper;
}

.basicChart:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1;
}

.basicChart:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1;
}

.basicChart:nth-child(3) {
  grid-column: 1 / 4;
  grid-row: 2;
}

.basicChart:nth-child(4) {
  grid-column: 1;
  grid-row: 3;
}

.basicChart:nth-child(5) {
  grid-column: 2;
  grid-row: 3;
}

.basicChart:nth-child(6) {
  grid-column: 3;
  grid-row: 3;
}
