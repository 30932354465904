.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns:  1fr;
  grid-template-rows: repeat(5, 3.25rem);
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
