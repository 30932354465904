@import "src/helpers/mixins";

.main {
  color: var(--grey-font);
  font-weight: 600;
  font-size: 14px;
}

.desc {
  font-size: 14px;
  margin-top: 1rem;
  color: var(--grey-font);
}

.tooltip {
  @include tooltip;
}
  