.basic {
  width: 110px;
  height: 24px;
  border-radius: 4px;
  color: var(--grey-font);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
}

.admin {
  @extend .basic;
  background: rgba(255, 95, 1, 0.1);
}
.tenantAdmin {
  @extend .basic;
  background: rgba(107, 85, 240, 0.1);
}
.viewer {
  @extend .basic;
  background: rgba(255, 199, 1, 0.17);
}
.editor {
  @extend .basic;
  background: rgba(0, 186, 119, 0.15);
}
.remoteUser {
  @extend .basic;
  background: rgba(0, 148, 255, 0.13);
}
