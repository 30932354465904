.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;

  p {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 2rem;
    color: var(--grey-font);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.3em;
  }
}
