@import "../../../../../../helpers/mixins";

.container {
  position: fixed;
  z-index: 10;
  border: 1px solid var(--border-color);
  box-shadow: 50px 38px 102.371px rgba(120, 118, 148, 0.14);
  border-radius: 4px;
  background: var(--background-color);
  padding: 10px;
  @include colGap(5px);
  font-size: 12px;
}

.intName {
  color: var(--grey-font);
}

.vpc {
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 3px;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  height: 20px;
}
