@import "../../../helpers/mixins";

.wrapper {
  height: 100%;
  width: 100%;
}

.textarea {
  @include inputField;
  @include yScroll;
  width: 100%;
  height: 100%;
  resize: none;
  font-family: var(--font-family);

  &:focus {
    outline: none;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;

    * {
      cursor: not-allowed !important;
      color: var(--grey-font) !important;
    }
  }

  &.error {
    border: 1px solid var(--orange);
  }
}

.label {
  @include fieldLabel;
}

.errorText {
  @include fiedlErrorText;
}
