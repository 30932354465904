.device {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;

  h3 {
    background: var(--accent-color-opacity);
    margin: 0;
    padding: 12px 16px;
    font-size: 16px;
  }
}

.device + .device {
  margin: 24px 0 0;
}

.legends {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  max-width: 120px;
}

.legend h5 {
  width: 100%;
  margin: 0;
}

.legend h6 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.legend h6 hr {
  width: 32px;
  border: solid #35a2eb;
  border-width: 3px 0 0;
}

.legend h6:last-child hr {
  border-style: dashed;
}

.legend:last-child h6 hr {
  border-color: #ff6384;
}

.charts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 24px;
  padding: 16px;

  h4 {
    font-weight: 500;
    color: #555555;
    text-transform: uppercase;
    margin: 0 0 8px;
  }
}
