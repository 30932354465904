.pageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
  min-height: 560px;
  height: 100%;
  box-sizing: border-box;
  gap: 20px;
  overflow: hidden;
}

.tableContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.connectionsTable {
  flex: 1.5;
}

.statisticsScreen {
  flex: 1; 
  background: transparent;
}
