.row {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
  row-gap: 0.75rem;
}

.colGroup {
  display: grid;
  align-items: flex-end;
  grid-template-rows: repeat(2, auto);
  row-gap: 0.75rem;
}

.colGroupWithMargin {
  @extend .colGroup;
  margin-top: 24px;
}
