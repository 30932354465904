@import "../../../../helpers/mixins.scss";

.row {
  display: grid;
  grid-template-columns: 240px repeat(4, 1fr);
  column-gap: 0.75rem;
  align-items: center;
  height: 2.75rem;
  row-gap: 0;

  &.shortened {
    grid-template-columns: 240px repeat(3, 1fr);
  }
}

.sectionTitle {
  height: 100%;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--accent-color);
  display: flex;
  align-items: flex-end;
}

.rowTitle {
  padding-right: 1rem;
  box-sizing: border-box;
}

.firstRow > .cell {
  padding-top: 1.5rem;
  box-sizing: border-box;
}

.cell {
  border: 1px solid rgba(89, 83, 107, 0.2);
  border-top: none;
  border-bottom: none;

  height: 100%;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
  text-align: center;

  &.active > div {
    width: 40%;
  }

  &.separator {
    border-bottom: var(--basic-border);
  }

  &.lastRow {
    border-bottom: var(--basic-border);
  }

  &.essentials {
    border-color: var(--essential-package-color-opacity);

    &.active,
    &.separator.active,
    &.lastRow.active {
      background-color: var(--essential-package-bg-primary);
    }
  }

  &.premium {
    border-color: var(--premium-package-color-opacity);

    &.active,
    &.separator.active,
    &.lastRow.active {
      background-color: var(--premium-package-bg-primary);
    }
  }

  &.pro {
    border-color: var(--pro-package-color-opacity);

    &.active,
    &.separator.active,
    &.lastRow.active {
      background-color: var(--pro-package-bg-primary);
    }
  }

  &.custom {
    border-color: var(--custom-package-color-opacity);

    &.active,
    &.separator.active,
    &.lastRow.active {
      background-color: var(--custom-package-bg-primary);
    }
  }
}
