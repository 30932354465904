@import "../../../../helpers/mixins.scss"; 

.guide{
    max-height: inherit;
    overflow: auto;
    height: 100%;
    flex: 1;
}

.header{
    @include remoteConfigHeader;
    display: flex;
    align-items: center;
    gap: 16px;
}

.viewPort{
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dropdown{
    width: 120px;
}

.title{
    font-size: 14px;
    color: var(--grey-font);
    opacity: 0.7;
}