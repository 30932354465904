.wrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border-radius: 4px;
  border: 1px solid #E2E2E8;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(241deg, rgba(255, 255, 255, 0.67) 4.4%, rgba(255, 255, 255, 0.88) 61.77%, rgba(255, 255, 255, 0.69) 119.94%);
  box-shadow: 10px 10px 22.37px 0px rgba(120, 118, 148, 0.14);
  padding: 13px 19px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}