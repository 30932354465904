@import "src/helpers/mixins";

.tabsWrapper {
  width: 100%;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr auto;
  column-gap: 0.675rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.controls {
  @include rowGap(0.75rem);
  height: 100%;
  display: flex;
  align-items: center;

  .btn {
    height: 27px !important;
    border-radius: 4px;
  }
}

