.matrixCellHeader {
  font-weight: 600;
  display: inline-block;
  background: rgba(107, 85, 240, 0.15);
  border-radius: 4px;
  color: var(--grey-font);
  box-sizing: border-box;
  padding: 2px 6px;
}

.corner {
  position: relative;
  color: var(--grey-font);
  opacity: 0.6;
  font-weight: 600;
  width: 70px;
  font-size: 12px;
  height: inherit;
}

.cornerDST,
.cornerSRC,
.cornerLine {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
}

.cornerDST {
  bottom: -5px;
  left: -5px;
}

.cornerSRC {
  top: -5px;
  right: -5px;
}

.cornerLine {
  width: 100%;
  border: 1px solid var(--border-color);
  transform: rotate(20deg);
  top: 8px;
}
