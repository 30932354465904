$diagramBorder: 1px solid #d8daeb;

.DiagramBlock {
  width: 100%;
  background: #fdfcfe;
  display: flex;
  flex-direction: column;
  height: 420px;

  @media screen and (max-height: 1000px) {
    height: 250px;
  }

  &.closed {
    height: unset;
  }

  .DiagramControl {
    border-top: $diagramBorder;
    border-bottom: $diagramBorder;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 20px;
    height: 30px;
    box-sizing: border-box;

    .ControlButton {
      color: var(--accent-color);
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.autoHeight {
  height: auto;
}
