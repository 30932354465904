.wrapper {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  &.collapsed {
    .leftPart {
      width: 100% !important;
    }
    .rightPart {
      display: none;
      width: 0 !important;
    }
    .resizer {
      display: none;
    }
  }
}

.leftPart {
  height: 100%;
  position: relative;
  transition: none;
  margin-right: 0;

  .leftPartContent {
    width: 100%;
    height: 100%;
  }
}

.rightPart {
  height: 100%;
  display: block;
  transition: none;
}

.collapsedButton {
  position: absolute;
  bottom: 50px;
  right: 15px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--item-background);
  box-shadow: var(--box-shadow);
  border: var(--basic-border);

  .expandedIcon {
    margin-top: 1px;
    margin-left: 1px;
    transition: all 0.6s ease-in-out;
    transform: rotate(180deg);
  }

  &.expandedButton .expandedIcon {
    transform: rotate(0deg);
  }
}

.resizer {
  width: 5px;
  height: 100%;
  background-color: transparent;
  cursor: col-resize;
  position: relative;

  &:hover,
  &:active {
    background-color: var(--border-color);
  }

  &::after {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    top: 0;
    bottom: 0;
  }
}
