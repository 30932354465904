@import "../../helpers/mixins.scss";

.tutorialFrame {
  position: relative;
}

.tutorial {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  padding: 50px 28px;
  box-sizing: border-box;
  z-index: 10;
  backdrop-filter: blur(5px);
  background: rgba(33, 29, 46, 0.7);
}

.frame {
  border-radius: 8px;
  background: var(--item-background);
  height: 100%;
  position: relative;
}

.preview {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
}
