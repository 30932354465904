@import "src/helpers/mixins";

.wrapper {
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include rowGap(1.25rem);
}
