@import "src/helpers/mixins";

@mixin labelsLineMixin() {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 0.75rem;
  }
}

.policyWrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.filtersBlock {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subFilter {
  width: max-content;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
}

.noBack {
  background: none;
  padding-left: 0;
}

.nameAndDescription {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
