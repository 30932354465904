@import "src/helpers/mixins";

.container {
  height: 9rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 0.25rem 0.5rem;
  grid-auto-flow: row;

  padding: 0.75rem;
  box-sizing: border-box;

  background-color: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;

  text-decoration: none;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.noData {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header {
  display: grid;
  grid-template-columns: 2rem 1fr;
  column-gap: 0.5rem;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--accent-color-lighter-80);
  white-space: nowrap;

  span {
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--accent-color-lighter-20);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.row {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: center;
}

.titlesItem {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--grey60);
  white-space: nowrap;
  margin-right: 0.5rem;

  text-overflow: ellipsis;
}

.numbersItem {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--font);
  white-space: nowrap;
  margin-left: 0.25rem;
  text-align: center;
}
