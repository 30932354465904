.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.875rem;
  box-sizing: border-box;

  button {
    width: 46%;
  }
}
