.tokenFields {
  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* text */

    color: var(--grey-font);
    margin-bottom: 8px;
  }

  .field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}