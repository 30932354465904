.tokenFields {
  margin-bottom: 18px;

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* text */

    color: var(--grey-font);
    margin-bottom: 8px;
  }

  .orange {
    color: var(--orange);
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
