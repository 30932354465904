@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  min-height: 560px;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  border-bottom: var(--basic-border);
}
