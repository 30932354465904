.wrapper {
    height: auto;

    &:not(:last-child) {
        border-bottom: var(--basic-border);
    }

    padding: 1.25rem 1.5rem;
    box-sizing: border-box;
    width: 100%;
}