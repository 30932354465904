@import "src/helpers/mixins.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  @media (max-height: 900px) {
    padding: 20px;
  }
}

