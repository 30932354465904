.wrapper {
  width: 100%;
  height: auto;
  padding: 1.125rem 1.125rem;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(320px, 35%) 1fr;
  transition: grid-template-columns 0.6s ease-in-out;
}

.wrapper.collapsed {
  grid-template-columns: 100% 0;

  .diagram {
    width: 0;
    border: 0 solid var(--border-color);
    margin-right: 0;
    padding: 1.5rem 0;
    min-width: 0;
  }
}

.diagram {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
