@import "src/helpers/mixins";

.form {
  display: flex;
  gap: 36px;
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.dialog {
  position: relative;
  width: 100%;
  height: max-content;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: unset;
  }
}

.container {
  border: 1.4px solid var(--accent-color);
  > div {
    padding: 11px 22px;
  }
}

.top {
  background: rgba(239, 233, 251, 0.7);
  height: 2rem;
  position: relative;
  border: 1px solid rgba(216, 218, 235, 1);
  display: flex;
  align-items: center;
  color: var(--grey-disabled);

  > svg {
    margin: 0 10px;
  }
}

.noOverflow {
  overflow: unset;
}

.warningMessage {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(255, 95, 1, 0.9);
}

.ipv6field {
  background: rgba(0, 182, 240, 0.2);
}
