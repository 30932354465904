.wrapper {
  display: flex;
  gap: 2rem;
  height: 1.25rem;
}

.consumerInfo {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.consumerTitle {
  font-size: 0.875rem;
  opacity: 0.6;
  line-height: normal;
  align-self: center;
}

.consumerDetail {
  display: flex;
  align-self: center;
}

.consumerIcon {
  opacity: 0.8;
  width: 20px;
  height: 20px;

  &:first-child {
    display: block;
    padding-top: 0.3rem;
  }
}

.consumerCount {
  color: var(--accent-color);
  margin: auto;
  padding-bottom: 0;
  line-height: 1.25rem;
}
