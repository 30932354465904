@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  overflow: auto;
  border: 1px solid var(--border-color);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.header {
  border-bottom: var(--basic-border);
}

