@import "../../helpers/mixins";

.container {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.5rem;

  // in case of backgrounds or border for full width
  & > * {
    padding: 0 0.125rem 0 1.5rem;
  }
}
