.cell {
  position: relative;
  display: flex;
  color: var(--grey-font);
  justify-content: flex-start;
}

.arrow {
  display: flex;
  align-items: center;
  gap: 3px;
  min-width: 60px;
}
