@import "src/helpers/mixins.scss";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;

  > div {
    min-height: 3.2rem;
  }
}

.portWrapper {
  max-width: 12rem;
  height: 3.2rem;
}

.text {
  font-size: 0.75rem;
  line-height: 1rem;
}

.checkboxWrapper {
  @extend.text;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.checkboxWrapperWidth {
  @extend .checkboxWrapper;

  max-width: max-content;
}

.line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1.25rem;
}

.twoBlocksWrapper {
  display: flex;
  gap: 0.8rem;

  svg {
    height: 16px;
    width: 16px;
  }
}

.listWrapper {
  right: -10px;
}

.systemsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.systemsWrapperName {
  margin-top: 14px;
  display: flex;
  align-items: center;
  gap: 16px;
}
