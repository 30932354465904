.wrapper {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.75rem 1.25rem;

  display: grid;
  grid-template-columns: 26px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "iconWrapper title" "iconWrapper message";
  column-gap: 1rem;

  background: var(--warning-message-background);
  border: var(--warning-message-border);
  border-radius: 2px;
  color: var(--warning-message-color);
  font-size: 0.75rem;
}

.icon {
  grid-area: iconWrapper;
  align-self: center;
}

.title {
  grid-area: title;
  font-weight: 600;
  opacity: 0.8;
}

.message {
  grid-area: message;
  opacity: 0.8;
}
