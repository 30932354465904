@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  display: grid;
  grid-template-columns: 0.4fr 0.6fr 1fr auto;
  grid-template-rows: 1.25rem 1fr auto;
  row-gap: 0.5rem;
  grid-template-areas:
    "labels labels labels services"
    "leftBlock aPort zPort services"
    "footer footer footer footer";

  &.twoCols {
    grid-template-columns: 0.4fr 0.6fr 1fr;
    grid-template-rows: 1.25rem 1fr auto;
    row-gap: 0.5rem;
    grid-template-areas:
      "labels labels labels"
      "leftBlock aPort zPort srvices"
      "footer footer footer";
  }
}

.labels {
  grid-area: labels;
}

.leftBlock {
  grid-area: leftBlock;

  // &.withoutAPort {
  //   border: var(--basic-border) !important;
  //   border-radius: 10px !important;
  // }
}
.aPort {
  grid-area: aPort;
}
.zPort {
  width: calc(100% - 1rem) !important;
  grid-area: zPort;
  margin-left: 1rem;
}
.services {
  width: calc(100% - 1rem) !important;
  grid-area: services;
  margin-left: 1rem;
}
.footer {
  grid-area: footer;
  padding-top: 1.5rem;
  box-sizing: border-box;
}
