@import "../../helpers/mixins.scss";

.wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
}

.copyIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}
