.dialogContent {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.dialogForm {
  box-sizing: border-box;
  height: auto;
  flex: 1;
  padding: 20px 24px;
  padding-right: 80px;
}

.actionButtons {
  width: 300px;
  gap: 1rem;
  display: flex;
  align-items: start;

  button {
    width: 9rem;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 600;
  }

  svg {
    position: relative;
    right: 1.5rem;
  }
}

.baseButton {
  background-color: none;

  svg {
    display: none;
  }

  &.active {
    color: #ffffffff;

    svg {
      display: block;
    }
  }
}

.typeButton {
  @extend .baseButton;

  border: 1px solid var(--accent-color);
  color: var(--accent-color);

  &.active {
    background-color: var(--accent-color);
  }
}

.label {
  color: var(--font);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
}

.servicesCheckboxes {
  display: grid;
  grid-template-columns: repeat(3, 90px);
  padding-top: 0.5rem;
}

.ipInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  padding-top: 0.5rem;
}

.usage {
  padding-top: 0.5rem;
}
