.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  background-color: var(--accent-color-opacity);
  border-radius: 4px;
  min-width: 2rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  &.outlined {
    background-color: transparent;
    border: 1px solid var(--label-background);
  }

  &.small {
    font-size: 12px;
  }
}

span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  color: var(--grey60);
}

.value {
  color: var(--grey-font);
}
