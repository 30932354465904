.tabsWrapper {
  width: 100%;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 140px;
  column-gap: 0.675rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.selectView {
  height: 28px;
  width: 78px;
  display: flex;
  padding: 6px 10px;
  background: var(--table-background-accent);
  margin-bottom: 4px;
  border-radius: 4px;
  box-sizing: border-box;

  justify-content: space-between;
  align-items: center;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.675rem;
  margin-bottom: 4px;
}

.timerangeWrapper {
  height: calc(100vh - 211px);
  top: 155px;
  margin-left: 1rem;
}

.disabled {
  background-color: transparent !important;
}
