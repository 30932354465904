.dialog {
    height: 100%;
    width: 100%;

    display: flex;
    gap: 20px;
}

.dialogInfo {
    width: 240px;
}

.dialogForm {
    flex: 1;
}

.dialogInstruction {
    width: 240px;
}