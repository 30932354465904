.actionButtons {
  gap: 1.5rem;
  
  button {
    width: 9rem;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  svg {
    position: relative;
    right: 1.5rem;
  }
}

.baseButton {
  background-color: none;

  svg {
    display: none;
  }

  &.active {
    color: #ffffffff;

    svg {
      display: block;
    }
  }
}
  
.allowButton {
  @extend .baseButton;

  border: 1px solid #00ba77;
  color: #00ba77;
  
  &.active {
    background-color: #00ba77;
  }
}
  
.webFilteringButton {
  @extend .baseButton;

  border: 1px solid #6b55f0b2;
  color: #6b55f0b2;
  
  &.active {
    background-color: #6b55f0b2;

    svg {
      right: 0;
    }
  }
}

.blockButton {
  @extend .baseButton;

  border: 1px solid #ff406e;
  color: #ff406e;

  &.active {
    background-color: #ff406e;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
