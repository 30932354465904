@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartsWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem 1rem;
}
.basicChart {
  @include grafanaChartWrapper;
}
