.textArea {
  width: 95%;
  height: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  outline: none;
  background: linear-gradient(
      0deg,
      rgba(107, 85, 240, 0.03),
      rgba(107, 85, 240, 0.03)
    ),
    linear-gradient(0deg, rgba(89, 83, 107, 0.02), rgba(89, 83, 107, 0.02));
}
