.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  color: var(--font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;

  display: grid;
  grid-template-columns: 1.25rem 1fr;
  align-items: center;
  column-gap: 0.75rem;

  margin-left: 0.75rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

// .info {
//   color: var(--grey-font);
//   font-size: 0.875rem;
//   font-weight: 400;
//   line-height: 1.3em;
// }
