.wrapper {
  width: 100%;
  height: 100%;

  background: var(--background-second-color);
  border: 1px solid var(--accent-color-lighter-50);
  box-shadow: var(--box-shadow-secondary);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--grey-font);
  margin-bottom: 1rem;
}

.listWrapper {
  list-style: none;
  margin: 0;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: 1.75rem;
  }
}

.item {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey-font);
}
