.code {
  padding: 14px 16px;
  box-sizing: border-box;
  border: 1px solid var(--border-color-secondary);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  white-space: pre-line;
  position: relative;
  margin-bottom: 2px;
}

.copy {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 0.9;
  }
}
