@import "src/helpers/mixins.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.addBtn {
  margin-top: 10px;
  width: 80px;
}

.delBtn {
  align-self: flex-end;
  height: 28px;
}

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.blocksWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.wideInput {
  min-width: 100px;
  white-space: nowrap;
}
