.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 3.25rem);
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.ipv6 {
  background-color: rgba(0, 182, 240, 0.12);
}
