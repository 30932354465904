.dnsResolversPopup {
  position: absolute;
  right: 100px;
  top: 0;
  height: 150px;
  background-color: #f8f9fb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  overflow: auto;
}

.dnsResolversTable {
  width: 100%;
  border-collapse: separate; /* Это важно для работы border-spacing */
  border-spacing: 4px; /* Отступы между ячейками */

  th,
  td {
    text-align: left;
    padding: 3px 8px;
    font-size: 14px;
    border-radius: 4px;
  }

  th {
    color: #555;
    font-weight: 600;
  }

  td {
    color: #333;
  }
}

.flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ipv4Item {
  background-color: #6b55f01a;
}

.ipv6Item {
  background-color: #00b6f01f;
}

.ipv6Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyButton {
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}
