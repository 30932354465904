.noBack {
  background: none;

  > div {
    background: none;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.collapseBtn {
  padding: 0 1.25rem;
  background: none !important;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}
