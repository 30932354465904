@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container {
  @include yScroll;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 720px 320px 320px;
  gap: 16px 16px;
  grid-auto-flow: row;
  grid-template-areas:
    "top top top"
    "middle middle middle"
    "btmLeft btmLeft btmRight";
}

@mixin chartWrapper {
  background-color: var(--item-background);
  border: var(--basic-border);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1.25rem;
  margin: 0;
  padding: 0;
}

.top {
  @include chartWrapper;
  grid-area: top;
}

.middle {
  @include chartWrapper;
  grid-area: middle;
}

.btmLeft {
  @include chartWrapper;
  grid-area: btmLeft;
}

.btmRight {
  @include chartWrapper;
  grid-area: btmRight;
}
