.dialogWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
}

.container {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.rightColumn {
  flex: 1;
  background: #ffffff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.tabContainer {
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 12px;
}

.tab {
  padding: 8px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #6c757d;
  border-radius: 0;
}

.tab.active {
  color: #6610f2;
  border-bottom: 2px solid #6610f2;
}

.contentBlock {
  margin-top: 16px;
  padding: 16px;
  background: #f1f3f5;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: "Courier New", Courier, monospace;
  overflow: auto;
  width: calc(100% - 32px);
  height: 70%;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.leftColumnText {
  font-size: 1rem;
}

.whoisContent {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.ipContent {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  display: flex;
  justify-content: space-around;
}

.contentClassName {
  padding: 0;
}

.ipColumn {
  font-family: Open Sans;
  font-size: 14px;
  text-align: left;
}

.ipTitle {
  margin-bottom: 8px;
}

.ipList {
  display: flex;
  flex-direction: column; /* Элементы идут в столбик */
  gap: 8px; /* Отступы между строками */
  padding: 0;
}

.ipv4Item {
  background-color: #6b55f01a; /* Светлый фон для читаемости */
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.ipv6Item {
  background-color: #00b6f01f; /* Светлый фон для читаемости */
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.textArea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  outline: none;
}

.textArea:focus {
  border-color: #007bff;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}
