@import "../../helpers/mixins";

.inner {
  margin: 12px 8px;
  height: 97%;
  border: 1px solid var(--border, #D8DAEB);
  background-color: white;
  @include yScroll();
  overflow-x: hidden;
}

.wrapper {
  background: var(--item-background);
  border: var(--basic-border);

  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  height: 100%;
}

.grafanaPlaceholder {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartsWrapper {
  height: 100%;
  display: grid;
  gap: 16px;
  align-items: center;
  justify-items: center;
  grid-template-rows: 1fr 1fr 1fr;
}

.chartsCommon {
  grid-template-columns: 25% 75%;
  grid-template-areas: 
    "a b"
    "c c"
    "d d";
}

.chartsItem {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "a a"
    "b b"
    "c c";
}

.chart {
  min-height: 450px;
  width: 100%;
  height: 100%;
}
