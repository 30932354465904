@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include rowGap(0.5rem);
}

.btn {
  width: 100%;
  height: 3rem;
  background: var(--item-background);
  border: var(--basic-border);
  border-radius: 4px;

  color: var(--accent-color);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;

  &:hover {
    border-color: var(--accent-color);
  }
}
