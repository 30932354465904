@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;

  background-color: var(--item-background);
  border: var(--basic-border);
  border-left: none;
  border-radius: 0 0 4px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  overflow: hidden;
}

%basicGrid {
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 0.5rem;
}

.header {
  @extend %basicGrid;
  align-items: center;
  padding: 0 1.5rem;
  box-sizing: border-box;
  border-bottom: var(--basic-border);
}

.content {
  @include yScroll;
  height: 100%;
  margin: 0;
  padding: 1rem 1.5rem;
  list-style: none;
}

.row {
  @extend %basicGrid;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 3rem;
  }

  .key {
    color: var(--font);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
  }

  .value {
    color: var(--grey-font);
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }
}
