@import "../../../helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  background-color: var(--label-background);
  border-radius: 4px;
  min-width: 2rem;
  box-sizing: border-box;

  line-height: 1.25rem;

  &.centered {
    justify-content: center;
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid var(--label-background);
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover .closeIcon {
    opacity: 1;
  }
}

$closeIconSize: 12px;

span {
  overflow: hidden;
  text-overflow: ellipsis;

  &.content {
    width: calc(100% - 0.25rem - #{$closeIconSize});
  }
}

.closeIcon {
  width: $closeIconSize;
  height: $closeIconSize;
  margin-left: 0.25rem;
  opacity: 0.6;
}

.tooltip {
  @include tooltip;
}
