.wrapper {
  display: flex;
  gap: 8px;

  &WithErrors {
    padding-bottom: 19px;
  }
}

.option {
  width: 38px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid var(--accent-color-lighter-20);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &Active {
    border: 1px solid var(--accent-color-lighter-20);
    background: var(--accent-color-lighter-70);
    color: var(--white);
  }
}