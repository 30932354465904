.wrapper {
  width: 100%;
  // height: 2.5rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  column-gap: 0.5rem;
  border-bottom: var(--basic-border);
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.title {
  color: var(--button-primary);
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--button-primary-hover);
  }
}

.count {
  color: var(--grey60);
  font-size: 0.75rem;
  font-weight: 600;
}

.collapseBtn {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1rem;
    height: 0.25rem;
  }

  &.rotate svg {
    transform: rotate(180deg);
  }
}
