.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.delimiter {
  height: max-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  opacity: 0.5;
  width: 6px;
  align-self: flex-end;
  margin-bottom: 8px;
}

.list {
  width: 100%;
}

.ip {
  width: 100%;
}

.prefix {
  width: 75px;
}

.hidden {
  display: none;
}
