.value {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey-font);

  display:flex;
  align-items: center;

  &:hover {
    color: var(--accent-orange-color);
  }
}
