@import "../../../helpers/mixins.scss";

.tooltip {
  position: absolute;
  top: -5px;
  right: 32px;
  border: var(--basic-border);
  border-radius: var(--border-radius);
  padding: 4px 12px;
  box-shadow: var(--box-shadow-smaller);
}
