.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.checkbox {
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--grey-font);

  opacity: 0.6;
}

.submit {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
}
