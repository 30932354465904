@import "../../../helpers/mixins.scss";

.dropDownWrapper {
  display: block;
  width: 100%;
  position: relative;
}

$leftIconSize: 22px;
$arrowIconSize: 10px;

.dropDownLabel {
  width: 100%;
  @include fieldLabel;

  &.errorWithTooltip {
    position: relative;
  }
}

.errorText {
  @include fiedlErrorText;
}

.errorIcon {
  position: absolute;
  bottom: 5px;
  right: 20px;
}
