.wrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
}

.icon {
  transition: all 0.12s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
    transition: all 0.12s ease-in-out;
  }

  &.smallSize {
    width: 9px;
  }
}
