.status {
  display: flex;
  gap: 6px;
  align-items: center;
}

.idle {
  color: var(--lilac);
  svg path {
    fill: var(--lilac) !important;
  }
}

.in_process {
  color: var(--orange);
  svg path {
    stroke: var(--orange) !important;
  }
}

.done {
  color: var(--green);
  svg path {
    stroke: var(--green) !important;
  }
}

.error {
  color: var(--critical);
  svg path {
    stroke: var(--critical) !important;
  }
}