@import "src/helpers/mixins.scss";

$leftIconSize: 22px;
$arrowIconSize: 10px;

.dropDownContainer {
  @include inputField;
  width: 100%;
  justify-content: flex-start;

  &.btnStyles {
    @include btnStyle;

    svg:first-child {
      margin-right: 0.25rem;
      width: $leftIconSize;
      height: $leftIconSize;
    }

    .text {
      width: calc(100% - #{$leftIconSize} - 4px - 8px - #{$arrowIconSize});
    }
  }

  &.error {
    border: 1px solid var(--orange);
  }

  &.smaller {
    height: 20px;
    padding: 0 0.5rem;
  }

  &.medium {
    padding: 0.375rem;
    height: 28px;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;

    * {
      cursor: not-allowed !important;
      color: var(--grey-font) !important;
    }
  }

  &.notEditable {
    background-color: transparent;
    border: 1px solid var(--border-color);

    * {
      color: var(--font) !important;
    }
  }
}

$iconSize: 0.675rem;
$iconMargin: 0.5rem;

.text {
  width: calc(100% - #{$iconSize} - #{$iconMargin});
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  color: var(--grey-font);
}

.dropDownIcon {
  margin-left: auto;
  width: $arrowIconSize;
  height: $arrowIconSize;
  min-width: $arrowIconSize;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.rotateIcon {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }
}

.autocompleteInputContainer {
  @include inputField;
  width: 100%;
  position: relative;
}

$iconSize: 14px;

.autocompleteInputIcon {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
  width: $iconSize;
  height: $iconSize;
}

.autocompleteInputField {
  @include inputFieldBasic;
  margin-left: calc(0.75rem + #{$iconSize});
  width: 100%;

  &.withoutIcon {
    margin-left: 0;
  }

  &::placeholder {
    color: var(--font);
  }
}

.icon {
  margin-right: 0.75rem;
  height: $leftIconSize;
  width: $leftIconSize;
}

.placeholder {
  color: var(--grey60);
  margin-right: 5px;
  text-wrap: nowrap;
}

.closeBtnWrapper{
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &WithMargin {
    margin-right: 8px;
  }
}

.closeIconClassProp{
  cursor: pointer;
  opacity: 0.7;
}