
.cloudPicker {
  display: grid;
  column-gap: 14px;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  margin-bottom: 31px;

  &Item {
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &Selected {
      border: 2px solid var(--accent-color);
    }

    &Disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
      pointer-events: none !important;
    }
  }
}
