@import "src/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
  box-shadow: unset;
}

.controls {
  > button {
    width: 165px;
  }
}

.footer {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.zPort {
  width: 100%;
  height: 90%;
  display: flex;
  border: none;
  border-radius: unset;
  box-shadow: unset;
}

.mainBlock {
  @include yScroll;

  background-color: #fff;
  stroke-width: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .title {
    color: #59536b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 12px;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.8;

  * {
    cursor: not-allowed !important;
    color: var(--grey-font) !important;
  }
}
