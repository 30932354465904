.wrapper {
  padding: 16px 22px;
  --number-of-packages: 3;
}

.header {
  display: flex;
  justify-content: space-between;

  &Title {
    font-weight: bold;
    color: var(--grey-font);
    opacity: 0.6;
  }

  &Names {
    display: flex;
  }

  &Name {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-top: 2px solid var(--accent-color-lighter-20);
  }

  &NameSelected {
    border-top-color: var(--accent-color);
  }
}

.content {
  display: flex;
  flex-direction: column;

  .groupWrapper {
    position: relative;
  }

  .group {
    display: flex;
    flex-direction: column;

    &Name {
      text-transform: uppercase;
      font-weight: bold;
      color: var(--accent-color-lighter-70);
      font-size: 0.75rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    .service:first-of-type {
      .servicePackage, .serviceNameWrapper {
        padding-top: 30px;
      }
    }

    .service:last-of-type {
      .servicePackage, .serviceNameWrapper {
        padding-bottom: 28px;
      }
    }
  }

  .service {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &NameWrapper {
      padding-right: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &Name {
      color: var(--font);
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.3em;

      display: grid;
      grid-template-columns: 1.25rem 1fr;
      align-items: center;
      column-gap: 0.75rem;
      min-width: 220px;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &Packages {
      display: flex;
    }

    &Package {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 12px;
    }
  }
}

.packageName {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
}

.selectedBackground {
  background-color: var(--accent-color-lighter-5);
}

.btn {
  width: 100%;
}

.selectedBtn {
  background: var(--accent-color);
}

.columnWidth {
  width: calc(180px / var(--number-of-packages));
}

.columnFullWidth {
  width: calc(40vw / var(--number-of-packages));
}
