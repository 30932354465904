.wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--item-background);
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto repeat(2, 1fr);
  gap: 0.25rem 0.25rem;
  grid-template-areas: "tab tab tab" "bar bar bar" "tableThreats tableThreats tableApp";
}
.tab {
  grid-area: tab;
  margin-bottom: 0.5rem;
}

//temp
.basic {
  background: #f9f8fb;
  opacity: 0.5;
  border: 1px solid #d8daeb;
  border-radius: 4px;
  font-weight: 600;
  font-size: 22px;
  color: var(--grey60);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center
}

.bar {
  @extend .basic;
  grid-area: bar;
}
.tableThreats {
  @extend .basic;
  grid-area: tableThreats;
}
.tableApp {
  @extend .basic;
  grid-area: tableApp;
}

