@import "../../../../helpers/mixins.scss";

.wrapper {
    width: 240px;
    @include lookingGlassPanel;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1;
}

.header {
    font-size: 18px;
    @include textHeader;
}

.runBtn {
    width: 100%;
    min-height: 30px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 20px;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}