@import "src/helpers/mixins";

.inputField {
  width: 100%;
  @include inputField;
  background-color: var(--iten-background);
  cursor: text;
  padding: 0.375rem;
  padding-left: 1.75rem;
  height: 28px;

  .input {
    width: 100%;
  }
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  width: 14px;
  height: 14px;
  transform: translateY(-50%);
}
