.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.error {
  width: 100%;
  color: var(--orange);
  font-size: 0.75rem;
}

.dialog {
  width: 300px;
}
