.list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;
  margin: 1.75rem 0 3.5rem;
  list-style: none;
  padding: 0;
}

.listItem {
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 1.25rem;
  .title {
    color: var(--font);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
  }
  .value {
    color: var(--grey-font);
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }
}

.navigation {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: var(--basic-border);
    z-index: 0;
  }
}

.devicesRow {
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
