.wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: var(--basic-border);
  background: var(--item-background);
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--accent-color);
  font-size: 0.675rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.value {
  color: var(--font);
  font-size: 1.5rem;
  font-weight: 600;
}
