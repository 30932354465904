@import "src/helpers/mixins";
@import "src/helpers/vars.scss";

.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--main-background);
}

.content {
  @include yScroll();
  height: calc(100vh - #{$layoutHeaderHeight} - #{$navBarHeight}* 2);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
