@import "../../../helpers/mixins.scss";

.container {
  width: 100%;
  height: calc(100% - 3.25rem);

  background: var(--background-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 6px;

  padding-top: 1.5rem;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: 1fr 3.75rem;
  row-gap: 1.25rem;
}

.tableWrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  position: relative;

  padding: 0 1.5rem;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.footer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  justify-content: flex-end;

  padding: 0 1.5rem;
  box-sizing: border-box;

  border-top: var(--basic-border);

  .btn {
    width: 8rem;
  }
}
