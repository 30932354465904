@import "../../../helpers/mixins";

$innerLabelWidth: 2.25rem;

.inputComponentLabel {
  @include fieldLabel;
  width: 100%;
}

.inputWrapper {
  @include inputField;

  &.medium {
    width: 100%;
    padding: 0.375rem;
    height: 28px;
  }

  &.withInnerLabel {
    margin: 0;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;

    * {
      cursor: not-allowed !important;
      color: var(--grey-font) !important;
    }
  }

  &.error {
    border: 1px solid var(--orange);
  }

  > svg {
    width: 18px;
    height: 18px;
  }

  &.dropDown {
    display: block;
  }

  &.notEditable {
    background-color: transparent;
    border: 1px solid var(--border-color);

    * {
      color: var(font) !important;
      cursor: not-allowed !important;
    }
  }
}

.valueWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  border: none;
  background: none;
  text-align: start;
  position: relative;

  .value {
    width: 100%;
    flex: 1;
    color: var(--font);
  }

  input {
    @include inputFieldBasic;
  }

  &.withInnerLabel {
    display: flex;
    align-items: center;
  }
}

.noValue {
  padding-left: 0.5rem;
  width: 100%;
}

.errorText {
  @include fiedlErrorText;
  display: flex;
  align-items: center;
}

.disclaimer {
  color: var(--grey-text);
  opacity: 0.6;
}

.innerLabel {
  color: var(--grey-font);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: auto;
  margin-right: 0.5rem;

  &.moveRight {
    order: 2;
  }
}

.errorIcon {
  width: 16px;
}

.rightButton {
  position: absolute;
  border: none !important;
  border-left: 1px solid var(--border-color) !important;
  border-radius: 0 !important;
  width: 26px;
  height: 26px;
  top: -7px;
  right: -7px;
}

$basicPadding: 1.875rem;

.listWrapper {
  position: absolute;
  min-width: 132px;
  z-index: 20;
  overflow: hidden;

  transform: translateY(102px) translateX(-23px);

  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  padding: 1.25rem 0;
  box-sizing: border-box;

  &.medium {
    transform: translateY(96px) translateX(-8px);
  }

  &.hiddenList {
    display: none;
  }
}

.list {
  @include yScroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  max-height: 110px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  padding-right: 1.875rem;
  box-sizing: border-box;
}

.listItem {
  width: 100%;
  height: 1.375rem;
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--font);
  font-size: 0.875rem;
  line-height: 1.2em;
  padding-left: $basicPadding;
  user-select: none;

  &:hover {
    border-color: var(--tab-active);
    color: var(--tab-active);
  }
}

.closeBtnWrapper{
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIconClassProp{
  cursor: pointer;
  opacity: 0.7;
}
