.formWrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 1.25rem 1.25rem;

  margin-bottom: 1rem;
}

.list {
  position: relative;
  top: 0 !important;
  width: 100%;
}

.asnApprovalMessage {
  font-size: 12px;
  color: #ff5f01;
}

.useBGPItem {
  border: 1px solid #d8daeb;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 90%;
  height: 28px;
  border-radius: 4px;
  border-width: 1px;
  padding: 4px;
  color: #6b55f0;
  font-weight: bold;
}

.requestNewWrapper {
  display: flex;
}

.ipTypeDropdown {
  margin-right: 4px;
  width: 50%;
}