.tabsWrapper {
  width: 100%;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr auto;
  column-gap: 0.675rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.tabActions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 0.5rem;
}