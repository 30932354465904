@import "src/helpers/mixins.scss";

@mixin basicPadding {
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.formContainer {
  @include yScroll;
  @include basicPadding;
  display: flex;
  flex-direction: column;

  grid-template-rows: auto;
  row-gap: 1.5rem;
  padding-bottom: 1rem;
}

.fieldsGroup {
  @include colGap(0.5rem);
}
