.tableTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titleButtons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.table {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll */
}

.tableWrapper {
  display: block; /* Correct scroll behavior */
  height: 100%;
}

.addButtonItem {
  width: 190px !important;
  gap: 10px;
  justify-content: flex-start;
  color: var(--accent-color);
}