$basicPadding: 1.875rem;

.row {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  column-gap: 0.5rem;

  &:hover .controlsBtn {
    visibility: visible;
  }

  &.withControls {
    grid-template-columns: 1fr 1.25rem 1.25rem;
  }
}

.listItem {
  width: 100%;
  height: 1.375rem;
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--font);
  font-size: 0.875rem;
  line-height: 1.2em;
  padding-left: $basicPadding;
  user-select: none;
  text-align: left;

  &:hover {
    border-color: var(--tab-active);
    color: var(--tab-active);
  }

  &.disabled {
    color: var(--grey-disabled) !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}

.checkboxes {
  margin-left: $basicPadding;
}

.icon {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.controlsBtn {
  visibility: hidden;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.spanSubtitle {
  color: var(--transparent-gray);
  font-size: 8px;
  position: relative;
  left: -20px;
  bottom: -12px;
}
