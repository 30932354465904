$basicMargin: 10px;

.wrapper {
  width: 100%;
  height: 100%;

  background: var(--item-background);
  mix-blend-mode: normal;
  border: var(--basic-border);
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  border-bottom: var(--basic-border);
  display: grid;
  grid-template-columns: 0.75rem 1.25rem 1fr;
  column-gap: 0.5rem;
  align-items: center;
  background-color: var(--extra-background);
}

.line {
  width: 3px;
  height: 100%;
}

.svgWrapper {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--extra-dusty-magenta);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey-font);
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: space-between;
}

.row {
  display: grid;
  grid-template-columns: 10.5rem 1fr;
  column-gap: 1.25rem;
  align-items: flex-start;

  .label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey60);
  }
}

.threatsBlock {
  display: grid;
  grid-template-columns: repeat(2, 50%);

  gap: 0.875rem 2.5rem;
}
