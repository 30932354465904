@import "../../../helpers/mixins.scss";

.wrapper {
  @include dialogWrapper;
  z-index: 999;
}

.overlay {
  @include overlayLayer;
}

.dialogContainer {
  @include dialogContainerShape;
  padding: 3.125rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.25rem 1fr 2rem;
  row-gap: 2rem;

  &.small {
    width: 35rem;
  }
}

.header {
  width: 100%;
  box-sizing: border-box;

  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--font);
}

.content {
  width: 100%;
  margin-bottom: 2rem;

  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--font);
}
