@import "src/helpers/mixins.scss";

.wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  box-sizing: border-box;
  @include rowGap(0.25rem);
  overflow: hidden;
}

.formWrapper {
  height: 100%;
  width: 320px;
  background: var(--background-second-color);
  border: var(--basic-border);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1.25rem 0;
  display: grid;
  grid-template-rows: 32px 1fr 32px;
  row-gap: 1.5rem;

  &.placeholder {
    @extend .formWrapper;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
