@import "../../../helpers/mixins.scss";

.container {
  width: 100%;
  height: calc(100% - 3.25rem);

  background: var(--background-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 6px;

  padding: 1.5rem 0;
  box-sizing: border-box;
}

.tableWrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  position: relative;

  padding: 0 1.5rem;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.headerRow {
  display: grid;
  grid-template-columns: 240px repeat(4, 1fr);
  column-gap: 0.75rem;
}

.headerBasic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2em;
  color: (--font);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.subTitle {
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.columnTitle {
  @extend .title;
  justify-content: center;
  margin-top: 4.5rem;
  padding: 1rem;
  box-sizing: border-box;

  border-radius: 4px 4px 0 0;
  border: 1px solid var(--custom-package-color);
  background-color: var(--custom-package-bg-primary);

  &.current {
    color: var(--background-color);
    background-color: var(--button-primary);
    border-color: var(--button-primary);
  }
}
