@keyframes slidein {
  from {
    margin-left: 10%;
    opacity: 0;
  }

  to {
    margin-right: 0;
    opacity: 1;
  }
}

.messages {
  position: absolute;
  width: 34%;
  height: max-content;
  left: 33%;
  z-index: 999;
  font-size: 12px;
}

.block {
  border: 1px solid var(--grey-disabled);
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  border-radius: 2px;
  animation-duration: 2s;
  animation-name: slidein;
}

.messagesBlock {
  @extend .block;

  border-top: 2px solid var(--green);

  svg {
    height: 18px;
    width: 18px;
  }
}

.messagesBlockError {
  @extend .block;

  border-top: 2px solid var(--critical);
  margin-top: 0.3rem;
}

.message {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-duration: 2s;
  animation-name: slidein;
  line-height: 18px;

  .detail {
    text-align: center;
    display: flex;
  }

  .name {
    font-weight: 600;
  }

  .desc {
    margin-left: 0.3rem;
    max-width: 400px;
    text-align: left;
  }

  .mainResult {
    margin: 0 0.5rem;
  }

  .error {
    @extend .mainResult;

    color: var(--critical);
  }

  .info {
    @extend .mainResult;

    color: var(--green);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-disabled);
  }
}

.clearAll {
  padding: 0.5rem 0.75rem;
  text-align: right;
  color: #59536b;
  opacity: 0.8;
  cursor: pointer;
}

.close {
  cursor: pointer;
}
