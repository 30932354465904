.tableContent {
  min-width: 750px;
}

.tableGroupTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: baseline;
}

.tableGroupTitleName {
  font-weight: bold;
  color: var(--grey70);
}

.tableGroupTitleDescription {
  font-size: 12px;
  color: var(--font);
}

.extraTitleWrapper {
  background: var(--table-header) !important;
  min-height: 60px;
  align-items: center;
}
