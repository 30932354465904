.dialogFooter {
  position: relative;

  display: flex;
  gap: 24px;

  border-top: 1px solid var(--border-color);

  padding: 12px 20px;
  justify-content: flex-end;

  @media screen and (max-height: 1000px) {
    padding: 5px;
  }
}

.btn {
  width: 150px;
}
