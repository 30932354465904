.btn {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid var(--border-color, #D8DAEB);
}

.circularLoader {
  animation-name: rotation;
  transform-origin: center;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
