.header {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  color: var(--grey60);
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
