.optionalSpan {
  color: var(--grey60);
}

.adminUser {
  display: grid;
  grid-template-columns: 200px 200px;
  column-gap: 23px;
}

.transparentLabel {
  color: transparent;
}
