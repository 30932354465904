@import "../../../helpers/vars.scss";

.navHeader {
  width: 100%;
  height: $navHeaderHeight;
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  background: var(--table-background-accent);

  color: var(--font);
  border-bottom: 1px solid var(--border-color);
}

.path {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--grey-font);
}

.pathEntry {
  font-size: 12px;
  line-height: 28px;
  color: var(--font);

  opacity: 0.6;

  &:hover {
    color: var(--button-primary-hover);
  }
}

.dropdownWrapper {
  width: 7rem;
}

.dropDownPath {
  width: 5rem;
  height: 20px;
}

.slash {
  font-size: 12px;
  line-height: 28px;
  margin: 0 1rem;
}

.complexElement {
  display: grid;
  grid-template-columns: 1rem auto auto;
  align-items: center;
  svg {
    width: 1rem;
    height: 1rem;
  }
}
