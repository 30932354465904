@import "src/helpers/mixins";

.listWrapper {
  position: absolute;
  left: 0;
  top: 32px;

  width: 100%;
  height: auto;
  border-radius: 10px;
  border: var(--basic-border);
  background: var(--item-background);
  box-shadow: 50px 38px 100px 0px rgba(120, 118, 148, 0.24);

  z-index: 99;

  color: var(--accent-color);
  font-size: 1rem;
  font-weight: 600;
  line-height: 14px;
}

@mixin basicPadding {
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;
}

.basicStyles {
  @include basicPadding;
}

.searchValue {
  @include basicPadding;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-right: 0.25rem;
  }

  &:hover {
    background-color: var(--table-background-accent);
  }
}

.navListWrapper {
  width: 100%;
  height: 100%;
  border-top: var(--basic-border);
}

.navListTitle {
  @include basicPadding;
  color: var(--grey60);
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.navList {
  @include yScroll;
  @include xScroll;
  height: 160px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.navItem {
  @include basicPadding;
  width: 100%;
  display: grid;
  grid-template-columns: 1rem 1fr;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--table-background-accent);
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
