.tableHeader {
  background: var(--table-background-accent);
  height: 47px;
  min-height: 47px;
  align-items: center;
  display: grid;
  border-top: var(--basic-border);

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
}

.th {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 18px;
}
