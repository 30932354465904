.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.125rem;
}

.link {
  text-decoration: none;
}
.btn {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
