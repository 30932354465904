.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tab {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
  padding: 0.25rem 0.5rem;

  &.active {
    background: var(--label-background);
    border-radius: 4px;
    color: var(--font);
    padding: 0.25rem 0.5rem;
  }

  &.longTab {
    font-size: 0.75rem;
    white-space: nowrap;
  }
}
