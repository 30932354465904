.dialog {
  width: 800px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #FAF9FF;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
  color: var(--essential-package-color);
}

.terms {
  border-radius: 8px;
  border: 1px solid var(--border-color-secondary);
  background: var(--white);
  padding: 13px 20px;
  height: 278px;
  overflow-y: auto;
}

.wrapper {
  padding-left: 20px;
  margin-top: 30px;
}

.checkbox {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.disclaimer {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  opacity: 0.6;
}

.price {
  margin-top: 10px;
  color: var(--accent-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  opacity: 0.6;
}
