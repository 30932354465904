@import "src/helpers/mixins";

.wrapper {
  background: var(--background-second-color);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px;
  padding: 1.5rem 1.25rem;
  box-sizing: border-box;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &.clickable {
    cursor: pointer;
  }
}

.cloudTitle {
  margin-right: 2rem;
}

.icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.detailsBlock {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  &.showBlock {
    @include yScroll;
    height: auto;
    min-height: 230px;

    transition: height 0.3s ease-in-out;
  }
}
