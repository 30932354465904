.dialogFrame {
    height: 100vh;
    width: 100vw;
}

.dialog {
    max-width: 900px;
    min-width: 768px;
    position: relative;

    padding: 50px;

    background: var(--background-second-color);
    border: 1px solid var(--border-color-secondary);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .dialogCloseBtn {
        position: absolute;
        right: 16px;
        top: 18px;
        color: var(--semi-gray);
        cursor: pointer;
    }
}

.dialogHeader {
    color: var(--grey-font);

    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
}

.dialogFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .helpText {
        color: var(--grey-font);

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        a {
            text-decoration: underline;
            color: var(-accent-color);
        }
    }
}


.setKeyBtn {
    background-color: var(--accent-color);
    padding: 18px 50px;
}

.inputKeyField {
    position: relative;
    top: 1px;
}