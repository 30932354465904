@import "src/helpers/mixins";

.wrapper {
  @include grafanaChartWrapper;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 212px;
}

.title,
.subtitle {
  margin: 0;
  padding: 0;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  text-align: center;
  color: var(--grey-font);
  margin: 2.25rem 0 1rem;
}

.subtitle {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  text-align: center;
  color: var(--grey-font);

  display: flex;
  align-items: center;
}

.icon {
  width: 18px;
  height: 18px;
  background: var(--out-area-color);
  border: var(--basic-border);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0.5rem;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
