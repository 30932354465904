.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 1.5rem 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bwInput {
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 52px;
}

.shrinkWidthField {
  width: calc(50% - 0.5rem);
}

.checkboxField {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
}

.checkboxInfo {
  max-width: 250px;
  opacity: 0.7;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}

.customErrorClassname {
  width: auto;
}

// .radioGroupContainer {
//   grid-column: 1 / span 2; /* Радиогруппа занимает обе колонки */
// }

// .radioGroup {
//   display: flex;
//   gap: 1rem;
//   align-items: flex-start;
//   width: 50%;
// }
