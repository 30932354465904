@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  div[class*="DialogTemplateOneColumn_wrapper"] {
    position: unset;
    width: 100%;
    height: 100%;
  }

  div[class*="DialogTemplateOneColumn_overlay"] {
    height: 100%;
  }
}
