@import "src/helpers/vars.scss";
@import "src/helpers/mixins";

.container {
  width: 100vw;
  height: $layoutHeaderHeight;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--border-color);
  padding: 0 40px;
  background: var(--background-color);
  box-sizing: border-box;
}

.leftPart {
  width: auto;
  height: 100%;

  display: flex;
  align-items: center;

  .data {
    color: var(--grey-disabled);
    margin-left: 58px;
  }
}

.rightPart {
  width: auto;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2rem;
}

.devBtn {
  width: auto;
  height: 2rem;
  padding: 0 0.75rem;
  box-sizing: border-box;
  border: 1px solid var(--accent-color-lighter-20);
  border-radius: 2px;
  color: var(--accent-color);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  &:hover {
    border-color: var(--accent-color);
  }
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.zayoLogo {
  width: 88px;
}
