@import "../../../helpers/mixins.scss";

.contentWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
  grid-template-columns: 1fr 1fr;
}

.btns {
  display: flex;
  align-items: center;
  @include rowGap(1rem);
}

.container {
  width: 100%;
}
