@import "../../../../helpers/mixins";
@import "../../../../helpers/vars.scss";

$tableHeaderHeight: 3.75rem;
$columnHeaderHeight: 3rem;
$tableFooterHeight: 3rem;
$extraHeaderHeight: 2.25rem;

.table {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--item-background);
}

.table.scroll {
  @include xScroll();
  overflow-y: hidden;

  .tableBody {
    @include yScroll();
    overflow-x: hidden;
    height: calc(100% - 47px);

    &.withExtraHeader {
      height: calc(100% - 47px - #{$extraHeaderHeight});
    }
  }

  .tableBody.tableBodyScrollable {
    overflow-x: auto;
  }
}

.tableContent {
  min-width: fit-content;
  width: 100%;
  // min-width: 900px;
  height: 100%;
  overflow: hidden;
}

.emptyRow {
  height: auto;
  min-height: 2.5rem;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  display: grid;
  border-top: var(--basic-border);
  background: var(--item-background);
}

.extraTitle {
  height: $extraHeaderHeight;
  display: flex;
  align-items: center;
  // margin-top: 0.25rem;
  padding: 0 1.25rem;
  box-sizing: border-box;
  border-top: var(--basic-border);
  background-color: var(--table-background-accent-secondary);
  justify-content: space-between;

  font-weight: 600;
  font-size: 0.875rem;
  color: var(--grey50);
}

.extraTitleWrapper {
  background-color: var(--table-background-accent-secondary) !important;
  display: flex;
  justify-content: space-between;
  border-top: var(--basic-border);

  .extraTitle {
    border-top: none;
    background-color: transparent;
  }

  &:hover {
    .groupActions {
      visibility: visible;
    }
  }
}

.right {
  display: inline-flex;

  span {
    text-wrap: nowrap;
    font-size: 0.875rem;
    color: var(--grey50);
    align-self: center;
  }
}

.rightWithOutCarret {
  width: 100px;
}

.collapseBtn {
  padding: 0 1.25rem;
  background: none !important;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}

.groupActions {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-right: 8px;
  visibility: hidden;
}
