@import "src/helpers/mixins";

.wrapper {
  max-height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  grid-template-areas: "tableBlock tableBlock" "scheme scheme";
  border: var(--basic-border);
  border-radius: 4px;
  box-sizing: border-box;

  &.twoCols {
    grid-template-areas: "tableBlock statBlock" "scheme scheme";
  }
}

.tableBlock {
  grid-area: tableBlock;
  border: none;
}

.statBlock {
  grid-area: statBlock;
  border-left: var(--basic-border);
}

.schemeWrapper {
  grid-area: scheme;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(120, 118, 148, 0.1);
}

.hiddenScheme {
  flex: initial;
}

.tableContainer {
  @include xScroll;
  @include yScroll;
  display: block;
}

.groupedTableStyles {
  @include xScroll;
  @include yScroll;
}

.groupedTableHeader, .groupedTableStyles {
  min-width: 744px;
}
