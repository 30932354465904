@import "src/helpers/mixins";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.content {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 15rem 1fr 15rem;
  column-gap: 1.25rem;

  overflow: hidden;
}

.basicBlock {
  width: 100%;
  height: 100%;
  background: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-smaller);
  border-radius: 4px;
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;

  background: var(--background-second-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.contentWrapper {
  @include yScroll;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sectionWrapper {
  height: auto;
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;
  width: 100%;

  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}

.footerWrapper {
  height: auto;
  border-top: var(--basic-border);
  box-sizing: border-box;
  padding: 0.75rem 1rem;
}
