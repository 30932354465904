.wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: var(--basic-border);
  background-color: var(--item-background);
  overflow: hidden;
  box-sizing: border-box;

  img {
    width: 100%;

    &:not(:first-child) {
      margin: 1rem;
      width: calc(100% - 2rem);
    }
  }
}
