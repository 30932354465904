.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.radioGroup {
  margin-top: 28px;
  height: 1.5rem;
}

.singleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shrinkWidthField {
  width: calc(50% - 0.5rem);
}

.customErrorClassname {
  width: auto;
}
