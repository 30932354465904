@import "src/helpers/mixins";

.pageWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 55px;
  gap: 0 0;
  align-items: center;

  background: var(--background-second-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: var(--box-shadow);

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
