@import "src/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}

.contentWrapper {
  @include yScroll;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sectionWrapper {
  padding: 1.25rem 1.5rem;
  box-sizing: border-box;
  width: 100%;

  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}

.footerWrapper {
  height: auto;
  border-top: var(--basic-border);
  box-sizing: border-box;
  padding: 0.75rem 1rem;
}
