@import "src/helpers/mixins.scss";

.sourceWrapper {
  margin-top: 1.25rem;
  border-radius: 0;
  width: 100%;

  button {
    color: var(--grey-font);
    font-size: 1rem;
    font-style: normal;
    margin-bottom: 0.5rem;

    span {
      margin-right: 0;
    }
  }
}
