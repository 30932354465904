@mixin labelsLineMixin() {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 0.75rem;
  }
}
@mixin expandIconMixin() {
  width: 16px;
  height: 11px;
}
