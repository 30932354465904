@import "../../../helpers/mixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-size: 14px;

  &.oneRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.label {
  @include fieldLabel;
  margin-bottom: 0.75rem;
}

.desc {
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  opacity: 0.7;
  margin-left: 25px;
}
