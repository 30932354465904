.filterIcon {
  position: relative;
  display: inline-block;
}

.filterForm {
  position: absolute;
  top: 35px;
  left: 0;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.addButton {
  padding: 6px 12px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #5a4bbc;
}

.plusSign {
  padding: 5px;
  border: 1px solid var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  margin-left: 0.5rem;
  height: 0.7rem;
}
