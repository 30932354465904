@import "src/helpers/mixins.scss";

.container {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.subnetTable {
  margin-top: 10px;
}

