.tableTitleWrapper {
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.tableTitleItem {
  display: flex;
  justify-content: space-between;
}

.button:nth-child(1) {
  margin-right: 4px;
}