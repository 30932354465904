.inputGroups {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;

  & > * {
    width: 98%;
  }
}
