.wrapper {
  height: 100%;
  width: 66%;
  position: relative;
}

.fullWidth {
  width: 99%;
  min-height: 30vh;
}

.controls {
  display: flex;
}

.selector {
  margin: 2px 1rem 0 0;
}

.additionalControls {
  display: flex;
  gap: 14px;

  .statisticsBtn {
    height: 28px;
    width: 28px;
    background: var(--accent-color) !important;
    border-radius: 10px;
  }
}