@import "../../../helpers/mixins.scss";

.systemTabsWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 35px;

  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;
}

.selectSystemView {
  height: 28px;
  width: 62px;
  display: flex;
  box-sizing: border-box;
  padding: 6px 10px;
  background: var(--table-background-accent);
  margin-bottom: 4px;
  border-radius: 4px;
  margin-right: 1rem;

  justify-content: space-between;
  align-items: center;

  > button > svg {
    height: 16px;
    width: 16px;
  }
}

.controls {
  @include rowGap(0.5rem);
  width: 200px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timerangeWrapper {
  top: 35px;
  right: -40px;
  height: calc(100% + 5px);
}
