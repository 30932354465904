.wrapper {
  width: 100%;
  background: var(--background-second-color);
  border: var(--basic-border);
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
}
