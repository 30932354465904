.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text {
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2em;
  color: var(--font);

  svg {
    margin-right: 0.5rem;
  }
}

.title {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--grey-font);
  margin-bottom: 0.75rem;
}
