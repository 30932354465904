.dialogFrame {
    height: 100vh;
    width: 100vw;
}

.dialog {
    max-width: 900px;
    min-width: 800px;
    position: relative;

    padding: 50px;

    background: var(--background-second-color);
    border: 1px solid var(--border-color-secondary);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .dialogCloseBtn {
        position: absolute;
        right: 16px;
        top: 18px;
        color: var(--semi-gray);
        cursor: pointer;
    }
}

.dialogHeader {
    color: var(--grey-font);

    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 3rem;
    align-items: center;
}

.rowLabel {
    width: 200px;
    color: var(--grey-font);
    font-weight: 600;
    opacity: 0.6;
}

.rowInput {
    flex: 1;
}

.rowNsosImages {
    min-height: 110px;
}

.rowVersions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
    border: 1px solid var(--accent-color-lighter-10);
    flex: 1;
    min-height: 110px;
}

.version {
    padding: 5px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background: var(--accent-color-lighter-10);
        cursor: pointer;
    }

    .versionActions {
        display: flex;
        gap: 12px;
    }
}

.downloadWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.downloadBtn {
    height: auto;
    align-self: stretch;
    width: 140px;
}

.newVersion {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    &Content {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &Text {
        color: var(--green);
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &Label {
        color: var(--grey-font);
        font-weight: 600;
        opacity: 0.6;
    }
}

.loaderWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}