.extraGap {
  margin-left: 20px;
}

.controls {
  display: grid;
  grid-template-columns: 1.5rem 0.75rem;
  column-gap: 0.75rem;
}

.basicBtn {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--button-primary);
  border-radius: 8px;

  &:hover {
    opacity: 0.6;
  }
}

.addBtn {
  @extend .basicBtn;
  width: 1.5rem;
  height: 1.5rem;
}

.switchBtn {
  @extend .basicBtn;
  border-color: var(--green);

  svg {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }
}
