@import "src/helpers/mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  row-gap: 0.75rem;
}

.large {
  grid-column-start: 1;
  grid-column-end: 4;
}
.chartWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;

  // &.withBorder {
  //   @include chartBorderMixin;
  // }
}

.appObservabilityCharts {
  height: 590px;
}

