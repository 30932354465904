@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  background: var(--background-color);
  border: var(--basic-border);
  border-radius: 0 10px 10px 0;
  box-shadow: var(--box-shadow-secondary);

  width: 100%;
  height: 100%;

  padding: 2rem 2rem;
  box-sizing: border-box;

  z-index: 10;
}

.basicForm {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  align-items: start;
  justify-items: start;
  row-gap: 1.25rem;
}
