.wrapper {
  width: 5.5rem;
  height: var(--input-height);
  display: grid;
  grid-template-columns: 1fr 12px 12px;
  column-gap: 0.5rem;
  align-items: center;
}

.value {
  text-align: left;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;

  color: var(--grey-font);

  &.isDeny {
    color: var(--critical);
  }
}

.caret {
  width: 12px;
  height: 12px;
  &.rotate {
    transform: rotate(180deg);
  }
}

.customIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--grey60);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 70%;
    height: 70%;
  }
}
