.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 6rem repeat(4, auto);
  row-gap: 0.5rem;
}

.loader {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
