.fieldsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
}

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.textArea {
  height: 300px;
}
