.nsosLabel {
  border: 1px solid #ccf1e4 !important;
}

.caret {
  transform: rotate(90deg);
}

.roleChangeLabel {
  border: 1px solid #ffe7d8 !important;
}

.currScoreGreenLabel {
  background-color: #ccf1e4;
  color: var(--green);
}

.currScoreOrangeLabel {
  background-color: #ffe7d8;
  color: var(--orange);
}

.currScoreRedLabel {
  background-color: #ffb3c5;
  color: var(--pink);
}

.prevScoreGreenLabel {
  border: 1px solid #ccf1e4 !important;
  color: var(--green);
}

.prevScoreOrangeLabel {
  border: 1px solid #ffe7d8 !important;
  color: var(--orange);
}

.prevScoreRedLabel {
  border: 1px solid #ffb3c5 !important;
  color: var(--pink);
}
