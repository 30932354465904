.wrapper {
  margin-top: 4rem;
  color: var(--text, #59536b);
  font-style: normal;
  line-height: normal;
  text-align: center;
}

.headerWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  opacity: 0.6;

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .line {
    width: 100%;
  }
}

.provider {
  width: 100%;
  margin-top: 3rem;
  border-radius: 0.625rem;
  border: 1px solid var(--d-8-daeb, #d8daeb);
  height: 2.5rem;
  cursor: pointer;

  .content {
    padding: 0.5rem 0;
    width: max-content;
    margin: 0 auto;
    display: flex;
    gap: 1rem;

    .contentTitle {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
