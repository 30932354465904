.loader {
  stroke: var(--accent-color);
  stroke-width: 2px;
  stroke-dasharray: 2;
  stroke-dashoffset: 2;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation-duration: 10s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
