@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.5rem;

  border: var(--basic-border);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include colGap(0.75rem);
}

.headerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--font);

  svg {
    width: 2rem;
    height: 2rem;
  }
}
