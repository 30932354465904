.ruleLayout {
  margin-bottom: 8px;
}
.addRuleForm {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: 180px;
  margin-bottom: 8px;
}