.notificationPopup {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 350px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.notificationPopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.notificationPopupIndicators {
  gap: 6px;
}

.oneRow {
  display: flex;
}

.twoRows {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 90%;
}

.notificationPopupIndicator {
  height: 6px;
  background-color: #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.oneRow .notificationPopupIndicator {
  width: 40px;
}

.twoRows .notificationPopupIndicator {
  width: 100%;
}

.notificationPopupIndicatorActive {
  background-color: #6b55f0;
}

.notificationPopupClose {
  color: #59536b;
  opacity: 0.7;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.notificationPopupContent {
  flex: 1;
  display: flex;
  margin-top: 16px;
  height: calc(70% - 16px);
  align-items: flex-start;
  text-align: center;
}

.notificationPopupMessage {
  color: #333;
  font-size: 15px;
  line-height: 1.4;
  text-align: left;
  margin-top: 8px;
  height: 100%;
  overflow-y: scroll;
}

.notificationPopupFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.notificationPopupCounter {
  color: #6b55f0;
  font-weight: 600;
  font-size: 14px;
}

.notificationPopupFooterButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.notificationPopupButtonLater {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b55f0;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
}

.notificationContentIcon {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.notificationContentText {
  height: 60%;
  display: block;
}

.notificationPopupTitle {
  opacity: 0.8;
  color: #59536b;
  text-align: left;
  font-weight: 600;
  font-size: 11px;
}
