@import "src/helpers/mixins";

.pageWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: flex;
  column-gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.basicBlock {
  width: 100%;
  display: grid;
  grid-template-columns: 66% 34%;
  grid-template-rows: 2rem 360px 1fr;
  row-gap: 0.75rem;
  box-sizing: border-box;
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--font);
}

.donutChart {
  justify-content: center;
}

.statContainer {
  width: 100%;
  height: 100%;
  padding: 0.75rem 1.125rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
