.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  @media (max-height: 900px) {
    padding: 20px;
  }
}

.message {
  margin: 2.25rem 0 1rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
  text-align: center;
  color: var(--grey-font);
}

.link {
  text-decoration: none;

  .icon {
    margin-right: 0.5rem;
  }
}
