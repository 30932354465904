.container {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  white-space: nowrap;
  
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    border: 1px solid var(--border-color);
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 0;
  }
}

.containerNoBorder {
  @extend .container;

  &::before {
    border: none;
  }
}

.tab {
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  // border: var(--basic-border);
  background-color: transparent;
  padding: 0 0.5rem;
  box-sizing: border-box;

  font-size: 0.875rem;
  color: var(--grey-disabled);

  border-right: 1px solid var(--border-color);

  &.active {
    background-color: var(--label-background);
    border: 1px solid var(--accent-color-lighter-50);
    color: var(--font);
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--grey-disabled);
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 0.25rem;
  }
}
