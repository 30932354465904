.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.cloudsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 21px;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: flex-end;
}
