.content {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 50%;
  padding-left: 40px;

  .block {
    min-width: 150px;
    display: flex;
    gap: 5px;
  }
}

.smartBlock {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 150px;
}

.contentAZ {
  @extend .content;

  padding-left: 20px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #D8DAEB;
}

.cloudSubTitle {
  font-weight: 600;
  font-size: 12px;
}

.cloudSubTitleOpacity {
  @extend .cloudSubTitle;

  opacity: 0.6;
}

.portLabel {
  border-radius: 6px;
  background: var(--active-tab-gradient);
  height: 22px;
  width: 60px;
  display: inline-flex;

  .label {
    width: 100%;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 2px 4px;
    height: 100%;
  }
}

.azWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}