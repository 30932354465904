@import "src/helpers/mixins";

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5.5rem 1fr auto;
}

.basics {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.title {
  @extend .basics;
  border-bottom: var(--basic-border);
  padding: 2rem 1.25rem 1.5rem;
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;

  .icon {
    width: 2rem;
    height: 2rem;
  }

  span {
    color: var(--grey-font);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
  }
}

.infoLines {
  @include colGap(2.25rem);
  @extend .basics;
  padding: 2rem 1.25rem 1.5rem;
  display: flex;
  flex-direction: column;
}
