@import "../../../helpers/mixins.scss";

.wrapper {
  display: grid;
  grid-template-columns: minmax(1rem, auto) 1fr;
  align-items: center;
  column-gap: 0.5rem;
  
  &.clickable {
    cursor: pointer;
  }
  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }

}