@import "src/helpers/mixins.scss";

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--item-background);
  border-left: var(--basic-border);
  margin-bottom: 1.5rem;
}

.tableWrapper {
  @include yScroll();
}

.table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labels {
  width: max-content;
  display: grid;
  grid-template-columns: 0.75rem 1fr;
  column-gap: 0.5rem;
  justify-content: flex-start;
  text-align: left;
}

.value {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--font);
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectedWrapper {
  display: flex;
  gap: 0.3rem;
}

.flag {
  width: 1.5rem;
  aspect-ratio: 3 / 2;
  border: 1px solid #d8daeb;
}

.labelWrapper {
  background-color: #ffffff;
  border: 1px dotted var(--grey-disabled);
}

.active {
  border-color: var(--green);
}

.greyText {
  color: var(--grey-disabled);
}

.date {
  margin-right: 0.3rem;
}

.timerangeWrapper {
  height: calc(100vh - 211px);
  top: 155px;
  margin-left: 1rem;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.675rem;
  margin-bottom: 4px;
}

.user {
  cursor: pointer;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .back {
    color: var(--accent-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;

    svg {
      margin-right: 0.5rem;
    }
  }
}
