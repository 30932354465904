@import "src/helpers/mixins";

.chartsContainer {
  // @include yScroll;
  overflow: hidden;

  width: 100%;
  height: 100%;
  background: var(--background-second-color);
  border: var(--basic-border);
  box-sizing: border-box;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 16rem 1fr;
}

.charts {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-column-gap: 0.5rem;
  padding: 0;
  border-top: 1px solid var(--border-color);

  .rightChart,
  .leftChart {
    height: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .leftChart {
    border-right: 1px solid var(--border-color);
  }
}

.iframesContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  width: 150px;
  padding: 0.5rem 1rem 0;
  box-sizing: border-box;

  font-size: 1.125rem;
  line-height: 2em;
  color: var(--grey-font);
}
