.vlanRow {
    height: fit-content;
}

.tableWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    height: inherit;
}

.tableContent {
    position: relative;
    max-height: 100%;
    overflow: scroll;
}