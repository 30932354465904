@import "src/helpers/mixins";

.wrapper {
    @include yScroll;
    width: 100%;
    height: 100%;
}

.cloudSection {
    height: auto;
}