@import "src/helpers/mixins";

.formWrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 1.25rem 1.25rem;

  margin-bottom: 1rem;
}

.list {
  width: 100%;
}

.checkboxWrapper {
  display: flex;
  justify-content: end;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 600;
}

.trackedInfo {
  max-width: 250px;
  opacity: 0.5;
}

.bw {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
}

.vlanWrapper {
  position: relative;
  .vlanList {
    width: 100%;
    transform: translateY(50px) translateX(-8px) !important;
    border-radius: 0;
    height: 65px;
  }

  .usedVlan {
    @include yScroll;

    width: calc(100% - 5px);
    position: absolute;
    height: 110px;
    box-sizing: border-box;
    top: 120px;
    border: 1px solid var(--border-color);
    box-shadow: var(--box-shadow);
    background: var(--item-background);
    z-index: 20;
    padding: 1.25rem 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      color: #ff406e;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      opacity: 0.8;
    }

    > div {
      font-size: 0.875rem;
      line-height: 1.2em;
      padding-left: 1.875rem;
    }
  }

  &.empty {
    .usedVlan {
      top: 50px;
      width: 100%;
    }
  }
}
