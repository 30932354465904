@import "src/helpers/mixins";

.wrapper {
  display: grid;
  overflow: hidden;
  grid-template-rows: auto 1fr;
}

.header {
  box-sizing: border-box;
  padding: 1rem 1.25rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
  height: 3.25rem;
}

.infoContainer {
  @include yScroll;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
  p {
    margin: 0;
    padding: 0;
    color: var(--grey-font);
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0.75rem;

    span {
      font-weight: 600;
    }
  }
}
