.wrapper {
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;

  display: flex;
}

.liItem {
  height: 42px;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: inherit;

  background: var(--item-background);
  border: var(--basic-border);
  border-radius: 2px;
  text-transform: uppercase;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);

  &.active {
    color: var(--accent-color);
    background: var(--accent-color-lighter-5);
    border: 1px solid var(--accent-color-lighter-20);
    border-radius: 2px;
    border-top: 2px solid var(--accent-color);
  }

  &.disabled {
    cursor: not-allowed !important;
  }
}
