.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
  border-top: var(--basic-border);

  .controls {
    display: flex;
    align-items: center;
    column-gap: 1.25rem;
  }
}