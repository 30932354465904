.tableWrapper {
  flex: 1;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey-font);
  opacity: 0.6;
  padding: 6px 0;
}

.rulesTable {
  flex: 1;
  height: 100%;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-radius: 6px;
  overflow: hidden;

  & > div > div {
    min-width: unset;
  }
}