.wrapper {
  width: 240px;
  display: grid;
  grid-template-columns: 1.25fr 0.75fr;
  grid-template-rows: repeat(4, auto);
  gap: 0.5rem 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.4em;
}
.title {
  color: var(--grey60);
}
.value {
  color: var(--grey-font);

  .status {
    display: grid;
    grid-template-columns: 0.5rem 1fr;
    column-gap: 0.25rem;
    align-items: center;
  }
}
