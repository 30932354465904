.wrapper {
  display: flex;
  color: #00ba77;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  height: 1.2rem;
  margin-bottom: 1rem;
}
