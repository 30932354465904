.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  transition: grid-template-columns 0.6s ease-in-out;
}

.wrapper.collapsed {
  grid-template-columns: auto 1fr auto;

  .infoWrapper {
    width: 0;
    border: 0 solid var(--border-color);
    margin-right: 0;
    padding: 1.5rem 0;
    min-width: 0;
  }
  
  .additionalWrapper {
    width: 15rem;
    border: 1px solid var(--border-color);
    padding: 1rem 0;
  }
}

.infoWrapper {
  width: 15rem;
  height: 100%;
  margin-right: 1.25rem;
  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-smaller);
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1rem 0;
  transition: width 0.6s ease-in-out, padding 0.6s ease-in-out,
    border 0.6s cubic-bezier(0.37, 0, 0.52, 0.99), margin-right 0.6s ease-in-out;
}

.additionalWrapper {
  @extend .infoWrapper;
  margin-right: 0;
  margin-left: 1.5rem;
  transition: none;
}

.collapsedIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  transform: translate(13.85rem);
  transition: all 0.6s ease-in-out;

  &.expandedIcon {
    transform: translate(-50%) rotate(180deg);
    margin-bottom: 9px;
  }
}
