.wrapper {
  position: relative;
}

.icon {
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
