@import "src/helpers/mixins.scss";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.blocksWrapper {
  display: flex;
  gap: 1rem;
}

.wideInput {
  min-width: 100px;
  white-space: nowrap;
}
