@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  background: var(--item-background);
  border-radius: 6px;
  border: var(--basic-border);
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
  grid-template-rows: 3rem auto;
  overflow: hidden;
}

@mixin basicBlock {
  background: var(--background-second-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 1.875rem;
}

.graphWrap {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  box-sizing: border-box;
  border-right: 1px solid var(--border-color);
}

.header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  column-gap: 14px;
  color: var(--grey-font);
  font-weight: 600;
  font-size: 14px;
  background: rgba(239, 233, 251, 0.7);
  border-left: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #6b55f0;
  }
}
