@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  background-color: var(--main-background);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
}

.title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey-font);
  overflow: hidden;
  text-overflow: ellipsis;
}

.bgpContainer {
  display: flex;
  @include rowGap(0.25rem);
  align-items: center;
  justify-content: flex-end;
}
