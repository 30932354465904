@import "../../../helpers/mixins.scss";

$padding: 1.5rem;

.animatedWrapper {
  position: absolute;
  z-index: 10;
  top: 63px;
  right: -1px;
  width: 282px;
  height: calc(100% - 62px);

  transition: width var(--transition-time) ease-in-out;

  display: grid;
  grid-template-rows: 4.5rem 1fr 4.5rem;
  align-items: flex-start;

  background-color: var(--item-background);
  // @include blurBackground(0.75);
  border: 1px solid var(--border-color);
  box-sizing: border-box;

  &.collapsed {
    width: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.title {
  font-size: 1.125rem;
  line-height: 1.2em;
  padding-bottom: 1.375rem;
  color: var(--grey50);

  padding: $padding $padding;
  box-sizing: border-box;
}

.body {
  @include yScroll;
  width: 100%;
  height: 100%;
  padding: 0 $padding;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: $padding $padding 1rem;
  display: flex;
  align-items: center;
  column-gap: 1.875rem;

  button {
    width: 100%;
  }
}
