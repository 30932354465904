@import "src/helpers/mixins";

@keyframes slidein {
  from {
    min-height: 100%;
  }

  to {
    min-height: 220px;
  }
}

.wrapper {
  z-index: 0;

  &.showDropdown {
    animation-duration: 0.2s;
    animation-name: slidein;
    min-height: 220px;
  }
}

.form {
  position: relative;
  background: var(--background-second-color);
  border: 1px solid #d8daeb;
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px;
  padding: 0.5rem 0 0.75rem;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: flex-end;
  z-index: 10;

  & > * {
    padding-left: 1.25rem;
    box-sizing: border-box;
  }

  .discoverLabel {
    color: var(--text, #59536b);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    opacity: 0.5;
    margin: auto;
  }

  .btn {
    padding: 0;
    width: 110px;
    margin: 0 auto;

    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      opacity: 1;
    }
  }
}

.listTable {
  @include yScroll;
  position: relative;
  border-top: 0;
  height: 280px;
  z-index: 1;
  margin-top: -8px;
  padding-top: 7px;
  padding-bottom: 0;
}
