.button {
  border: var(--basic-border);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
  border-radius: 4px;

  span {
    margin-left: 0.5rem;
  }
}
