@import "src/helpers/mixins";

.wrapper {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
}

.contentWrapper {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: space-between;

  &.collapsed {
    .leftPart {
      transition: all 0.3s ease-in-out;
      width: 100%;
      margin-right: 0;
    }
    .rightPart {
      display: none;
      width: 0;
      margin-left: 1.25rem;
    }
  }
}

.leftPart {
  height: 100%;
  width: 70%;
  min-width: 70%;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-right: 1.25rem;

  .leftPartContent {
    width: 100%;
    height: 100%;
  }
}

.rightPart {
  width: 30%;
  display: block;
}

.collapsedButton {
  position: absolute;
  bottom: 50px;
  right: 15px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--item-background);
  box-shadow: var(--box-shadow);
  border: var(--basic-border);

  .expandedIcon {
    margin-top: 1px;
    margin-left: 1px;
    transition: all 0.6s ease-in-out;
    transform: rotate(180deg);
  }

  &.expandedButton .expandedIcon {
    transform: rotate(0deg);
  }
}
