.prefixesWrapper {
  display: flex;
}

.ipv4 {
  width: 30px;
  height: 24px;
  background: #6b55f01a;
  border-radius: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ipv6 {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  background: #00b6f01a;
  display: flex;
  align-items: center;
  justify-content: center;
}
