.actionCreate {
    color: #00BA77;
    background-color: #E5F8F1;
}

.actionBgp {
    color: #6A82FF;
    background-color: #F0F2FF;
}

.actionDelete {
    color: #FF406E;
    background-color: #FFECF0;
}

.actionUpdate {
    color: #6A82FF;
    background-color: #F0F2FF;
}

.timestamp {
    opacity: 0.6;
    color: var(--grey-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}