@import "../../../helpers/mixins.scss";

.wrapper {
  position: relative;
  min-width: 10rem;

  @include md {
    min-width: 3rem;
  }
}

.dropDownLabel {
  @include fieldLabel;
}

.extraMrg {
  margin-top: 2rem;
}

.tooltip {
  @include tooltip;
  padding: 0.3rem 0.8rem !important;
  pointer-events: all !important;
  display: flex;
  flex-direction: column;
  background-color: var(--item-background) !important;
  border-radius: 0.125rem !important;

  .title {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: normal;
    color: var(--font);
  }
}
