.wrapper {
  cursor: pointer;
  width: 88px;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 0.5rem 1fr 1rem;
  column-gap: 0.5rem;
  align-items: center;

  background-color: #fde4ec;
  border: 1px solid #ffc6d4;

  &.Established {
    width: 60px;
    background-color: #e4f6f1;
    grid-template-columns: 0.5rem 1fr;
    border-color: #b3ebd7;
  }
  &.Disconnect {
    background-color: #fde8de;
    border-color: #ffcfb3;
  }

  span {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey-font);
    text-overflow: unset !important;
  }
}

.statusIcon {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 2px;
}

.configIcon {
  width: 1rem;
  height: 1rem;
}

.tooltip {
  border: var(--basic-border) !important;
  // box-shadow: var(--box-shadow-medium) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  padding: 0 !important;

  .tooltipContent {
    padding: 0.5rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1rem auto;
    align-items: center;
    column-gap: 0.5rem;
    text-overflow: none;

    span {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.2em;
      color: var(--grey-font);
    }
  }
}
