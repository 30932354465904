@import "../../helpers/mixins.scss";

.wrapper {
  &.hide {
    display: none;
  }
  @include tooltip;
  position: absolute;
  width: 240px;
  z-index: 10;

  pointer-events: all;
  display: flex;
  flex-direction: column;

  border: 1px solid;
  background-color: var(--item-background);
}
