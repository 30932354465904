@import "../../../../helpers/mixins.scss";

.loadingIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.attemptsMessage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    white-space: pre-line;
    text-align: center;

    font-size: 12px;
    @include textHeader;
}