.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.columnNoGap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row-gap: none;
}

.descriptionButton {
  justify-content: flex-start;
  color: var(--accent-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}

.checkbox {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 0.75rem;
}

.checkboxWrapper {
  height: 4rem;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
}

.chexboxTitle {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selected {
  border: 2px solid var(--6-b-55-f-0, #6b55f0) !important;
}

.provider {
  margin-bottom: 0.75rem;
  border: 1px solid var(--d-8-daeb, #d8daeb);
  border-radius: 0.125rem;
  cursor: pointer;
  display: flex;
  gap: 0.8rem;
  padding: 0.75rem 1rem;

  .content {
    width: max-content;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .contentTitle {
      font-size: 0.875rem;
    }

    .contentDesc {
      opacity: 0.6;
      font-size: 0.75rem;
    }
  }
}
