.contentWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
}

.tablesWrapper {
  display: grid;
  row-gap: 0.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}
