.wrapper {
  padding-bottom: 0.125rem;
}

.form {
  position: relative;
  border: 1px solid #d8daeb;
  background: var(--background-second-color);
  box-shadow: var(--box-shadow-medium);
  border-radius: 6px;
  padding: 0.5rem 0 0.75rem;
  box-sizing: border-box;
  filter: drop-shadow(1px 5px 5px rgba(120, 118, 148, 0.1));
  stroke-width: 1px;
  stroke: #d8daeb;
  margin-bottom: 1rem;
  z-index: 10;

  > * {
    padding-left: 1.25rem;
    box-sizing: border-box;
  }

  .row {
    display: flex;
  }

  .group {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    flex-grow: 1;
    align-items: flex-end;
    padding: 0.8rem 1.25rem;
    row-gap: 1.75rem;
  }

  .btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem 1.25rem;
    justify-content: flex-end;
  }

  .btn {
    padding: 0;
    width: 140px;
    margin: 0 auto;
  }
}

.label {
  color: var(--text, #59536b);
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 0.75rem;
}

.connectionsPlug {
  position: relative;
  border: 1px solid #d8daeb;
  border-radius: 6px;
  box-sizing: border-box;
  height: 4.25rem;
  color: var(--59536-b, #59536b);

  .text {
    padding: 1.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.6;
  }
}
