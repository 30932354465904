.dialogHeader {
    font-size: 2em;
    font-weight: 500;
    font-size: 24px;
    color: #59536B;
    padding: 20px 24px;
    border-bottom: 1px solid #D8DAEB;
}

.frame {
    padding: 0px;
}

.ipamIcon {
    height: 24px;
    width: 24px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 0px 24px;
}