@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  @include yScroll;
  @include colGap(1.5rem);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1.25rem;
  box-sizing: border-box;
}
