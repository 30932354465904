@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  overflow: hidden;
  background: var(--item-background);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.content {
  @include yScroll;
  @include xScroll;

  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sectionWrapper {
  height: auto;
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
  overflow: visible;

  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}
