.addRuleWrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey-font);
  opacity: 0.6;
  padding: 6px 0;
}

