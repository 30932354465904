.closeTutorial {
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 11;

  border-radius: 50%;
  background: var(--item-background);
  opacity: 0.8;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
