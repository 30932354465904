@import "../../../../../helpers/mixins";

.dialog {
  @include yScroll();
  width: 800px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #FAF9FF;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
  color: var(--essential-package-color);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card {
  border-radius: 8px;
  border: 1px solid var(--border-color-secondary);
  background: var(--white);
  box-shadow: 0px 4px 10px 0px rgba(226, 225, 233, 0.50);
  display: flex;
  align-items: center;
  cursor: pointer;

  &Price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;
    border-radius: 8px;
    border: 1px solid rgba(226, 226, 232, 1);
    justify-content: center;
    padding: 19px 31px 16px 25px;
    background-color: var(--white);

    &Value {
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      line-height: 21px;
    }

    &Period {
      color: var(--grey-font);
      font-size: 10px;
      font-weight: 600;
      line-height: 21px; /* 210% */
      text-transform: uppercase;
    }
  }

  &Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex: 1;
    padding: 19px 28px 17px 30px;
  }

  &Period {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
    }

    &Per, &Fee {
      font-size: 12px;
      font-weight: 600;
      line-height: 21px;
    }

    &Fee {
      opacity: 0.6;
    }
  }

  &Right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &Discount {
    padding: 3px 7px;
    background-color: var(--green-20);
    color: var(--green);
  }
}

.cardSelected {
  border: 1px solid rgba(107, 85, 240, 0.30);
  background: #E6E2FF;
  
  .cardPrice {
    border-color: #BCB1FB;

    &Value {
      color: var(--accent-color);
    }

    &Period {
      color: var(--accent-color-lighter-70);
    }
  }

  .cardPeriod {
    &Title {
      color: var(--accent-color);
    }

    &Per {
      color: var(--accent-color);
    }

    &Fee {
      color: var(--accent-color-lighter-70);
      opacity: 1;
    }
  }
}

.payment {
  margin-top: 26px;
  display: flex;
  border-radius: 8px;
  gap: 40px;
  border: 1px solid var(--border-color-secondary);
  background: rgba(249, 248, 254, 0.80);
  padding: 15px 46px 19px 14px;

  &Method {
    color: var(--accent-color);
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
    text-wrap: nowrap;
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
    }

    &Text {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      opacity: 0.6;
    }
  }
}

.radio {
  border-radius: 28px;
  border: 1px solid var(--border-color-secondary);
  background: #F9F8FE;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioSelected {
  background: var(--accent-color);
}
