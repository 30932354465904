.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--item-background);
  // border-left: var(--basic-border);
}

.basics {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  box-sizing: border-box;
}
