.source {
  display: flex;
  gap: 0.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  flex-wrap: wrap;

  .prefix {
    opacity: 0.6;
    color: var(--font);
    margin-right: 0.5rem;
  }

  svg {
    width: 12px;
    height: 9px;
  }
}

.back {
  background-color: rgba(110, 57, 223, 0.08);
}
