@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  align-items: center;
  gap: 16px 16px;
}
