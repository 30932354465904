.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.5fr 0.5fr;
  border-top: var(--basic-border);
  padding: 1.125rem 1.125rem 2rem;
  box-sizing: border-box;
  column-gap: 1.25rem;
  align-items: flex-start;
}

.btns {
  margin-top: 21px;
}
