.tableContainer {
  height: auto;
  box-sizing: border-box;
  background-color: var(--item-background);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  border: var(--basic-border);
}
