@import "src/helpers/mixins";

.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr 32px;
  background: var(--background-second-color);
  border: var(--basic-border);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1.25rem 0;
}

@mixin basicPadding {
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.location {
  @include basicPadding;
  color: var(--orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  padding-bottom: 1.25rem;
}

.selectorsList {
  @include yScroll;
  @include colGap(1.5rem);
  @include basicPadding;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .selectorItem {
    @include colGap(1rem);
    display: flex;
    flex-direction: column;
  }

  .selectorsTitle {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--grey60);
  }

  .selectorsTitleWithToggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--grey60);
  }
}

.btn {
  margin: 0 1rem;
  align-self: flex-end;
  border: 1px solid var(--grey50);
  color: var(--grey50);
  width: calc(100% - 2rem);
}
