@import "src/helpers/mixins.scss";

.leftPart {
  @include yScroll();
  box-sizing: border-box;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
}

$butoonsHeight: 9rem;
$vertMargin: 1rem;
$eventsCollapsed: 3rem;

.buttonsContainer {
  height: $butoonsHeight;
}
