$colWidth: calc(50% - 1.25rem / 2);
.formWrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: $colWidth $colWidth;
  grid-auto-rows: auto;
  gap: 1.25rem 1.25rem;

  margin-bottom: 1rem;
}
