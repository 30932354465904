@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  position: fixed;
  z-index: 9999;
  background: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  margin-top: calc(var(--input-height) + 4px);

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;

  padding: 1.25rem 1.5rem;
  box-sizing: border-box;

  max-height: 240px;
}
