.button {
  width: 100%;
  color: var(--accent-color);
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  justify-content: flex-end;

  span {
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
  }

  &:hover span {
    width: auto;
    transition: width 0.3s ease-in-out;
  }
}
