.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--item-background);
  border-left: var(--basic-border);
  margin-bottom: 1.5rem;
  &.twoCols {
    grid-template-columns: 60% 40%;
  }
}

.table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.configBox {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  background-color: var(--item-background);
  box-sizing: border-box;
}

.labels {
  width: 90px;
  display: grid;
  grid-template-columns: 0.75rem 1fr;
  column-gap: 0.5rem;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}

.value {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--font);
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnGroup {
  display: flex;
  gap: 1rem;

  .identityBtn {
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;

    .contentWrapper {
      display: flex;
      gap: 0.5rem;
    }

    .icon {
      align-self: center;
    }

    &:hover {
      color: var(--button-font);
      background-color: var(--button-primary-hover);
    }
  }
}
