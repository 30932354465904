.labels {
  width: 90px;
  display: grid;
  grid-template-columns: 0.75rem 1fr;
  column-gap: 0.5rem;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}

.value {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--font);
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.essentials {
  background-color: var(--essential-package-color-opacity);
}

.premium {
  background-color: var(--premium-package-color-opacity);
}

.pro {
  background-color: var(--pro-package-color-opacity);
}

.custom {
  background-color: transparent;
  border: 1px solid var(--custom-package-color);
}
