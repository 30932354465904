@import "./vars.scss";

@mixin flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin yScroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 2px;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--font);
    border-radius: 2px;
  }
}

@mixin xScroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--font);
    border-radius: 2px;
  }
}

@mixin mediumText {
  color: var(--grey-font);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;
}

@mixin inputField {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font-size: 14px;
  justify-content: space-between;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  padding: 11px 22px;
}

@mixin fieldLabel {
  color: var(--font);
  //margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

@mixin fiedlErrorText {
  position: absolute;
  width: 100%;
  bottom: -20px;
  left: 0;
  color: var(--orange);
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin checkBoxRadioContainer {
  display: flex;
  align-items: center;
}

@mixin checkboxRadioBasics {
  display: inline-block;
  height: $checkboxRadioSize;
  width: $checkboxRadioSize;
  box-sizing: border-box;
  background: var(--border-color);
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

@mixin checkboxRadioDisabled {
  cursor: auto;
  background-color: var(--disabled-basic);
  pointer-events: none;
}

@mixin dialogWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

@mixin overlayLayer {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--dialog-background);
  backdrop-filter: blur(8px);
  z-index: 0;
}

@mixin dialogContainerShape {
  max-height: 90vh;
  max-width: 90vw;
  box-sizing: border-box;
  background: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  z-index: 1;
}

@mixin dialogHeaderWithBtmBorder {
  width: 100%;
  padding-bottom: 0.75rem;
  margin-bottom: 1.75rem;
  box-sizing: border-box;

  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--grey-font);

  border-bottom: 1px solid var(--border-color);
}

@mixin rectWrapperStyles {
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-medium);
  border-radius: var(--border-radius);
  background-color: var(--item-background);
}

@mixin btnStyle {
  background: var(--label-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-sizing: border-box;
}

@mixin inputFieldBasic {
  background: none;
  background: unset;
  border: none;
  outline: none;
  color: var(--font);
  font-size: 14px;

  &::placeholder {
    color: var(--grey-font);
    opacity: 0.8;
    font-size: 14px;
  }
}

@mixin blurBackground($bgOpacity) {
  background-color: rgba(255, 255, 255, $bgOpacity);
  backdrop-filter: blur(20px);
}

@mixin tooltip {
  border-color: var(--border-color) !important;
  border-radius: var(--border-radius) !important;
  padding: 16px 20px !important;

  &::after {
    border: none !important;
  }
}

@mixin basicChartWrapper {
  background: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 1.25rem 1.5rem;
}

@mixin colGap($value) {
  &>*+* {
    margin-top: $value;
  }
}

@mixin rowGap($value) {
  &>*+* {
    margin-left: $value;
  }
}

@mixin chartBorderMixin {
  box-sizing: border-box;
  border: var(--basic-border);
  border-radius: 2px;
  padding: 0.5rem;
}

@mixin lookingGlassPanel {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 24px;
  box-sizing: border-box;
  background: var(--background-color);
  height: 100%;
}

@mixin textHeader {
  font-weight: 600;
  line-height: 25px;
  color: var(--grey-font);
  opacity: 0.6;
}

@mixin grafanaChartWrapper {
  background-color: var(--item-background);
  border: var(--basic-border);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1.25rem;
  margin: 0;
  padding: 0;
}

@mixin remoteConfigHeader{
    font-size: 18px;
    line-height: 25px;
    color: var(--grey-font);
    margin-bottom: 12px;
}

@mixin md {
  @media screen and (max-width: 1440px) {
    @content;
  }
}
