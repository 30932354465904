@import "src/helpers/mixins.scss";

@mixin basicPadding {
  padding: 0 1.875rem;
  box-sizing: border-box;
}

.formContainer {
  @include yScroll;
  @include basicPadding;
  display: flex;
  flex-direction: column;

  grid-template-rows: auto;
  row-gap: 1.5rem;
  padding-bottom: 1rem;
}

.fieldsGroup {
  @include colGap(0.5rem);
}

.msg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2em;
  text-align: center;
  color: var(--grey-font);
}

.link {
  color: var(--accent-color);
  border-bottom: 1px solid var(--accent-color);
  cursor: pointer;
}
