.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 0.75rem;

  .title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey70);
    margin-right: 0.7rem;
  }
}
