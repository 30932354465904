.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 0.5rem;
}

.inputField {
  width: 100%;
}

.formContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
}
