@import "src/helpers/mixins.scss";

$basicPadding: 1.875rem;

.noValue {
  padding-left: $basicPadding;
  color: var(--grey-font);
}

.subTitle {
  color: var(--grey60);
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: $basicPadding;
  box-sizing: border-box;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.additional {
  overflow-y: hidden;
  opacity: 0.8;

  &::before {
    border-top: 1px solid #d8daeb;
    width: 90%;
    margin: 0 auto 1rem;
    content: "";
    display: block;
  }
}

.values {
  @include yScroll;
  max-height: 110px;
}

.noVisible {
  display: none;
}
