.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;

  padding: 12px 24px;
  color: var(--grey-font);
  background: #ffffff;
  justify-content: space-between;
}

.title {
  color: var(--grey60);
}

.right {
  display: flex;
  gap: 18px;
}
