@import "src/helpers/mixins";

.menu {
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  position: relative;
  box-sizing: border-box;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 600;
  color: var(--grey-font);
  border-left: 2px solid transparent;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-color-secondary);

  &:hover {
    cursor: pointer;
    color: var(--accent-color);
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--grey-disabled);
    // pointer-events: none;
  }
}

.item:last-child {
  border-bottom: 1px solid var(--border-color-secondary);
}

.selectedItem {
  background-color: rgba(107, 85, 240, 0.05);
  border-left: 2px solid var(--accent-color);
  color: var(--accent-color);
}

.itemArrow {
  display: none;
  z-index: 2;
}

.expandedItemArrow {
  position: relative;
  display: block;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  opacity: 1;
}

.tooltip {
  @include tooltip;
  width: 160px;
  font-weight: 600;
}

.toggleItem {
  display: block;
  position: absolute;
  right: 0;
  opacity: 1;
}
