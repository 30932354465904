@import "src/helpers/mixins";

.paramsWrapper {
  position: relative;
  cursor: pointer;

  min-height: 88px;
  border-top: var(--basic-border);
  border-bottom: var(--basic-border);

  display: grid;
  width: 100%;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  align-items: flex-start;

  &:hover .deleteBtn {
    display: block;
  }

  .field {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .fieldTitle {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.4em;
    color: var(--grey-font);
    margin-bottom: 0.25rem;
  }
  .fieldValue {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey-font);
  }

  .fieldPlaceholder {
    width: 100%;
    height: 100%;
    color: var(--grey60);
  }
}

.deleteBtn {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
