.dialog {
  position: absolute;
  top: calc(100% + 4rem);
  right: 0;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 10;
  min-width: 700px;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #59536b;
}

.resolverGroup {
  margin-bottom: 10px;
  display: flex;
}

.resolverInputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 50%;
  margin-right: 5px;
}

.inputIPv4 {
  padding: 6px 10px;
  margin: 5px 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #6b55f01a;
}

.inputIPv6 {
  padding: 6px 10px;
  margin: 5px 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #00b6f01f;
}

.inputIPv6::placeholder {
  opacity: 0.5;
}

.inputIPv4::placeholder {
  opacity: 0.5;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancelButton {
  background: white;
  color: var(--accent-color);
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--accent-color);
  width: 150px;
}

.addButton {
  background: #6c5ce7;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
  width: 150px;
  font-size: 14px;
}

.addButton:hover {
  background: #5a4bd4;
}

.blockTitle {
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  opacity: 0.7;
}
