.wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 10rem 1fr;

  @media screen and (max-height: 1000px) {
    grid-template-columns: 5rem 1fr;
  }
}

.title {
  color: var(--accent-color);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
  text-transform: uppercase;
  opacity: 0.6;
}

.group {
  display: flex;
  flex-direction: column;
}

.row {
  height: auto;
  box-sizing: border-box;
  padding: 1.5rem 0;
  border-bottom: var(--basic-border);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }
}
