.wrapper {
  height: 2rem;
  width: auto;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  border: var(--basic-border);
  background-color: var(--item-background);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item {
  display: grid;
  grid-template-columns: 1rem 1fr;
  align-items: center;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  color: var(--grey-font);
  opacity: 0.6;

  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 1;
  }
}

.item.active {
  opacity: 1;
  color: var(--accent-color);
  cursor: auto;
}

.item:not(:last-child) {
  padding-right: 0.5rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    border-left: var(--basic-border);
    box-sizing: border-box;
  }
}

.item:not(:first-child) {
  padding-left: 0.5rem;
}
