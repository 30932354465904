@import "src/helpers/mixins";

.container {
  @include yScroll;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 500px 680px 500px;
  grid-auto-columns: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "stat1 stat1 stat1 stat1 stat2 stat2"
    "stat3 stat3 stat3 stat4 stat4 stat4"
    "stat5 stat5 stat5 stat5 stat5 stat5";

  padding: 0 1.25rem 1.25rem;
  box-sizing: border-box;

  & > * {
    background-color: var(--item-background);
    border: var(--basic-border);
    box-shadow: var(--box-shadow-medium);
    border-radius: 10px;
    overflow: hidden;
  }
}

.stat1 {
  grid-area: stat1;
}

.stat2 {
  grid-area: stat2;
}

.stat3 {
  grid-area: stat3;
}

.stat4 {
  grid-area: stat4;
}

.stat5 {
  grid-area: stat5;
}
