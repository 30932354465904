.confirmDialog {
  width: 360px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  align-items: flex-start;
  text-align: left;
}
