@import "src/helpers/mixins.scss";

.matrix {
  @include xScroll;
  display: grid;
  grid-template-columns: 150px repeat(auto-fill, 1fr);
  grid-auto-flow: column;

  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.matrixRow {
  box-sizing: border-box;
}

.matrixCell {
  padding-right: 30px;
  padding: 12px 26px;
  border-bottom: 1px solid var(--border-color);
  color: var(--grey-font);
  height: 20px;
}

.matrixCell:last-child {
  border-bottom: none;
}
