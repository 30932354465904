.wrapper {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 38px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "icon name" "icon info";
  gap: 0 1rem;
  align-items: center;
}

.icon {
  grid-area: icon;
}

.name {
  grid-area: name;
  color: var(--font);
  font-size: 1.125rem;
  font-weight: 600;
}

.info {
  grid-area: info;
  color: var(--grey60);
  font-size: 0.75rem;
  font-weight: 600;
}
