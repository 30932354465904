@import "../../../helpers/mixins.scss";

$headerHeight: 132px;
$headerHeightSmall: 112px;
$footerHeight: 60px;

@mixin partsAppearance {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.topPart {
  @include yScroll;
  @include partsAppearance;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;
  height: calc(100% - 72px);
  overflow-x: hidden;
}

.title {
  font-weight: 400;
  font-size: 1, 125rem;
  line-height: 1.3em;
  color: var(--grey60);

  margin-bottom: 1.25rem;
}

.subTitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3em;
  color: var(--grey60);
}

.contentPart {
  width: 100%;
  height: 100%;

  .timeRange {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    margin-bottom: 2rem;

    .timeRangeItem {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: var(--grey-font);

      &:hover {
        opacity: 0.6;
      }
    }

    .chosenTimeRange {
      color: var(--orange);
    }
  }
}

.contentBtnPart {
  @include partsAppearance;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;

  .btn {
    width: 108px;
  }
}
