.wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: var(--basic-border);
}

.tab {
  cursor: pointer;

  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.75rem;

  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.5rem;
  align-items: center;

  .title {
    color: var(--grey-font);
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.3em;
  }

  &:not(:last-child) {
    border-right: var(--basic-border);
  }

  &.activeTab {
    border: 1px solid var(--accent-color-lighter-50);
    background-color: var(--accent-color-lighter-5);

    .title {
      color: var(--font);
    }
  }
}
