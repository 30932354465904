.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 240px;
  column-gap: 1rem;

  &.withoutInfo {
    grid-template-columns: 1fr;
  }
}

.placeholderWrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: var(--basic-border);
  background: var(--item-background);
  box-shadow: var(-box-shadow);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--grey-font);
}

.title {
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  margin: 0.5rem 0.75rem;
}

.descr {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  p {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0;

    & > * {
      margin-left: 0.25rem;
    }
  }

  span {
    cursor: pointer;
    color: var(--button-primary);

    &:hover {
      color: var(--button-primary-hover);
    }
  }
}

.infoWrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--item-background);
  border: var(--basic-border);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 1.75rem;

  padding: 1rem 1.5rem;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.75rem;

  color: var(--grey60);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
}

.info {
  color: var(--grey-font);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6em;
}

.error {
  width: 100%;
  position: absolute;
  color: var(--pink) !important;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  text-align: center;
}
