.wrapper {
  z-index: 9999;
  position: fixed;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);

  padding: 0.75rem 1rem;
  box-sizing: border-box;
  background-color: var(--item-background);
  border: var(--basic-border);
  border-top: 3px solid var(--stop-color);
  border-radius: 0 0 6px 6px;
  box-shadow: var(--box-shadow);

  display: grid;
  align-items: flex-start;
  grid-template-columns: 1.125rem 1fr;
  grid-template-areas: auto auto;
  grid-template-areas: "msgIcon msgTitle" "icon msgDetails";
  gap: 0.25rem 0.75rem;

  &.okState {
    border-top: 3px solid var(--green);
  }
  &.pendingState {
    border-top: 3px solid var(--extra-dusty-yellow);
  }
}

.icon {
  grid-area: msgIcon;
  display: flex;
  align-items: center;
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.title {
  grid-area: msgTitle;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2em;
  color: var(--font);
}

.details {
  grid-area: msgDetails;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey-font);
}
