@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 2.5rem;
  gap: 0.25rem 0.25rem;

  &.oneRow {
    @include rowGap(0.25rem);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.item {
  flex: 1;
  cursor: pointer;

  width: 100%;
  height: 100%;
  box-sizing: border-box;

  background: var(--item-background);
  border: var(--basic-border);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: 1px solid var(--accent-color);
  }

  & > svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none !important;
  }
}
