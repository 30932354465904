@import "../../../helpers/mixins.scss";

.prefixesWrapper {
  display: flex;
}

.ipv4 {
  width: 30px;
  height: 24px;
  background: #6b55f01a;
  border-radius: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ipv6 {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  background: #00b6f01a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltipTitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--grey-font);
  margin-bottom: 1rem;
}

.tooltipValue {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1em;
  color: var(--font);
  margin-bottom: 0.75rem;
}

.tooltip {
  @include tooltip;
  opacity: 1 !important;
}

.underPopupElement {
  cursor: pointer;
}
