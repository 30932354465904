.gcpFields {
  grid-template-rows: repeat(5, auto) !important;
}

.regions {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &Labels {
    gap: 6px;
  }
}

.nni {
  display: flex;
  gap: 15px;
  align-items: end;
}
