.wrapper {
  width: 100%;
  height: 100%;
  background: var(--item-background);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  box-sizing: border-box;
  border: var(--basic-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chartsContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}
