@import "../../../helpers/mixins.scss";

.formWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 3.25rem);
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.gatewayServices {
  display: inline-grid;
  grid-template-columns: 150px;
  grid-template-rows: 25px 25px;
}

.gatewayLabel {
  @include fieldLabel;
  width: 100%;
}

.gatewayCheckboxes {
  display: grid;
  grid-template-columns: repeat(3, 90px);
}

.ipv6 {
  background-color: rgba(0, 182, 240, 0.12);
}
