.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 1rem;
}

// .message {
//   font-weight: 600;
//   font-size: 0.75rem;
//   line-height: 1.2em;
//   color: var(--grey70);
//   svg {
//     width: 1rem;
//     height: 1rem;
//   }
// }

.btn {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  justify-content: flex-end;
  svg {
    width: 1rem;
    height: 1rem;
  }
}
