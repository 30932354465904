.form {
  padding: 16px;
  border: 1px solid #D8DAEB;
  background: #6b55f008;
  max-width: calc(100% - 16px);
}

.textarea {
  width: calc(100% - 16px);
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: linear-gradient(
      0deg,
      rgba(107, 85, 240, 0.03),
      rgba(107, 85, 240, 0.03)
    ),
    linear-gradient(0deg, rgba(89, 83, 107, 0.02), rgba(89, 83, 107, 0.02));
  resize: none;
}

.actions {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.button {
  border: none;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
}

.label {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #59536b;
  margin-bottom: 0.5rem;
}

.descriptionView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  max-width: calc(100% - 16px);
  border: 1px solid #D8DAEB;
  background: linear-gradient(
      0deg,
      rgba(107, 85, 240, 0.03),
      rgba(107, 85, 240, 0.03)
    ),
    linear-gradient(0deg, rgba(89, 83, 107, 0.02), rgba(89, 83, 107, 0.02));
}

.descriptionText {
  margin: 0 0 0 0.5rem;
  font-size: 16px;
  color: #59536b;
  text-align: left;
  max-width: 500px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editButton {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.editButton:hover {
  color: #0056b3;
}
