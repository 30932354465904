@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  div[class*="DialogTemplateOneColumn_wrapper"] {
    position: unset;
    width: 100%;
    height: 100%;
  }

  div[class*="DialogTemplateOneColumn_overlay"] {
    height: 100%;
  }
}

.caretIcon {
  padding-top: 2px;
  margin: auto 0;
  height: max-content;
  width: 20px;
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button-primary);

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.typeName {
  margin-left: 10px;
  font-weight: 600;
}

.groupContent {
  padding: 10px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.groupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--grey-font);
  padding: 10px;
  border: 1px solid #dddddd;
  position: relative;
}

.groupTitle {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.groupInfo {
  display: flex;
  gap: 15px;
  font-size: 0.8rem;
  color: #6b55f0;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #007bff;
  margin-left: auto;
}
