@import "src/helpers/vars.scss";

.container {
  height: $navBarHeight;
  display: flex;
  align-items: center;
  padding: 0 20%;
  justify-content: space-evenly;
  width: 100vw;
  background: var(--item-background);
  box-sizing: border-box;

  font-size: 0.875rem;
  line-height: 1em;

  position: relative;
}

$numberSize: 24px;

.tabButton {
  display: flex;
  align-items: center;
  color: var(--font);
  text-decoration: none;
  height: 42px;
  padding: 7px 9px;
  box-sizing: border-box;

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $numberSize;
    height: $numberSize;
    margin-right: 0.5rem;
    box-sizing: border-box;
    border: 1px solid var(--grey-font);
    border-radius: 50%;

    font-weight: 700;
    padding-top: 1px;
  }
}

.tabButton.active {
  color: var(--accent-color);

  .number {
    color: var(--item-background);
    background: var(--accent-color);
    border: 1px solid var(--accent-color);
  }
}

.separator {
  width: 100%;
  height: 1px;
  border-top: 1px dashed var(--grey70);
  box-sizing: border-box;
  background-color: transparent;
}

.btn {
  width: 128px;
  border-radius: 28px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
