.tableHeader {
  background: var(--table-background-accent);
  height: 47px;
  align-items: center;
  display: grid;
  border-bottom: var(--basic-border);

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
}

.th {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 20px;
}

.sortingWrapper {
  height: 8px;
  width: 8px;
  margin-top: 2px;
  margin-left: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
