.groupWrapper {
  display: grid;
  grid-template-columns: 1fr 28px;
  column-gap: 0.75rem;
  align-items: center;

  padding: 12px 20px;
  background: rgba(239, 233, 251, 0.7);

  border-left: 2px solid transparent;

  transition: all 0.15s ease;

  &:hover {
    border-color: #6b55f0;
    cursor: pointer;
  }
}

.groupTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 12px;
}
