@import "../../../helpers/mixins.scss";

.wrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// .tableWrapper {
//   display: flex;
//   border-radius: 4px;
//   overflow: hidden;
//   height: 100%;
//   width: 100%;
//   color: var(--font);
//   box-sizing: border-box;
//   border: 1px solid var(--border-color);
//   background-color: var(--background-color);
// }
