.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modalTitle {
  margin-bottom: 16px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  color: #59536b;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
