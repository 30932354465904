@import "../../../../helpers/mixins.scss";

.summaryLayout {
  grid-template-rows: 70px 64px 1fr 32px;
  box-sizing: border-box;
}

.summarySystems {
  padding-right: 1.5rem;
  margin-top: 8px;
  width: 12rem;
}

.haLayout {
  grid-template-rows: 38px 5px 32px 32px 32px 32px 10px 32px 12px 32px;
}

.subtitle {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey-font);
  margin-top: -0.25rem;
}

.location {
  color: var(--grey60);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 55px;
  margin-top: -1.5rem;
  row-gap: 0;
  border-bottom: 1px solid var(--border);
}

.healthyColor {
  color: var(--green);
  font-weight: 600;
}

.unHealthyColor {
  color: var(--orange);
  font-weight: 600;
}
