$btnWidth: 7rem;
$btmMargin: 1.5rem;

.btmContols {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.leftPart {
  width: calc(100% - #{$btnWidth} * 2 - #{$btmMargin} * 2);
  margin-right: $btmMargin;
  @media screen and (max-height: 1000px) {
    width: 90%;
  }
}

.dialogBtns {
  width: 7rem;
  margin-right: $btmMargin;
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }

  &.primary {
    margin-right: 0;
  }

  @media screen and (max-height: 1000px) {
    width: 5rem;
    margin-right: 5px;
  }
}

.loader {
  width: 1rem;
  height: 1rem;
}
