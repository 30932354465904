.wrapper {
  margin-top: 38px;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0 4px 27px 0 #9ea6ae1a;
  padding: 36px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 8px;
  position: relative;
}

.selected {
  border: 2px solid var(--accent-color);
}

.row {
  display: flex;
  gap: 12px;

  .label {
    width: 120px;
    color: var(--grey-font);
  }
}

.cloudIcon {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.cloudProviderIcon {
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.usageIcon {
  position: absolute;
  right: 15px;
  top: 15px;

  --used: 0;
  --free: calc(2 * 3.14 * 25);
  --stroke-final: #00ba77;
  > g > circle {
    stroke-dasharray: calc(2 * 3.14 * 25);
    animation: dash 1s linear forwards;
    animation-delay: 0.2s;
    stroke: #d8daeb;
  }

  @keyframes dash {
    to {
      stroke-dasharray: var(--used) var(--free);
      stroke: var(--stroke-final);
    }
  }
}
