.wrapper {
  width: 14rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  row-gap: 0.5rem;
}

.header {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3em;
  color: var(--grey-font);
  margin-bottom: 0.5rem;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  column-gap: 0.5rem;

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.3em;
}

.title {
  color: var(--grey60);
}

.value {
  display: flex;
  align-items: center;
  color: var(--grey-font);
  svg {
    margin-right: 0.24rem;
  }
}

// .btn {
//   display: flex;
//   justify-content: flex-start;
//   color: var(--button-primary);
//   font-weight: 600;
//   font-size: 0.875rem;
//   line-height: 1.3em;
//   margin-top: 0.5rem;
//   &:hover {
//     color: var(--button-primary-hover);
//   }
// }
