.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--background-color) !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.content {
  max-width: 500px;
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.form {
  // flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  padding-bottom: 47px;
  font-size: 26px;
}

.password {
  padding-top: 26px;
}

// .tenantName {
//   padding-top: 26px;
// }

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.loginButton {
  background: var(--accent-color) !important;
  width: 45%;
  height: 40px;
  color: var(--button-font) !important;
  border-radius: var(--border-radius);
  margin-top: 34px;

  &.zayoColors {
    background: var(--zayo-dark-blue) !important;
  }
}

.cancellButton {
  border: 1px solid var(--accent-color)!important;
  background: transparent !important;
  width: 45%;
  height: 40px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25em;
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--accent-color) !important;
  margin-top: 34px;

  &:hover {
    color: var(--button-primary-hover);
  }

  &.zayoColors {
    background: var(--zayo-dark-blue) !important;
    color: var(--button-font) !important;
    border: none !important;
  }
}

.errorText {
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  color: var(--critical);

  &.visibility {
    visibility: hidden;
  }
}

.inputInternalClass {
  border: 1px solid var(--critical);
}

.rightPart {
  background: linear-gradient(
      143.06deg,
      rgba(151, 69, 255, 0.2) 4.31%,
      rgba(151, 69, 255, 0) 101.18%
    ),
    #ffd2b1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 10% 30px;
  box-sizing: border-box;

  > * {
    z-index: 1;
  }

  &.zayoColors {
    background: none;
    background-color: var(--zayo-light-blue);

    .rightContent {
      max-height: none;
    }

    .welcome,
    .by {
      color: var(--zayo-font) !important;
    }
  }
}

.background {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: auto;
  z-index: 0;
}

.welcome {
  color: #ffffff;
  font-weight: 600;
  line-height: 49px;
  font-size: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.by {
  font-weight: 600;
  line-height: 33px;
  font-size: 24px;
  margin-top: 18px;
}

.iconsRow {
  width: 100%;
  height: auto;
}

.rightContent {
  height: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.zayoLogo {
  width: 159px;
}
