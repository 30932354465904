.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.card {
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 0;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
