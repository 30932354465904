.wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  padding: 40px;
  box-sizing: border-box;
}
