.radioWrapper {
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  flex-wrap: nowrap;

  .radioContainer {
    gap: 2rem;
    font-size: 0.75rem;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.checkbox {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 0.75rem;
}

.checkboxWrapper {
  height: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.label {
  @extend .checkbox;
  color: #59536b;
}

.labelRed {
  @extend.label;

  background-color: #ff406e26;
}

.labelGreen {
  @extend.label;

  background-color: #00ba7726;
}

.listWrapper {
  margin-top: 2rem;
  width: 100%;
  padding: 0.75rem;

  button {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0.5rem;
    padding-left: 0;
  }
}

.hiddenContent {
  display: none;
}
