.pie {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 16px;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.pie > div:first-child {
  width: 100%;
}

.pie h5 {
  margin: 0 0 8px;
}

.pie ul {
  margin: 0 0 16px;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  list-style: none;
}

.pie ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.pie ul li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #bbbbbb;
}

.pie ul:nth-of-type(1) li:nth-child(1)::before {
  background: var(--pie-chart-inner-color-1);
}

.pie ul:nth-of-type(1) li:nth-child(2)::before {
  background: var(--pie-chart-inner-color-2);
}

.pie ul:nth-of-type(1) li:nth-child(3)::before {
  background: var(--pie-chart-inner-color-3);
}

.pie ul:nth-of-type(1) li:nth-child(4)::before {
  background: var(--pie-chart-inner-color-4);
}

.pie ul:nth-of-type(2) li:nth-child(1)::before {
  background: var(--pie-chart-outer-color-1);
}

.pie ul:nth-of-type(2) li:nth-child(2)::before {
  background: var(--pie-chart-outer-color-2);
}

.pie ul:nth-of-type(2) li:nth-child(3)::before {
  background: var(--pie-chart-outer-color-3);
}
