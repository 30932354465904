.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.75rem 1rem;

  &.rightAlign {
    justify-content: flex-end;
  }
}

.toggleBtn {
  color: var(--button-primary);
  cursor: pointer;

  &:hover {
    color: var(--button-primary-hover);
  }
}

.toggleIcon {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);

  &.reverse {
    transform: rotate(0deg);
  }
}
