.toggle {
  display: flex;
  align-items: center;
  height: 16px;
  cursor: pointer;

  color: var(--font);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;

  &.largeSize {
    font-size: 1rem;
  }

  .toggleCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .toggleLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 27px;
    height: 11px;
    background: var(--toggle-inactive-track);
    border-radius: 50px;
    position: relative;
    transition: background-color var(--transition-time);
    margin-right: 0.5rem;
  }

  .toggleButton {
    display: flex;
  }

  .toggleLabel .toggleButton {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    transition: all 0.6s ease-in-out;
    background: var(--toggle-inactive-knob);
  }

  &.toggleActive {
    .toggleLabel {
      background: var(--toggle-active-knob);
    }

    .toggleLabel .toggleButton {
      background: var(--button-primary);
    }
  }

  &.notEditable {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.6;
  }

  &.toggleDisabled {
    color: var(--grey-disabled);

    .toggleLabel {
      background: var(--border-color-8);
    }

    .toggleLabel .toggleButton {
      background: var(--border-color);
    }
  }

  .toggleCheckbox:checked + .toggleLabel .toggleButton {
    left: calc(50%);
  }

  .toggleText {
    margin-bottom: 3px;
  }
}
