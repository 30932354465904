@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden
}

.container {
  @include yScroll;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 640px 320px;
  gap: 16px 16px;
  grid-auto-flow: row;
  grid-template-areas:
    "buttons buttons buttons"
    "appCharts appCharts appCharts"
    "topAppTable topAppTable topAppDonut ";
}

@mixin chartWrapper {
  background-color: var(--item-background);
  border: var(--basic-border);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1.25rem;
  margin: 0;
  // display: flex;
  // align-items: flex-start;
  // justify-content: space-between;
  // position: relative;
  // height: calc(100% - 50px);
  padding: 0;
}

.buttons {
  grid-area: buttons;
}

.topAppTable {
  @include chartWrapper;
  grid-area: topAppTable;
}

.appCharts {
  @include chartWrapper;
  grid-area: appCharts;
}

.topAppDonut {
  @include chartWrapper;
  grid-area: topAppDonut;
}
