.wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 0.5rem;

  &.center {
    align-items: center;
  }

  &.withSeparator {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .subtitle {
      font-weight: 600;
      color: var(--font);
    }

    .text {
      font-size: 0.75rem;
      color: var(--grey50);
    }
  }
}

.subtitle {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey-font);
}

.separator {
  @extend .subtitle;

  &.colon {
    margin-right: 0.5rem;
  }

  &.slash {
    margin: 0 0.5rem;
  }
}

.text {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--font);

  &.large {
    font-size: 1.5rem;
  }

  &.medium {
    font-size: 0.875rem;
  }

  &.small {
    font-size: 0.75rem;
  }
}
