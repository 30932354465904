.wrapper {
  min-width: 320px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: var(--basic-border);
  border-radius: 2px 0 0 2px;
  position: relative;
}

.row {
  width: calc(100% - 30px);
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.row.withPortFlag {
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-template-areas: "rowTitle portFlag" "rowContent portFlag";

  .rowTitle {
    grid-area: rowTitle;
  }
  .rowContent {
    grid-area: rowContent;
  }
  .portFlag {
    grid-area: portFlag;
    border-bottom: var(--basic-border);
  }
}

.rowControls {
  width: calc(100% - 30px) !important;
}

.portFlag {
  position: absolute;
  top: 0;
  right: 0;
}
