.wrapper {
  display: grid;
  grid-template-columns: 1rem 1fr;
  column-gap: 0.25rem;
  align-items: center;

  color: var(--grey-font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 16px;
}
