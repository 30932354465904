.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.listWrapper {
  border-radius: 0;
  width: 100%;
  padding: 0.5rem 0;

  button {
    color: var(--grey-font);
    font-style: normal;
  }
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: max-content;
  margin: 0 auto;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  gap: 1rem;
}

.opacity {
  opacity: 0.5;
}

.trackedInfo {
  max-width: 250px;
}
