.dialogLayout {
    position: relative;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: rgba(155, 149, 173, 0.6);
    backdrop-filter: blur(4px);
}