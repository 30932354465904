@import "src/helpers/mixins";

.wrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
}

.basicSection {
  height: auto;
  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}
