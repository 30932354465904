@import "src/helpers/mixins";

.layout {
  @include yScroll;
  padding: 30px;
  box-sizing: border-box;
  background-color: var(--background-color);
  border-radius: 10px;
  border: 1px solid var(--border-color-secondary);
  height: inherit;
  width: 100%;
  overflow: auto;
}
