.table {
  max-height: 40vh;
}

.noCloudSites {
  background: var(--white);
  padding: 12px;
  display: flex;
  justify-content: center;
}
