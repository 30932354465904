.filterItem {
  display: flex;
  align-items: center;
  background: rgba(107, 85, 240, 0.8);
  padding: 7px 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #ced4da;
  color: white;
  height: 0.7rem;
  align-self: center;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.removeFilterIcon {
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  padding-left: 0.25rem;
  padding-bottom: 1px;
  cursor: pointer;
}

.filterListWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
