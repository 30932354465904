.wrapper {
  width: 100%;
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: 1fr 10rem 10rem;
  align-items: center;
}

.controls {
  width: 100%;
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}
