@import "src/helpers/mixins.scss";

.contentWrapper {
  @include yScroll();
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  border-left: var(--basic-border);
  box-sizing: border-box;
}

.eventsTable {
  border-radius: 0 !important;
  border: none !important;
  border-top: var(--basic-border);
}
