.dialogFrame {
    padding: 20px 24px;
    position: relative;

    background: var(--background-color);
    border: 1px solid #E2E2E8;
    box-shadow: 0px 4px 27px rgba(158, 166, 174, 0.1);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    max-height: 100%;
    overflow-y: auto;
}