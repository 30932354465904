.wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--item-background);
  border: var(--basic-border);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 1.75rem;

  padding: 1rem 1.5rem;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.75rem;

  color: var(--grey60);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
}

.info {
  color: var(--grey-font);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6em;
}
