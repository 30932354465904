.formContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.625rem;
}

.addBtn {
  height: 100% !important;
}
