@import "src/helpers/mixins";

.wrapper {
  @include yScroll;

  width: 100%;
  height: 100%;

  background: var(--background-color);
  border: var(--basic-border);
  border-radius: 10px;
  box-shadow: var(--box-shadow-secondary);

  padding: 1rem 1rem;
  box-sizing: border-box;

  @include colGap(1rem);
  display: flex;
  flex-direction: column;
}
