.rule {
  color: var(--accent-color);
  cursor: pointer;
  margin-right: 28px;
}

.blockedRule {
  color: var(--grey-font);
  cursor: not-allowed;
}
