.wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 2.5rem;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2em;
}

.itemWrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 2.5rem;
}

.value {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 0.25rem;
  align-items: flex-end;

  .valueNumber {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.2em;
    color: var(--font);
  }

  .msg {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--grey60);
    margin-left: 0.25rem;
  }
}

.stat {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 0.25rem;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  align-items: flex-end;

  .statNumber {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2em;
  }

  &.up {
    border: 1px solid var(--light-green);
    .statNumber {
      color: var(--green);
    }
  }
  &.down {
    border: 1px solid var(--light-pink);
    .statNumber {
      color: var(--pink);
    }
  }
}

.statMessage {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2;
  color: var(--grey60);
  margin-left: 0.25rem;
}

.icon {
  display: flex;
  height: 100%;
  align-items: center;
}
