.subnets {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.subnet {
  color: var(--grey-font);
  background: rgba(107, 85, 240, 0.1);
  box-sizing: border-box;
  padding: 4px;
  border-radius: 2px;
  gap: 4px;
  display: flex;
  align-items: center;
  padding-right: 8px;

  .subnetClose {
    position: relative;
    cursor: pointer;
  }
}
