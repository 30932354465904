@import "src/helpers/mixins.scss";

.contentWrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;
}
