@import "src/helpers/mixins";

.wrapper {
  @include grafanaChartWrapper;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
