@import "../../helpers/mixins";
@import "../../helpers/vars.scss";

.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--main-background);
}

.content {
  @include yScroll();
  height: calc(
    100vh - #{$layoutHeaderHeight} - #{$navBarHeight} - #{$navHeaderHeight}
  );
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 40px 40px;
  box-sizing: border-box;
  overflow-x: hidden;

  &.withoutNavigation {
    height: calc(100vh - #{$layoutHeaderHeight} - #{$navBarHeight});
  }
}

.content.withoutPaddings {
  padding: unset;
}
