@import "../../../helpers/mixins";

input[type="checkbox"] {
  @include visuallyHidden;
}

.container {
  @include checkBoxRadioContainer;
}

.checkbox {
  @include checkboxRadioBasics;
  border-radius: 2px;
  margin-right: 0.875rem;

  &.checkboxActive {
    background-color: var(--button-primary);
  }

  &.checkboxDisabled {
    @include checkboxRadioDisabled;
    border: 1px solid var(--border-color);
    background-color: var(--item-background);
  }

  &.checkboxActive.checkboxDisabled {
    @include checkboxRadioDisabled;
    border: none;
    background-color: var(--disabled-checked);
  }

  &.singleCheckbox {
    margin: 0;
  }
}

.tooltip {
  @include tooltip;
  padding: 0.3rem 0.8rem !important;
  pointer-events: all !important;
  display: flex;
  flex-direction: column;
  background-color: var(--item-background) !important;
  border-radius: 0.125rem !important;

  .title {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: normal;
    color: var(--font);
  }
}
