.listWrapper {
  margin-top: 2rem;
  width: 100%;
  padding: 0.75rem;

  button {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0.5rem;
    padding-left: 0;
  }
}
