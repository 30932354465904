.wrapper{
    max-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

}

.layout{
    position: relative;
    box-sizing: border-box;
    padding: 26px 40px;
    display: flex;
    gap: 20px;
    height: calc(100vh - 75px);

}