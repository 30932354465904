.controlWrapper {
  padding: 1.25rem 0;
  border-bottom: var(--basic-border);
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.controlTitle {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey60);
  margin-bottom: 1.125rem;
}
