@import "src/helpers/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartsWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 480px 480px repeat(2, 240px);
  gap: 1rem 1rem;
  grid-template-areas:
    "map map map"
    "sankey sankey sankey"
    "countryPie countryBar countryBar"
    "cityPie cityBar cityBar";
}
.map {
  @include grafanaChartWrapper;
  grid-area: map;
}

.sankey {
  @include grafanaChartWrapper;
  grid-area: sankey;
}

.countryPie {
  @include grafanaChartWrapper;
  grid-area: countryPie;
}

.countryBar {
  @include grafanaChartWrapper;
  grid-area: countryBar;
}

.cityPie {
  @include grafanaChartWrapper;
  grid-area: cityPie;
}

.cityBar {
  @include grafanaChartWrapper;
  grid-area: cityBar;
}
