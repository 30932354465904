@import "src/helpers/mixins";

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 1.25rem;
  border: var(--basic-border);
  border-radius: 4px;
  padding: 1rem 4.5rem 1rem 1rem;
  box-sizing: border-box;
  position: relative;

  &.droppable {
    border-color: var(--border-color-secondary);
    border-width: 1px;
    border-style: dashed;
    background-color: var(--item-background-secondary);
    opacity: 0.7;
  }

  &.dragging {
    border-color: var(--accent-color-lighter-50);
    background-color: var(--item-background-secondary);
  }
}

.customLabel {
  font-size: 0.75rem;
  color: var(--font);
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  align-items: flex-start;

  .checkbox {
    height: 100%;
  }
}
.deleteBtn {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-radius: 4px;
  border: var(--basic-border);
  box-sizing: border-box;
}

.dragDropBtn {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: auto;
  height: 28px;
  position: absolute;
  bottom: 1rem;
  right: 0.625rem;
  cursor: grab;
  opacity: 0.7;

  font-size: 0.75rem;
  font-weight: 600;
  color: var(--accent-color);

  span {
    margin-right: 0.5rem;
  }

  &:active,
  &:hover {
    opacity: 1;
  }
}
