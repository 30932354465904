.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 100%;

  padding: 0.75rem 1.125rem;
  box-sizing: border-box;

  border: var(--basic-border);
  border-left: none;
  border-right: none;
  background-color: var(--table-background-secondary);

  color: var(--grey-font);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
  position: relative;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

.statContainer {
  width: 100%;
  height: 100%;
  padding: 0.75rem 1.125rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs > *:not(:last-child) {
  margin-right: 0.5rem !important;
}
