@import "../../../helpers/mixins";

.dropDownWrapper {
  display: block;
  position: relative;
  width: 100%;
}

$leftIconSize: 22px;
$arrowIconSize: 10px;

.dropDownContainer {
  @include inputField;
  width: 100%;
  justify-content: space-between;

  &.btnStyles {
    @include btnStyle;

    svg:first-child {
      margin-right: 0.25rem;
      width: $leftIconSize;
      height: $leftIconSize;
    }

    .text {
      width: calc(100% - #{$leftIconSize} - 4px - 8px - #{$arrowIconSize});
    }
  }

  &.smaller {
    height: 20px;
    padding: 0 0.5rem;
  }

  &.medium {
    padding: 0.375rem;
    height: 28px;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;

    * {
      cursor: not-allowed !important;
      color: var(--grey-font) !important;
    }
  }
}

$iconSize: 0.675rem;
$iconMargin: 0.5rem;

.text {
  width: calc(100% - #{$iconSize} - #{$iconMargin});
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.dropDownIcon {
  width: $arrowIconSize;
  height: $arrowIconSize;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.rotateIcon {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }
}

.dropDownList {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  display: block;
  padding: 1.25rem 0;
  box-sizing: border-box;
  margin-top: 1.5rem;
  position: absolute;
  top: 22px;
  min-width: 131px;
  left: 0;
  z-index: 20;

  max-height: 200px;
  overflow: scroll;

  &.smaller {
    top: 22px;
    margin: 0;
  }

  &.medium {
    top: 34px;
    margin: 0;
  }

  &.notFormElement {
    top: 40px;
  }

  &.hiddenList {
    display: none;
  }

  .listItem {
    width: 100%;
    height: 1.375rem;
    box-sizing: border-box;
    justify-content: flex-start;
    color: var(--font);
    font-size: 0.875rem;
    line-height: 1.2em;
    padding-left: 18px;
    gap: 1.25rem;
    user-select: none;

    &:hover {
      border-color: var(--tab-active);
      color: var(--tab-active);
    }
  }
}

.dropDownLabel {
  @include fieldLabel;
  position: relative;
}

.error {
  position: absolute;
  display: flex;
  margin-top: 0.25rem;

  .errorText {
    margin-left: 0.5rem;
    width: 100%;
    color: var(--orange);
    font-size: 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
