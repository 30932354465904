@import "../../../helpers/mixins";

.btn {
  background-color: transparent !important;
  color: var(--button-primary);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25em;
  cursor: pointer;

  &:hover {
    color: var(--button-primary-hover);
  }
}

.btn.outlined {
  width: 28px;
  height: 28px;

  border: 1px solid var(--button-primary);
  border-radius: 10px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--button-primary-hover);
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.btn.fillBtn {
  @include btnStyle;
  padding: 7px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
  }
}

.btn.opacity {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.label {
  width: 100%;
  margin-left: 0.75rem;

  &.disabled {
    color: var(--grey-disabled) !important;
  }
}

.fireWallBtnLabel {
  margin-left: 0;
  color: var(--accent-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3em;
  opacity: 1 !important;
  padding: 2px 0;
}

.fireWallBtn {
  margin-left: 60px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  height: max-content;
  width: 77px;
  display: flex;
  align-items: center;
  padding: 0 4px;
}
