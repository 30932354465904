.filterButton {
  border: 1px solid var(--border-color);
  padding: 8px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  svg {
    position: relative;
    left: 1px;
  }
}
