.layout {
  padding: 16px 16px 54px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 80px 1fr;
  border: 1px solid #e2e2e8;
  border-radius: 4px;
  width: 100%;
  position: relative;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey-font);
  opacity: 0.6;
  position: relative;
  bottom: 12px;
}
