.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 290px;
  margin-left: 15px;
}

.msg {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4em;
  color: var(--grey-font);
  text-align: center;
  width: 260px;
  margin-top: 2rem;
}

