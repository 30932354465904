.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--item-background);
  border-radius: 4px 4px 0 0;
  border: var(--basic-border);
  border-bottom: none;
  padding: 0 1.125rem;
  box-sizing: border-box;

  &.fullBorder {
    border-bottom: var(--basic-border);
    border-radius: 4px;
  }
}

.title {
  color: var(--grey60);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: normal;
}

.systemTimerangeWrapper {
  height: calc(100vh - 312px) !important;
  top: 100px !important;
  right: 0 !important;
}

.timerangeWrapper {
  height: calc(100vh - 270px) !important;
  top: 172px !important;
  right: 40px !important;
}

.tableControls {
  display: flex;
}

.showAllBtn {
  color: var(--button-primary);
  margin-right: 0.75rem;
  &:hover {
    color: var(--button-primary-hover);
  }
}

.collapseBtn {
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}

.amountLabel {
  min-width: 30px;
  height: 18px;
  margin-right: 0.5rem;
  border-radius: 15px;
  background-color: var(--accent-color);
  color: var(--item-background);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3em;
}
