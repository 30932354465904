@import "../../../../helpers/mixins.scss";

.fields {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 300px 300px;
  row-gap: 16px;
  column-gap: 26px;
}

.vlanPool {
  display: flex;
  align-items: center;
  gap: 5px;
}

.vlanPoolLabel {
  @include fieldLabel;
}

.dropdown {
  width: 400px;
}
