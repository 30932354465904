.mainText {
  color: var(--grey-font);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.titleText {
  color: var(--grey-font);
  margin: 12px 0;
  font-weight: 700;
}

li {
  list-style-type: disc !important;
  margin-left: 14px;
  margin-top: -3px;
}
