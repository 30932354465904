.wrapper {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 1rem;
}

.title {
  opacity: 0.6;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  svg {
    margin-right: 0.8rem;
  }
}
