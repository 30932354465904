@import "src/helpers/mixins";

.rightPart {
  @include yScroll;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: var(--basic-border);
  border-radius: 6px;
  background: var(--item-background);
  box-shadow: var(--box-shadow);
  height: 100%;
}

// .topPart {
//   padding: 1.5rem;
//   height: 33%;
//   display: flex;
//   flex-direction: column;
// }

// .bottomPart {
//   padding: 1.5rem;
//   border-top: var(--basic-border);
//   height: 67%;
// }

.onePart {
  height: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.demoTopPart {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.demoBottomPart {
  padding: 1.5rem;
  border-top: var(--basic-border);
  display: grid;
  grid-template-columns: 50% 50%;

  .left {
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
  }
}

.traffic {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
