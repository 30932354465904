.connectionName {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  .remote {
    font-style: italic;
  }
}

.ips {
  background-color: rgba(0, 182, 240, 0.12);
}
