.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2.5rem repeat(2, 1fr);
  grid-template-areas:
    "tabs tabs rightChart"
    "leftChart midChart rightChart"
    "leftChart midChart rightChart";
}

.tabs {
  grid-area: tabs;
  border-bottom: 1px solid var(--border-color);
  align-self: flex-end;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.leftChart,
.midChart,
.rightChart {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.leftChart {
  grid-area: leftChart;
  border-right: 1px solid var(--border-color);
}

.midChart {
  grid-area: midChart;
}

.rightChart {
  grid-area: rightChart;
  border-left: 1px solid var(--border-color);
}

.chartsWrapper {
  height: 30vh;
}
