@import "src/helpers/mixins.scss";

.errorContainer {
  display: flex;
  align-items: center;
}

.errorText {
  @include fiedlErrorText;
  position: relative;
  margin-left: 0.75rem;
  bottom: 0;
  left: 0;
}
