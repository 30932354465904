.complexHeader {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
  min-width: 240px;

  .label {
    color: var(--grey-font);
  }

  .oneLine {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
}
