.form {
  display: flex;
  gap: 36px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-height: 1000px) {
    gap: 12px;
  }
}

.row {
  width: 100%;
  height: max-content;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 40% 0;
  height: max-content;

  @media screen and (max-height: 1000px) {
    width: 45%;
    flex: unset;
  }
}

.dialog {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  div[class*="content"] {
    height: 100%;
  }
}

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: space-between;

  > div {
    padding: 11px 22px;
    position: relative;
    overflow: unset;
  }
}

.dropdown {
  width: 100%;

  > div > div {
    overflow-x: hidden;
  }

  &.relative {
    top: unset;
    bottom: 10px;
  }
}

.icon {
  width: 14px;
  height: 14px;
  padding: 0 5px 0 0;
  margin-left: 5px;
}
