.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border: var(--basic-border);
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  box-sizing: border-box;
  background-color: var(--accent-color-lighter-5);
}

.viewTabs {
  position: absolute;
  z-index: 10;
  top: 0.875rem;
  right: 0.625rem;
}

.tooltip {
  background-color: var(--item-background) !important;
  border: var(--basic-border) !important;
  opacity: 1 !important;
  box-shadow: var(--box-shadow);
}
