@import "src/helpers/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  transition: all 0.3s ease-in-out;

  &.twoCols {
    transition: all 0.3s ease-in-out;
    grid-template-columns: 1fr 15rem;
  }
}

.tableWrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: var(--item-background);
  border: var(--basic-border);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.header {
  width: 100%;
  height: auto;
  padding: 0.75rem 1.125rem;
  box-sizing: border-box;
  color: var(--grey-font);
  font-size: 1.125rem;
  font-weight: 600;
  border-bottom: var(--basic-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listWrapper {
  @include yScroll;
  width: 100%;
  height: 100%;
  padding: 0.75rem 1.125rem;
  box-sizing: border-box;
}

.group {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 0;
  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}
