.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.title {
  font-size: 24px;
  padding: 10px;
}

.actions {
  display: flex;
  gap: 30px;
}
