@import "src/helpers/mixins.scss";

@mixin basicPaddings {
  padding: 1.25rem 1.5rem 2rem;
  box-sizing: border-box;
}

.contentWrapperNoBorder {
  @include basicPaddings;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.inputWrapper {
  width: 8rem;
}

.inputWrapperCenter {
  @extend .inputWrapper;
  align-self: center;
}

.portWrapper {
  width: 12rem;
}
