.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
}

.tab {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--grey-disabled);

  &:not(:last-child) {
    margin-right: 40px;
  }

  &.active {
    border-bottom: 2px solid var(--tab-active);
    color: var(--font);
  }
}

.btnMode {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);
  padding: 0.25rem 0.5rem;

  &.btnModeActive {
    background: var(--label-background);
    border-radius: 4px;
    color: var(--font);
    padding: 0.25rem 0.5rem;
    border: none;
  }
}
