@import "../../helpers/mixins";

.container {
  width: 15rem;
  height: 100%;
  display: grid;
  grid-template-rows: 2.5rem 1fr 2rem;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  > * {
    width: 100%;
    justify-content: flex-start;
  }

  &.withoutFooter {
    grid-template-rows: 2.5rem 1fr;
  }
}

.bodyContainer {
  @include yScroll();
  height: 100%;
  overflow-x: hidden;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
