.wrapper {
  position: relative;
  cursor: pointer;
}

.icon {
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.notification {
  position: absolute;
  top: -8px;
  right: -10px;
  height: 18px;
  width: auto;
  border: 1px solid white;
  background-color: #d76384;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  box-sizing: border-box;
  padding: 0 5px;
  border-radius: 10px;
}
