@import "src/helpers/mixins";

.block {
  display: grid;
  grid-template-columns: 32px calc(100% - 32px);
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
}

.inner {
  margin: 12px 8px;
  height: 97%;
  border: 1px solid var(--border, #D8DAEB);
  background-color: white;
}

.wrapper {
  @include yScroll;
  background: var(--item-background);
  border: var(--basic-border);
}
