@import "src/helpers/mixins";

.form {
  --form-header-height: 28px;
  background: #fff;
  box-shadow: 4px 4px 10px 0 rgba(120, 118, 148, 0.1);

  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #d8daeb;
  height: max-content;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 210px;
  margin-top: 4px;

  &.hidden {
    display: none;
  }
}

.portData {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.connections {
  border: 1px solid #d8daeb;
  width: 40%;
  height: 100%;
  align-self: flex-end;

  @include yScroll;

  div.connection:last-child {
    border-bottom: 1px solid #d8daeb;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @include md {
    flex-wrap: wrap;
    gap: 8px; 
  }

  .titleWithEdit {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .title {
    border-radius: 6px;
    background: #f3f1fe;
    display: inline-flex;
    height: var(--form-header-height);
    width: max-content;
    align-items: center;
    gap: 7px;
    padding: 2px 6px 2px 0;

    @media screen and (max-height: 1000px) {
      height: max-content;
      padding: 2px 5px;
    }

    &.pending {
      background: var(--disabled-basic);
    }
  }
}

.iconsData {
  display: grid;
  grid-template-rows: repeat(2, 50%);
  grid-template-columns: repeat(2, 50%);
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .unitWrapper {
    display: flex;
    align-items: center;
    gap: 13px;
    justify-content: center;

    .unit {
      display: flex;
      flex-direction: column;
      align-items: center;

      .unitTitle {
        color: var(--text);
        opacity: 0.6;
        font-size: 12px;
        font-weight: 600;
      }

      .unitInfo {
        display: flex;
        align-items: center;
      }
    }
  }
}

.bw {
  --used: 0;
  --free: calc(2 * 3.14 * 25);
  --stroke-final: #00ba77;
  > g > circle {
    stroke-dasharray: calc(2 * 3.14 * 25);
    animation: dash 1s linear forwards;
    animation-delay: 0.2s;
    stroke: #d8daeb;
  }

  &.pending {
    --stroke-final: #d8daeb;
  }

  @keyframes dash {
    to {
      stroke-dasharray: var(--used) var(--free);
      stroke: var(--stroke-final);
    }
  }
}

.icon {
  padding-top: 2px;
  margin: auto 0;
  height: max-content;
  width: 20px;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &.opened {
    svg {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.deleteIcon {
  align-self: baseline;
}

.addConnection {
  color: #ff406e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  opacity: 0.8;
  cursor: pointer;
  padding: 13px 11px;
  border: none;

  &__hidden {
    opacity: 0.6;
    padding-top: 2px;
  }

  &.pending {
    color: var(--grey-disabled);
    opacity: 0.8;
  }
}

.connectionsInfoWrapper {
  display: flex;
  align-items: center;
}

.connection {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #d8daeb;
  padding: 8px;

  .connectionTitle {
    color: var(--text, #59536b);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .connectionData {
    display: flex;
    gap: 10px;
    font-size: 12px;

    .connectionLabel {
      opacity: 0.6;
      margin-right: 5px;
    }
  }
}

.connectionLabelWrapper {
  margin-top: 2px;
}

.cloudLinkStyle {
  color: var(--out-color);
  text-decoration: underline;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: var(--disabled-checked);
  }
}

.displayBlock {
  display: block !important;
}

.azureConnectionType {
  color: var(--59536-b, #59536b);
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  padding: 1px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(107, 85, 240, 0.1);
}

.connectionTitleWrapper {
  width: 100%;
}

.editDescription {
  position: absolute;
  width: 292px;
  height: 156px;
  top: 34px;
  left: 0;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 50px 38px 102.37px 0px #78769424;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;

  &Title {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: var(--essential-package-color);
    opacity: 0.6;
    margin-bottom: 12px;
  }

  &Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Button {
    width: 118px;
    height: 28px;
  }
}