@import "../../../helpers/mixins";

.button {
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: var(--grey-font);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: unset;

  &:disabled,
  .disabled {
    cursor: not-allowed;
    color: var(--item-background);
    border-color: var(--grey-disabled);
  }
}

.tooltip {
  @include tooltip;
  pointer-events: all !important;
  display: flex;
  flex-direction: column;
  background-color: var(--item-background) !important;

  .title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: var(--font);
  }
}
