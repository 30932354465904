.wrapper {
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.25rem;
}

.liItem {
  height: 4.25rem;

  cursor: pointer;

  display: flex;
  align-items: center;

  padding: 0.5rem 0.75rem;
  box-sizing: border-box;

  background: var(--item-background);
  border: var(--basic-border);
  border-radius: 2px;
  box-sizing: border-box;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--grey60);

  &.active {
    color: var(--accent-color);
    background: var(--accent-color-lighter-5);
    border: 1px solid var(--accent-color-lighter-20);
    border-radius: 2px;
    border-top: 2px solid var(--accent-color);
  }

  &.disabled {
    cursor: not-allowed !important;
  }
}
