@import "src/helpers/mixins";

.schemeWrapper {
  position: relative;
  width: 100%;
  margin-top: -2px;

  .toggleBtn {
    position: relative;
    left: 20px;
    height: 40px;
  }

  .schemeContainer {
    height: 0;
    opacity: 0;
    transition: height 0.2s ease;
  }
}

.showScheme .schemeContainer {
  height: auto;
  opacity: 1;

  background: var(--background-second-color);
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;

  padding: 20px 0;
  display: flex;
  justify-content: center;

  transition: height 0.2s ease;
}

.schemeContainer svg:focus {
  outline: none;
  border: none;
}

.toggleBtn {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2em;
  letter-spacing: 0.05em;

  cursor: pointer;
  color: var(--button-primary);

  &:hover {
    color: var(--button-primary-hover);
  }
}
