.wrapper {
  height: 100%;
  display: grid;
  column-gap: 2px;
  box-sizing: border-box;
  background-color: var(--item-background);
  border: 1px solid var(--border-color);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  border-radius: 10px;

  &.collapsed {
    height: 36px;
  }
}

.iconContainer {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapseBtn {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1rem;
    height: 0.25rem;
  }

  &.rotate svg {
    transform: rotate(180deg);
  }
}
