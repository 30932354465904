.btn {
  height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--accent-color-opacity);

  cursor: pointer;
  color: var(--button-primary);

  &:hover {
    color: var(--button-primary-hover);
    border-color: var(--button-primary-hover);
  }

  &.disabledBtn {
    color: var(--grey-disabled);
    margin-right: 0;
    border-color: var(--grey-disabled);
    cursor: not-allowed;
  }

  &.primaryBtn {
    background-color: var(--button-primary);
    margin-right: 0;
    color: var(--button-font);

    &:hover:not(.disabledBtn) {
      background-color: var(--button-primary-hover);
    }

    &.disabledBtn {
      background-color: var(--grey-disabled);
      margin-right: 0;
      color: var(--button-font);
      border: none;
    }
  }

  &.smallBtn {
    width: 28px;
    height: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
