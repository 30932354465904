.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  position: absolute;
  // width: 162px;
  box-sizing: border-box;
  z-index: 10;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 9px));
  background: var(--bottom-nav-background);
  border-top: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 12px 27px;
  font-size: 14px;
}

.button {
  justify-content: start;
  margin: 15px 0;
  text-align: left;
  white-space: nowrap;
  color: var(--bottom-nav-active-color);

  &:hover {
    color: var(--bottom-nav-inactive-color);
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
}
