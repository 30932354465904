.basic {
  width: 88px;
  height: 24px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
}

.block {
  @extend .basic;
  background-color: var(--light-pink);
  color: var(--pink);
}

.allow {
  @extend .basic;
  background-color: var(--light-green);
  color: var(--green);
}
