@import "src/helpers/mixins";

.labels {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 0.75rem;
  };
}

.id {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--grey50);
  cursor: pointer;
}

.tooltip {
  @include tooltip;
}

.name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--font);
}

.type {
  color: var(--grey-font);
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
