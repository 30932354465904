@import "../../helpers/mixins.scss";

.wrapper {
  @include yScroll();
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
