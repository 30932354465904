.wrapper {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 15rem 1fr 15rem;
  column-gap: 1.25rem;
}

.basicBlock {
  width: 100%;
  height: 100%;
  background: var(--item-background);
  border: var(--basic-border);
  box-shadow: var(--box-shadow-smaller);
  border-radius: 4px;
}
