@import "src/helpers/mixins";

.tableBlock {
  border: none;
  height: 100%;
  max-height: 40vh;

  @include yScroll;

  > * {
    overflow: hidden;
    height: max-content;
    grid-template-rows: unset;
  }

  div[class*="_tableHeader_"] {
    background: none;
    opacity: 0.6;
  }

  div[class*="Table_scroll"] {
    height: 40vh !important;
  }

  @media screen and (max-height: 1000px) {
    max-height: 30vh;
  }
}

.rowClassName {
  color: var(--text, #59536b);
  &:hover {
    background: rgba(255, 95, 1, 0.05) !important;
    div:first-child {
      color: rgba(255, 95, 1, 1) !important;
    }
  }
}
