.elementsBox {
  display: grid;
  gap: 1.75rem;
  padding: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  //grid-template: repeat(2, 1fr) / repeat(4, 1fr);

  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;

  &.twoColumns {
    grid-template-columns: repeat(2, 1fr);
  }
}
