.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .section {
    box-sizing: border-box;
    padding: 0.8rem 3.6rem 1rem 1.5rem;

    &:not(:last-child) {
      border-bottom: var(--basic-border);
    }
  }

  .subsection {
    box-sizing: border-box;
    padding: 0.25rem 0.5rem 0.5rem;

    &:not(:last-child) {
      border-bottom: var(--basic-border);
    }
  }
}

.footer {
  border-top: var(--basic-border);
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
}
