.tableTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--transparent-gray);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.timerangeWrapper {
  height: calc(100vh - 234px) !important;
  top: 62px !important;
}
