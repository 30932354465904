.title,
.username,
.ip {
    color: var(--grey-font);
    font-weight: 600;
}

.title {
    font-size: 14px;
    opacity: 0.6;
}

.username {
    font-size: 18px;
    color: var(--grey-font);
}

.ip {
    font-size: 12px;
}