@import "src/helpers/mixins";

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 1.25rem;
  border: var(--basic-border);
  border-radius: 4px;
  padding: 1rem 4.5rem 2rem 1rem;
  box-sizing: border-box;
  position: relative;
}

.firstCol {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;

  .statusGroup {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.75rem;

    span {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.3em;
      color: var(--grey60);
    }
  }
}

.secondCol {
  display: flex;
  flex-direction: column;
  @include colGap(0.5rem);
}

.row {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.25rem;
  align-items: flex-end;
}
