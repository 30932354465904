.wrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: 100%;
}

.form {
  width: 50%;
  min-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.input {
}

.submit {
  align-self: flex-end;
  display: none;
}

.dropdownList {
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
