$basicBorder: 1px solid #D8DAEB;

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  transition: all 0.3s ease-in-out;

  &.empty {
    align-items: center;
  }
}

.tableBlock {
  border: none;
  height: 100%;

  &.empty {
    > * {
      height: 100%;
    }
  }

  > * {
    overflow: hidden;
    height: max-content;
    grid-template-rows: unset;
  }
}

.rowClassName {
  background: rgba(107, 85, 240, 0.08) !important;
}

.noVisible {
  display: none !important;
}

.subRow {
  border-right: $basicBorder;
  border-top: $basicBorder;
  background: rgba(107, 85, 240, 0.03);
  height: 40px;
  width: 100%;
  border-left: 2px solid var(--accent-color);

  &.az {
    height: max-content;
  }
}
