.wrapper {
  height: 100%;
}

.dropdownButton {
  button {
    height: 50px;
  }
}

.form {
  width: 50%;
  min-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
