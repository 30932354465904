.formWrapper {
  height: 100%;
  align-items: flex-start;
  display: grid;
  gap: 1.5rem 2rem;
  grid-template-areas:
    "destination ."
    "type gateway"
    "distance description";
  grid-auto-rows: 50px;
}

.destination {
  grid-area: destination;
}

.type {
  grid-area: type;
}

.distance {
  grid-area: distance;
}

.description {
  grid-area: description;
}

.description,
.destination,
.distance,
.type {
  height: 50px;
}
