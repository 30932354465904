.historyLogPageWrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    gap: 18px;
}

.redirectBtn {
    align-self: flex-end;
    padding: 8px 24px;
}