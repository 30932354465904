.table {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--grey-font);
    opacity: 0.6;
  }

  .headerActions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    display: none;
  }

  .selectedCount {
    font-weight: 400;
    font-size: 14px;
    color: var(--accent-color);
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .filterButton {
  }
}

.matrix {
  padding-top: 20px;
}
