@import "../../../helpers/mixins.scss";

$headerHeight: 132px;
$headerHeightSmall: 112px;
$footerHeight: 60px;

.timeRange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  margin-bottom: 2rem;

  .customDate {
    display: flex;
    justify-content: space-between;
    column-gap: 0.75rem;

    .dataPicker {
      width: 100px;
    }
  }
}

.subTitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3em;
  color: var(--grey60);
}
