.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.25rem;
}

.inputWrapper {
  max-width: 12rem;
}

.descriptionButton {
  justify-content: flex-start;
  color: var(--accent-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}
