.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: var(--font);
  column-gap: 1rem;

  button {
    cursor: pointer;
  }
}

.plusCreateBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  gap: 0.25rem;
}
