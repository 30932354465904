.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--grey60);
}

.title {
  font-size: 18px;
  margin-top: 26px;
  line-height: 22px;
  color: #59536b;
}

.subTitle {
  font-size: 12px;
  margin-top: 10px;
  line-height: 16px;
}

.error > .title {
  color: var(--critical);
}
