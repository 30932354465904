.red {
  background-color: var(--pink-10);
}

.green {
  background-color: var(--green-10);
}

.orange {
  background-color: var(--orange-10);
}

.greenText {
  color: var(--green);
}

.redText {
  color: var(--pink);
}

.orangeText {
  color: var(--orange);
}

.greyText {
  color: var(--grey-font);
}
