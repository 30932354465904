.form {
  display: flex;
  gap: 36px;
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
}

.bottomButton {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.changePasswordBtn {
  border: none;
  font-weight: 600;
  font-size: 16px;
}


.dialogPending {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;
}