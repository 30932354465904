.sectionWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-start;
}

.title {
  font-weight: 600;
  font-size: 0.65rem;
  line-height: 1.2em;
  color: var(--accent-color-lighter-60);
  padding-bottom: 6px;
}

.subtitle {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2em;
  color: var(--grey60);
}

.contentWrapper {
  width: 100%;
  height: auto;
  display: grid;
  align-items: flex-start;
  column-gap: 2.5rem;
  row-gap: 1rem;
  border-top: var(--basic-border);
  padding-top: 0.5rem;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 40%) !important;
    overflow-x: hidden;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 80% !important;
    overflow-x: hidden;
  }

  &.oneColumn {
    grid-template-columns: 1fr !important;
    @media (max-width: 1300px) {
      grid-template-columns: 1fr !important;
      overflow-x: hidden;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr !important;
      overflow-x: hidden;
    }
  }

  &.btmAlign {
    align-items: flex-end;
  }
}
