.groupWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px 16px;
    background: rgba(239, 233, 251, 0.7);

    border-left: 2px solid transparent;

    transition: all 0.15s ease;

    &:hover {
        border-color: #6b55f0;
        cursor: pointer;
    }
}

.groupTitle {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 12px;
}