@import "src/helpers/mixins";

.layout {
  padding: 0 24px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}

.wrapper {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;
}

.stepContent {
  display: flex;
  gap: 4px;
  margin-top: 90px;
  width: 100%;
}

.menu {
  padding-top: 30px;
  padding-left: 23px;
  padding-right: 23px;
  box-sizing: border-box;
  width: 300px;
  position: relative;
}

.settings {
  display: flex;
  flex-direction: column;
}

.settingItem {
  font-weight: 600;
  font-size: 14px;
  color: var(--grey-font);
  position: relative;
  box-sizing: border-box;
  padding: 12px 0 12px 14px;
  display: flex;
  border-bottom: 1px solid var(--border-color-secondary);

  &:hover {
    cursor: pointer;
    color: var(--accent-color);
  }
}

.group {
  box-sizing: border-box;
  padding: 0.5rem 0;
  padding-bottom: 26px;

  &:not(:last-child) {
    border-bottom: var(--basic-border);
  }
}

.title {
  color: var(--accent-color);
  font-size: 0.875rem;
  height: 20px;
  font-weight: 600;
  line-height: 1.3em;
  text-transform: uppercase;
  opacity: 0.6;
  padding-bottom: 0.5rem;
  border-bottom: var(--basic-border);
}
