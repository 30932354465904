@import "../../../helpers/mixins.scss";
$btmBlockHeight: 224px;

.contentWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
  grid-template-rows: 3rem auto;
  overflow: hidden;
}

@mixin basicBlock {
  background: var(--background-second-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 1.875rem;
}

.header {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4em;
  color: var(--grey-font);
  grid-area: header;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 3.25rem;
}

.chartsWrapper {
  @include yScroll;
  @include xScroll;
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.25rem;
  grid-template-rows: 400px auto;
}

.topPart {
  @include basicBlock;
  background: var(--background-color);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  padding: 0 1.875rem;
  width: 100%;
  height: 100%;

  .innerLeftPart {
    width: 100%;
    height: 100%;
    padding: 1.875rem 0;
    padding-right: 1.25rem;
    box-sizing: border-box;
    border-right: 1px solid var(--border-color);
  }

  .innerRightPart {
    width: 100%;
    height: 100%;
    padding: 1.875rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    padding-left: 1.25rem;
    box-sizing: border-box;
  }
}

$headerHeight: 3rem;

.graphWrap {
  display: flex;
  flex-direction: column;
}

.rightPart {
  height: 100%;
  width: 100%;
  border: var(--basic-border);
  border-radius: 6px;
  padding: 0.75rem;
  box-sizing: border-box;
  grid-area: rightPart;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btmPart {
  @include basicBlock;
  background: var(--background-color);
  height: fit-content;
}

.btmTop {
  display: flex;
  @include rowGap(1rem);
  margin-bottom: 1rem;
}

.bottomStats {
  display: flex;
  justify-content: space-between;
  @include rowGap(1rem);

  & > iframe {
    width: calc(30% - 0.5rem);
  }
}

.percentageBox {
  display: grid;
  align-items: center;
  padding: 1rem;
  flex: 1;
  grid-template-columns: 8rem auto;
  overflow: hidden;
  column-gap: 0.75rem;

  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background-color: var(--item-background);

  span {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 0.5rem;
    }
  }
}

.footer {
  @include basicBlock;
  background: var(--background-color);
  display: flex;
  justify-content: flex-end;
}

.linkBtn {
  text-decoration: none;
  align-items: center;
}

.bottomStatsImage {
  width: 100%;
  height: 160px;
  object-fit: contain;
}
