.filterHeader {
  width: 100%;
  padding: 1.25rem 1.25rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--grey);
  display: flex;
  align-items: center;
}

.filterControls {
  width: 100%;
  border-top: var(--basic-border);
  display: grid;
  column-gap: 0.75rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem 1.25rem;
  box-sizing: border-box;
}