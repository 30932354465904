.wrapper {
  height: 100%;
  width: 33%;
  position: relative;
  margin-bottom: 30px;
}

.additionalButton {
  margin-right: 0.5rem;
}
