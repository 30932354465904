.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.125rem;
  justify-content: flex-start;
}

.confirmDialog {
  width: 500px !important;
}

.btn {
  justify-content: flex-start;
  text-align: left;
}

.loader {
  width: 24px;
  height: 24px;
}
