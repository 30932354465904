@import "src/helpers/mixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: var(--basic-border);
  border-radius: 4px;
  background-color: var(--item-background);
  overflow-y: hidden;
}

.complexHeader {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  column-gap: 0.625rem;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  border-bottom: var(--basic-border);

  & > span {
    color: var(--grey60);
    font-size: 1.125rem;
    font-weight: 400;
  }
}

.table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
